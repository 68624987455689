import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { GET_EMBEDDED_REPORTING_ITEMS } from '@frontend/app/queries';
import {
  GetEmbeddedReportingItems,
  GetEmbeddedReportingItems_embeddedReportingItems,
} from '@frontend/app/queries/types/GetEmbeddedReportingItems';

export type IEmbeddedReportingItem = GetEmbeddedReportingItems_embeddedReportingItems;

type IOptions = QueryHookOptions<GetEmbeddedReportingItems>;

export function useGetEmbeddedReportingItems(options: IOptions = {}) {
  return useQuery<GetEmbeddedReportingItems>(
    GET_EMBEDDED_REPORTING_ITEMS,
    {
      ...options,
      fetchPolicy: 'network-only',
    },
  );
}

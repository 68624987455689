import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { UPDATE_ROLE_FOR_CLIENT_MUTATION } from '@frontend/app/queries';
import {
  UpdateRoleForClientMutation,
  UpdateRoleForClientMutationVariables,
} from '@frontend/app/queries/types/UpdateRoleForClientMutation';
import { useGetUserById } from '@frontend/app/hooks';

const { useEffect } = React;

type IOptions = MutationHookOptions<UpdateRoleForClientMutation, UpdateRoleForClientMutationVariables>;

export function useUpdateRoleForClient(options: IOptions = {}) {
  const { refetch: refetchUser } = useGetUserById();
  const [updateRoleForClient, { loading, error }] = useMutation<
    UpdateRoleForClientMutation,
    UpdateRoleForClientMutationVariables
  >(UPDATE_ROLE_FOR_CLIENT_MUTATION, {
    ...options,
    onCompleted(data) {
      if (options.onCompleted) {
        options.onCompleted(data);
      }

      message.info('Successfully updated manager client access');
    },
  });

  const _updateRoleForClient: typeof updateRoleForClient = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [, result] = args;
        if (!result.data.succeeded) {
          return;
        }

        const { id } = _options.variables || options.variables;
        refetchUser({
          id,
        });
      },
    };

    return updateRoleForClient(...args);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    updateRoleForClient: _updateRoleForClient,
    loading,
    error,
  };
}

/* Text styles */
._ClientFeatureList_coxav_2 ._header_coxav_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientFeatureList_coxav_2 ._header_coxav_2 ._createButton_coxav_7 {
  margin-left: auto;
}
._ClientFeatureList_coxav_2 ._table_coxav_10 ._nameCell_coxav_10 {
  color: #3996e0;
  cursor: pointer;
}
import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_PROGRAMS_QUERY } from '@frontend/app/queries';
import {
  GetProgramsQuery,
  GetProgramsQuery_programs,
  GetProgramsQueryVariables,
} from '@frontend/app/queries/types/GetProgramsQuery';

export type IProgram = GetProgramsQuery_programs;
const { useEffect } = React;

export function useGetPrograms(options: QueryHookOptions<GetProgramsQuery, GetProgramsQueryVariables> = {}) {
  const {
    loading,
    data: { programs = [] } = {},
    error,
    refetch,
  } = useQuery<GetProgramsQuery, GetProgramsQueryVariables>(
    GET_PROGRAMS_QUERY,
    {
      ...options,
      variables: {
        clientId: options.variables?.clientId as string,
      },
    },
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    programs,
    error,
    refetch,
  };
}

import * as React from 'react';

import { App } from '@frontend/app/containers';
import { AuthProvider } from '@frontend/context/authContext';

const Main: React.FC = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default Main;

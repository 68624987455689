import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';

import { message } from 'antd';

import { GET_USER_QUERY } from '@frontend/app/queries';
import {
  GetUserQuery,
  GetUserQueryVariables,
  GetUserQuery_user,
} from '@frontend/app/queries/types/GetUserQuery';

const { useEffect } = React;
export type IUser = GetUserQuery_user;
type IOptions = QueryHookOptions<GetUserQuery, GetUserQueryVariables>;

export function useGetUserById(options: IOptions = {}) {
  const {
 loading, data: { user = null } = {}, error, refetch,
} = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_QUERY, {
    ...options,
    skip: isEmpty(options.variables?.id),
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    user,
    error,
    refetch,
  };
}

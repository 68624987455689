import * as React from 'react';
import cx from 'classnames';
import { Input, Typography, Upload, Row, Col, Button, IUploadChangeParam, Space } from '@revfluence/fresh';
import { Moment } from 'moment';

const { Link, Title } = Typography;
import { RouteComponentProps } from 'react-router-dom';

import {
    DatePicker, message,
} from 'antd';
import Papa from 'papaparse';
import { useUpdateClientOffer } from '@frontend/app/hooks/clients/useUpdateClientOffer';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { logger } from '@common';

const styles = require('./ClientOffers.scss');
const { RangePicker } = DatePicker;

const { useEffect, useMemo } = React;

interface IMatchParams {
    clientId: string;
}
interface IProps extends RouteComponentProps<IMatchParams> {
    className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientOffers: React.FunctionComponent<IProps> = React.memo((props) => {
    const { clientId } = props.match.params;

    useEffect(() => {

    }, [clientId]);
    const { updateOfferData } = useUpdateClientOffer();
    const [fileList, setFileList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const onDateChange = (
        values: [Moment | null, Moment | null] | null,
    ) => {
        setDateRange(values)
    };

    const customItemRender = (file, remove: () => void) => {
        return (
            <Space direction="horizontal">
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {file.name}
                </span>
                <DeleteOutlined onClick={()=>remove()} style={{color:"red"}}/>
            </Space>

        );
    };
    const handleOnChange = (e: IUploadChangeParam) => {
        if (e.file.status === 'removed') {
            handleOnRemove();
        } else {
            let newFileList = [...e.fileList];
            newFileList = newFileList.slice(-1);
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                }
                return file;
            });
            setFileList(newFileList);
            const uploadedFile = newFileList[0];

            if (uploadedFile) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const text = event.target.result;
                    Papa.parse(text, {
                        complete: (result) => {
                            const { data } = result;
                            if (data.length) {
                                if (data.length > 200) {
                                    message.error("Please add max 200 members in one upload");
                                    handleOnRemove();
                                } else {
                                    setSelectedFile(data);
                                }
                            } else {
                                handleOnRemove();
                                logger.error("No data found in the CSV file.");
                            }
                        },
                        error: (err) => {
                            handleOnRemove();
                            logger.error('Error parsing CSV:', err);
                        },
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                    });
                };
                reader.readAsText(uploadedFile.originFileObj);
            }
        }
    };
    const handleOnRemove = () => {
        setFileList([]);
        setInputValue('');
        setSelectedFile(null);
    }

    const disableSubmitButton = useMemo(() => {
        if (selectedFile === null || inputValue == "") {
            return true;
        }
        return false;
    }, [selectedFile, inputValue]);
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (selectedFile) {
                updateOfferData({
                    variables: {
                        clientId: clientId,
                        offerId: Number(inputValue),
                        data: selectedFile,
                        startDate: dateRange[0]?.toISOString(),
                        endDate: dateRange[1]?.toISOString(),
                    }
                });
                setSelectedFile(null);
                setInputValue('');
                message.success("Promo codes are  generating, please check community portal.");
            } else {
                message.error("No data found in the CSV file.");
            }
        } catch (error) {
            logger.error({
                message: 'Error updating offer data:',
                error
            })
            message.error("Error updating offer data:", error);
        }
    };

    return (
        <div className={cx(styles.ClientOffers, props.className)}>
            <form onSubmit={handleFormSubmit}>
                <Row gutter={[32, 16]} align="middle" className={styles.clientOfersContainer}>
                    <Col span={12}>
                        <Col span={8} >
                            <Upload
                                fileList={fileList}
                                onChange={(e) => handleOnChange(e)}
                                accept=".csv"
                                showUploadList={{ showDownloadIcon: false }}
                                onRemove={handleOnRemove}
                                maxCount={1}
                                multiple={false}
                                itemRender={(_, file,_a, actions) => customItemRender(file, actions.remove)}
                            >
                                <Button icon={<UploadOutlined className={styles.uploadButton} />}>Upload  CSV</Button>
                            </Upload>
                        </Col>
                    </Col>
                    <Col className={styles.sampleCsvUrl} span={12}>
                        <Link href="https://storage.googleapis.com/aspirex-dev-community/SampleMemberOffer.csv" target="_blank" strong >
                            Download Sample CSV
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Col span={8} >
                            <Title level={4}>Add your Promo Offer Id</Title>
                        </Col>
                        <Col span={8} >
                            <Input
                                className={styles.inputOffer}
                                value={inputValue}
                                onChange={onChange}
                                placeholder="Offer Id"
                                type="number"
                            />
                        </Col>
                    </Col>
                    <Col xs={24}>
                        <Col span={24} >
                            <Title level={4}>
                                Select Date range in case codes will be ungrouped
                            </Title>
                        </Col>
                        <Col span={8}>
                            <RangePicker onChange={onDateChange} format="MMM D, YYYY" value={dateRange} />
                        </Col>
                    </Col>
                    <Col xs={24}>
                        <Col span={8} >
                            <Button
                                type="primary"
                                className={styles.submitButton}
                                disabled={disableSubmitButton}
                                htmlType="submit"
                                style={{ marginTop: 16 }}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Col>
                </Row>


            </form>
        </div>

    );
});

export default ClientOffers;

import 'css-chunk:src/app/containers/Home/ClientsView/ClientDetailView/ClientUsers.scss';export default {
  "ClientUsers": "_ClientUsers_fti0l_1",
  "header": "_header_fti0l_1",
  "manageUsersButton": "_manageUsersButton_fti0l_7",
  "table": "_table_fti0l_10",
  "nameCell": "_nameCell_fti0l_10",
  "alertIcon": "_alertIcon_fti0l_15",
  "avatar": "_avatar_fti0l_19",
  "name": "_name_fti0l_10",
  "roleCell": "_roleCell_fti0l_28",
  "roleItem": "_roleItem_fti0l_33",
  "AccessModal": "_AccessModal_fti0l_37",
  "select": "_select_fti0l_37"
};
import * as React from 'react';
import cx from 'classnames';

import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy } = React;
import styles from './ClientFeaturesView.scss';
const ClientFeatureList = lazy(() => import('./ClientFeatureList'));

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientFeaturesView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={cx(styles.ClientFeaturesView, props.className)}>
      <Switch>
        <Route path={`${match.path}`} render={lazyLoadComponent(ClientFeatureList)} />
      </Switch>
    </div>
  );
});

export default ClientFeaturesView;

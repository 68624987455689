import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_ALLOY_METADATA_QUERY } from '@frontend/app/queries';
import { GetAlloyMetadataQuery, GetAlloyMetadataQueryVariables } from '@frontend/app/queries/types/GetAlloyMetadataQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetAlloyMetadataQuery, GetAlloyMetadataQueryVariables>;

export function useGetAlloyMetadata(options: IOptions = {}) {
  const {
    loading, data: { metadata } = {}, error, refetch,
  } = useQuery<GetAlloyMetadataQuery, GetAlloyMetadataQueryVariables>(
    GET_ALLOY_METADATA_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    metadata,
    error,
    refetch,
  };
}

import * as React from 'react';
import Helmet from 'react-helmet';

import htmlStyles from './Html.scss';

interface IHtmlProps {
  manifest?: any;
  markup?: string;
}

class Html extends React.Component<IHtmlProps, {}> {
  private resolve(files) {
    return files.map((src) => {
      if (!this.props.manifest[src]) { return; }
      return this.props.manifest[src];
    }).filter((file) => file !== undefined);
  }

  public render() {
    const head = Helmet.rewind();
    const { markup } = this.props;

    const styles = this.resolve(['client.css']);
    const renderStyles = styles.map((src, i) =>
      <link key={i} rel="stylesheet" type="text/css" href={src} />,
    );

    const scripts = this.resolve(['client.js']);
    const renderScripts = scripts.map((src, i) =>
      <script src={src} key={i} />,
    );

    return (
      <html className={htmlStyles.html}>
        <head>
          {head.base.toComponent()}
          {head.title.toComponent()}
          {head.meta.toComponent()}
          {head.link.toComponent()}
          {head.script.toComponent()}
          {renderStyles}
          <link rel="shortcut icon" href="/favicon.ico" />
        </head>
        <body className={htmlStyles.body}>
          <main
            id="app"
            className={htmlStyles.content}
            dangerouslySetInnerHTML={{ __html: markup }}
          />
          {renderScripts}
        </body>
      </html>
    );
  }
}

export { Html };

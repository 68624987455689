import * as React from 'react';
import cx from 'classnames';
import { split, last, isEmpty } from 'lodash';

import {
  Link, Route, Redirect, Switch,
  useRouteMatch, useHistory, useParams, useLocation,
} from 'react-router-dom';

import {
  useGetApplicationById,
  useDeleteApplicationById,
  useGetApplicationInstances,
} from '@frontend/app/hooks';

import {
  Button, Typography,
  Menu, Dropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
const { Title } = Typography;
import { LoadSpinner } from '@frontend/lib';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy, useState } = React;
import styles from './ApplicationDetailView.scss';

const ApplicationDetails = lazy(() => import('./ApplicationDetailView/ApplicationDetails'));
const ApplicationInstances = lazy(() => import('./ApplicationDetailView/ApplicationInstances'));

interface IMatchParams {
  applicationId: string;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ApplicationDetailView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { applicationId } = useParams<IMatchParams>();
  const { application, loading } = useGetApplicationById({
    variables: {
      id: applicationId,
    }
  });
  const { instances, loading: loadingInstances, refetch: refetchInstances } = useGetApplicationInstances({
    variables: {
      applicationId: applicationId,
    },
    fetchPolicy: 'no-cache',
  });
  const { deleteApplicationById } = useDeleteApplicationById();
  const lastRoute = last(split(location.pathname, '/'));
  const [selectedRoute, setSelectedRoute] = useState(
    ['details'].includes(lastRoute) ? lastRoute : 'details',
  );

  const handleActionClick = ({ key }) => {
    switch (key) {
      case 'edit': {
        alert('Not implemented yet');

        break;
      }
      case 'delete': {
        if (loadingInstances) {
          alert('Loading application instances. Try Again.');
          break;
        }
        if (!isEmpty(instances)) {
          alert(`Cannot delete ${application.name}. You must first manually remove all instances of ${application.name} from all Clients.`);
          break;
        }
        if (confirm(
          `You are going to delete ${application.name}. This is irreversible action and will remove application completely from all clients. \nAre you sure?`,
        )) {
          deleteApplicationById({
            variables: {
              id: application.id,
            },
          }).then(() => history.push('/apps'));
        }

        break;
      }
      default: {
        return;
      }
    }
  };
  const handleNavClick = ({ key }) => setSelectedRoute(key);

  const ActionMenu = (
    <Menu onClick={handleActionClick}>
      <Menu.Item key="delete" className={styles.warning}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <div className={cx(styles.ApplicationDetailView, props.className)}>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && application &&
        <>
          <div className={styles.header}>
            <Title>{application.name}</Title>
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <Button className={styles.action} onClick={()=>{refetchInstances();}}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className={styles.children}>
            <Menu
              className={styles.menu}
              onClick={handleNavClick}
              mode="horizontal"
              selectedKeys={[selectedRoute]}
            >
              <Menu.Item key="details">
                <Link to={`${match.url}/details`}>Details</Link>
              </Menu.Item>
              <Menu.Item key="instances">
                <Link to={`${match.url}/instances`}>Instances</Link>
              </Menu.Item>
            </Menu>
            <Switch>
              <Route path={`${match.path}/details`} render={
                (props) => lazyLoadComponent(ApplicationDetails)({ ...props, application: application })
              } />
              <Route path={`${match.path}/instances`} render={
                (props) => lazyLoadComponent(ApplicationInstances)({ ...props, application: application })
              } />
              <Redirect from={`${match.path}`} to={`${match.path}/details`} />
            </Switch>
          </div>
        </>
      }
    </div>
  );
});

export default ApplicationDetailView;

/* Text styles */
._ApplicationListView_7lmtj_2 ._header_7lmtj_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ApplicationListView_7lmtj_2 ._header_7lmtj_2 ._createButton_7lmtj_7 {
  margin-left: auto;
}
._ApplicationListView_7lmtj_2 ._table_7lmtj_10 ._nameCell_7lmtj_10 {
  color: #3996e0;
  cursor: pointer;
}
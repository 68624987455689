import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_ROLES_QUERY } from '@frontend/app/queries';
import { GetRolesQuery, GetRolesQuery_roles } from '@frontend/app/queries/types/GetRolesQuery';

export type IRole = GetRolesQuery_roles;

const { useEffect } = React;
type IOptions = QueryHookOptions<GetRolesQuery>;

export function useGetRoles(options: IOptions = {}) {
  const {
 loading, data: { roles = [] } = {}, error, refetch,
} = useQuery<GetRolesQuery>(
    GET_ROLES_QUERY,
    {
      ...options,
      notifyOnNetworkStatusChange: false,
    },
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    roles,
    error,
    refetch,
  };
}

import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { find, includes, map, startCase, toLower, without } from 'lodash';

import { Alert, Empty, List, Switch } from 'antd';
import { LoadSpinner } from '@frontend/lib';

import { AlloyIntegrationType } from '@frontend/app/types/globalTypes';
import { IClient, useGetAlloyMetadata, useGetApplicationInstances, useUpdateAlloyIntegrations } from '@frontend/app/hooks';

interface IProps {
  client: IClient;

  className?: string;
}

import styles from './ClientIntegrations.scss';
const { useMemo, useCallback } = React;

/**
 * @type {React.FunctionComponent}
 */
const ClientIntegrations: React.FunctionComponent<IProps> = React.memo(({
  client, className,
}) => {
  const history = useHistory();
  const { updateAlloyIntegrations, loading: updating } = useUpdateAlloyIntegrations();

  const { loading: loadingMetadata, metadata, refetch } = useGetAlloyMetadata({
    variables: {
      clientId: client.id,
    },
  });
  const { loading: loadingInstances, instances } = useGetApplicationInstances({
    context: {
      headers: {
        'requested-client-id': client.id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const loading = useMemo(() => loadingMetadata || loadingInstances, [loadingMetadata, loadingInstances]);
  const alloyInstalled = useMemo(() => {
    return !!find(instances, (i) => toLower(i.application.name) === 'alloy');
  }, [instances]);
  const goToClientApplications = useCallback(
    () => history.push(`/clients/${client.id}/apps`), [client],
  );

  return (
    <div className={cx(styles.ClientIntegrations, className)}>
      {loading && <LoadSpinner />}
      {!loading && !alloyInstalled && (
        <Alert
          message={
            <>
              Please install <b>Alloy</b> from the <span
                className={styles.applicationsLink}
                onClick={goToClientApplications}
              >Applications</span> page first.
            </>
          }
        />
      )}
      {!loading && alloyInstalled && !metadata && <Empty />}
      {!loading && alloyInstalled && metadata && (
        <List
          bordered
          dataSource={map(AlloyIntegrationType)}
          renderItem={(integration) => {
            const active = includes(metadata.availableIntegrations, integration);

            return (
              <List.Item
                actions={[
                  <Switch
                    checked={active}
                    loading={updating}
                    onChange={async (checked) => {
                      let availableIntegrations: AlloyIntegrationType[];
                      if (checked) {
                        availableIntegrations = [
                          ...(metadata.availableIntegrations || []),
                          integration,
                        ];
                      } else {
                        availableIntegrations = without(metadata.availableIntegrations, integration);
                      }

                      await updateAlloyIntegrations({
                        variables: {
                          clientId: client.id,
                          availableIntegrations,
                        },
                      });

                      refetch();
                    }}
                  />,
                ]}
              >
                {startCase(toLower(integration))}
              </List.Item>
            );
          }}
        />
      )}
    </div>
  );
});

export default ClientIntegrations;

/* Text styles */
._CommunityDetailView_1txej_2 ._header_1txej_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CommunityDetailView_1txej_2 ._header_1txej_2 ._action_1txej_7 {
  margin-left: auto;
}
._CommunityDetailView_1txej_2 ._children_1txej_10 ._menu_1txej_10 {
  margin-bottom: 20px;
}
/* Text styles */
._ClientDetailView_j1bkz_2 ._header_j1bkz_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientDetailView_j1bkz_2 ._header_j1bkz_2 ._action_j1bkz_7 {
  margin-left: auto;
}
._ClientDetailView_j1bkz_2 ._children_j1bkz_10 ._menu_j1bkz_10 {
  margin-bottom: 20px;
}

._warning_j1bkz_14 {
  color: red;
}

._warning_light_j1bkz_18 {
  color: orange;
}
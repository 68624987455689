._ColorPicker_1r5zj_1 {
  display: flex;
  align-content: center;
}
._ColorPicker_1r5zj_1 ._swatch_1r5zj_5 {
  display: block;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: 0.5px solid #DADCDE;
  cursor: pointer;
}
._ColorPicker_1r5zj_1 ._value_1r5zj_15 {
  margin-left: 8px;
  flex: 1;
  border: 0.5px solid #DADCDE;
  border-radius: 100px;
  background-color: #eff5f9;
  color: #5F5F5F;
  font-size: 13px;
  font-weight: 400;
}
._ColorPicker__disabled_1r5zj_25 {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

._Popover_1r5zj_31 .ant-popover-inner-content {
  padding: 0 !important;
}
._Popover_1r5zj_31 .ant-popover-arrow {
  display: none !important;
}
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { DELETE_EMBEDDED_REPORTING_ITEM_BY_ID } from '@frontend/app/queries';
import { DeleteEmbeddedReportingItem, DeleteEmbeddedReportingItemVariables } from '@frontend/app/queries/types/DeleteEmbeddedReportingItem';

type IOptions = MutationHookOptions<DeleteEmbeddedReportingItem, DeleteEmbeddedReportingItemVariables>;

export function useDeleteEmbeddedReportingItemById(options: IOptions = {}) {
  return useMutation<DeleteEmbeddedReportingItem, DeleteEmbeddedReportingItemVariables>(DELETE_EMBEDDED_REPORTING_ITEM_BY_ID, options);
}

import * as React from 'react';
import { IProgram } from '@frontend/app/hooks';
import { Button } from '@revfluence/fresh';
import { Drawer, Form } from 'antd';
import { DatePicker } from 'antd';
import { Moment } from 'moment';

const { RangePicker } = DatePicker;
const { useState } = React;

interface ClientBriefDownloadDrawerProps {
  program: IProgram;
  open: boolean;
  onClose(): void;
  downloadBriefsForProgram(programId: number, startDate: string, endDate: string): void;
  exportingBriefs: boolean;
  downloadBriefsUrl: string
}

const ClientBriefDownloadDrawer: React.FC<ClientBriefDownloadDrawerProps> = React.memo(props => {
  const [dateRange, setDateRange] = useState(null);

  const onDateChange = (
      values: [Moment | null, Moment | null] | null,
  ) => {
      setDateRange(values)
  };
  const onSubmit = () => {
    props.downloadBriefsForProgram(props.program.id, dateRange[0].unix(), dateRange[1].unix())
  }
  return  (
  <Drawer title="Brief's Download" width={500} onClose={props.onClose} visible={props.open}>
    <Form
      onFinish={onSubmit}
    >
      <Form.Item label="Select the date range to download the brief for the program:">
        <RangePicker onChange={onDateChange} value={dateRange} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={props.exportingBriefs}
        >
          Start Download
        </Button>
      </Form.Item>
      {props.downloadBriefsUrl && <Form.Item>
          <a
            href={props.downloadBriefsUrl}
            target="__blank">
            Download
          </a>
        </Form.Item>
      }
    </Form>
  </Drawer>
)
});

export default ClientBriefDownloadDrawer;

import * as React from 'react';

import { Spin } from 'antd';
import { ErrorBoundary } from '@frontend/utils';

const { Suspense } = React;

export function lazyLoadComponent(Component: React.LazyExoticComponent<any>) {
  return (props) => (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Spin
            style={{
              margin: '0 auto',
            }}
            size="large"
          />
        }
      >
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}

import gql from 'graphql-tag';

export const SAVE_ALLOWED_DOMAIN_MUTATION = gql`
  mutation SaveAllowedDomainMutation($allowedDomainInput: [String!]!,$clientId: String!) {
    saveAllowedDomain: upsertAllowDomains(allowedDomainInput: $allowedDomainInput,clientId: $clientId) {
        id
    }
  }
`;

export const Remove_ALLOWED_DOMAIN_MUTATION = gql`
  mutation RemoveAllowedDomainMutation($domains: [String!]!,$clientId: String!) {
    removeAllowedDomain: removeAllowedDomains(domains: $domains,clientId: $clientId) {
        id
    }
  }
`;

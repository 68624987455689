import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_APPLICATION_INSTANCES_QUERY } from '@frontend/app/queries';
import {
  GetApplicationInstancesQuery,
  GetApplicationInstancesQueryVariables,
  GetApplicationInstancesQuery_instances,
} from '@frontend/app/queries/types/GetApplicationInstancesQuery';

export type IApplicationInstance = GetApplicationInstancesQuery_instances;
const { useEffect } = React;
type IOptions = QueryHookOptions<GetApplicationInstancesQuery, GetApplicationInstancesQueryVariables>;

export function useGetApplicationInstances(options: IOptions = {}) {
  const {
 loading, data: { instances = [] } = {}, error, refetch,
} = useQuery<
    GetApplicationInstancesQuery,
    GetApplicationInstancesQueryVariables
  >(
    GET_APPLICATION_INSTANCES_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    instances,
    error,
    refetch,
  };
}

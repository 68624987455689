import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { GENERATE_ONBOARDING_PAGE } from '@frontend/app/queries';
import {
  GenerateOnboardingPage,
  GenerateOnboardingPageVariables,
} from '@frontend/app/queries/types/GenerateOnboardingPage';

type IOptions = QueryHookOptions<GenerateOnboardingPage, GenerateOnboardingPageVariables>;

export function useGenerateProgramOnboardingPage(options: IOptions = {}) {
  return useQuery<GenerateOnboardingPage, GenerateOnboardingPageVariables>(
    GENERATE_ONBOARDING_PAGE,
    options,
  );
}

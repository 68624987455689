import * as React from 'react';
import cx from 'classnames';

import { Link } from 'react-router-dom';
import { Input, Table, Typography, message } from 'antd';
import { useUpdateProgram } from '@frontend/app/hooks';

const { useState } = React;
const { Column } = Table;
const { Text } = Typography;

import {
  ICommunity,
  IProgram,
} from '@frontend/app/hooks';

import styles from './CommunityPrograms.scss';

interface IProps {
  community: ICommunity;

  className?: string;
}

/**
 * @type {React.FC}
 */
const CommunityPrograms: React.FC<IProps> = React.memo((props) => {
  const [isLandingPageEditing, setIsLandingPageEditing] = useState<boolean>();
  const { community } = props;

  const [updateProperties] = useUpdateProgram({
    onCompleted() {
      message.success('Successfully saved program');
    },
    onError(error) {
      message.error(error.message);
    },
  });

  const handleSaveLandingPage = (programId, updatedLandingPage: string) => {
    updateProperties({
      variables: {
        id: programId,
        properties: {
          customLandingPagePath: updatedLandingPage
        },
      },
    }).then(() => {
      setIsLandingPageEditing(false)
    });
  };

  return (
    <div className={cx(styles.CommunityPrograms, props.className)}>
      <Text className={styles.text}>Programs under this community.</Text>
      <Table
        rowKey={(program: IProgram) => program.id}
        dataSource={community.programs}
      >
        <Column title="Id" dataIndex="id" render={(_, program: IProgram) => {
          return (
            <Link to={`/programs/${program.id}`}>{program.id}</Link>
          );
        }} />
        <Column title="Title" dataIndex="title" />

        <Column
          title="Custom Landing Page Path"
          dataIndex="customLandingPagePath"
          onCell={(_) => ({ onClick: () => { setIsLandingPageEditing(true) } })}
          render={(_, program: IProgram) => {
            if (isLandingPageEditing) {
              return (
                <Input
                  className={styles.field}
                  defaultValue={program.customLandingPagePath}
                  onPressEnter={
                    (e) => handleSaveLandingPage(program.id, (e.target as HTMLInputElement).value)
                  }
                />
              )
            }
            return program.customLandingPagePath;
          }}
        />
        <Column title="Owner" dataIndex="owner" />
      </Table>
    </div>
  );
});

export default CommunityPrograms;

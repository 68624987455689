import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { RESTORE_CLIENTS_AND_USERS_MUTATION } from '@frontend/app/queries';
import {
  RestoreClientsAndUsersMutation,
} from '@frontend/app/queries/types/RestoreClientsAndUsersMutation';

const { useEffect } = React;
type IOptions = MutationHookOptions<RestoreClientsAndUsersMutation>;

export function useRestoreClientsAndUsers(options: IOptions = {}) {
  const [
    restoreClientsAndUsers, { loading, error },
  ] = useMutation<RestoreClientsAndUsersMutation>(RESTORE_CLIENTS_AND_USERS_MUTATION, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    restoreClientsAndUsers,
    error,
  };
}

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_APPLICATION_QUERY } from '@frontend/app/queries';
import {
  GetApplicationQuery,
  GetApplicationQueryVariables,
  GetApplicationQuery_application,
} from '@frontend/app/queries/types/GetApplicationQuery';

const { useEffect } = React;
export type IApplication = GetApplicationQuery_application;
type IOptions = QueryHookOptions<GetApplicationQuery, GetApplicationQueryVariables>;

export function useGetApplicationById(options: IOptions = {}) {
  const {
 loading, data: { application = null } = {}, error, refetch,
} = useQuery<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >(GET_APPLICATION_QUERY, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    application,
    error,
    refetch,
  };
}

._ApplicationInstances_m497x_1 ._header_m497x_1 {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ApplicationInstances_m497x_1 ._header_m497x_1 ._uninstallButton_m497x_7 {
  margin-left: auto;
}
._ApplicationInstances_m497x_1 ._header_m497x_1 ._installButton_m497x_10 {
  margin-left: 20px;
}
._ApplicationInstances_m497x_1 ._table_m497x_13 ._cell_m497x_13 {
  color: #3996e0;
  cursor: pointer;
}
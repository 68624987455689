import * as React from 'react';
import cx from 'classnames';

import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy } = React;
import styles from './ApplicationsView.scss';
const ApplicationListView = lazy(() => import('./ApplicationListView'));
const ApplicationDetailView = lazy(() => import('./ApplicationDetailView'));

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ApplicationsView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={cx(styles.ApplicationsView, props.className)}>
      <Switch>
        <Route path={`${match.path}`} exact render={lazyLoadComponent(ApplicationListView)} />
        <Route path={`${match.path}/:applicationId`} render={lazyLoadComponent(ApplicationDetailView)} />
      </Switch>
    </div>
  );
});

export default ApplicationsView;

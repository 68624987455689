import * as React from 'react';
import { Menu, message } from 'antd';

import { useParams } from 'react-router-dom';

import { Button } from '@revfluence/fresh';
import { useGetProgramById } from '@frontend/app/hooks';
import { useUnArchiveProgramById } from '@frontend/app/hooks/programs/useUnArchiveProgramById';
import { ProgramOnboardingProvider } from './context/ProgramOnboardingContext';
import { ProgramOnboardingPageConfig } from './ProgramOnboardingPageConfig';
import { ProgramOnboardingPagePreview } from './ProgramOnboardingPagePreview';
import { ProgramInviteTemplate } from './ProgramInviteTemplate';
import { ProgramOnboardingHTML } from './ProgramOnboardingHTML';

import styles from './ProgramDetails.scss';

const { useState } = React;

interface IParams {
  programId?: string;
}

const ProgramDetails: React.FC = () => {
  const { programId } = useParams<IParams>();
  const {
    data: {
      program = null,
    } = {},
  } = useGetProgramById({
    variables: {
      id: parseInt(programId, 10),
    },
    skip: !programId,
  });
  const [unArchiveProgram, {
    loading: isUnArchiving,
  }] = useUnArchiveProgramById({
    onCompleted() {
      message.success('Successfully unarchived program');
    },
    onError(error) {
      message.error(error.message);
    },
  });

  const onUnArchive = () => {
    unArchiveProgram({
      variables: {
        id: parseInt(programId, 10),
      },
    });
  };

  const [selectedKey, setSelectedKey] = useState<string>('invite');
  return (
    <ProgramOnboardingProvider programId={Number(programId)}>
      {program && program.status === 'archived' && (
        <Button
          type="primary"
          loading={isUnArchiving}
          onClick={onUnArchive}
        >
          Unarchive Program
        </Button>
      )}
      <Menu
        className={styles.menu}
        onClick={(param) => setSelectedKey(param.key)}
        mode="horizontal"
        selectedKeys={[selectedKey]}
      >
        <Menu.Item key="invite">
          Invite template
        </Menu.Item>
        <Menu.Item key="onboardingHtml">
          Onboarding Page HTML
        </Menu.Item>
        <Menu.Item key="config">
          Onboarding Page Config
        </Menu.Item>
        <Menu.Item key="preview">
          Preview
        </Menu.Item>
      </Menu>
      {selectedKey === 'invite' && (
        <ProgramInviteTemplate />
      )}
      {selectedKey === 'preview' && (
        <ProgramOnboardingPagePreview />
      )}
      {selectedKey === 'config' && (
        <ProgramOnboardingPageConfig />
      )}
      {selectedKey === 'onboardingHtml' && (
        <ProgramOnboardingHTML />
      )}
    </ProgramOnboardingProvider>
  );
};

export default ProgramDetails;

import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import {
    UPDATE_CLIENT_AUTHORIZED_AGENT,
} from '@frontend/app/queries';

import {
  UpdateAuthorizedAgent,
  UpdateAuthorizedAgentVariables,
} from '@frontend/app/queries/types/UpdateAuthorizedAgent';
import { message } from 'antd';
import { useEffect } from 'react';

type IOptions = MutationHookOptions<UpdateAuthorizedAgent, UpdateAuthorizedAgentVariables>;

export function useUpdateClientAuthorizedAgent(options: IOptions = {}, customSucccessMessage?: string, customErrorMessage?: string) {
    const [updateClientAuthorizedAgent, { loading, error }] = useMutation(UPDATE_CLIENT_AUTHORIZED_AGENT, {
        ...options,
        onCompleted: () => { message.success(customSucccessMessage ?? 'Successfully updated authorized agent'); },
    });

    useEffect(() => {
        if (error) {
            message.error(customErrorMessage ?? error.message);
        }
    }, [error, customErrorMessage]);

    return {
        updateClientAuthorizedAgent,
        loading,
        error,
    };
}

import gql from 'graphql-tag';

import { APPLICATION_FRAGMENT } from './fragments';

export const GET_APPLICATIONS_QUERY = gql`
  query GetApplicationsQuery {
    applications: getApplications {
      ...ApplicationFragment
    }
  }
  ${APPLICATION_FRAGMENT}
`;

export const GET_APPLICATION_QUERY = gql`
  query GetApplicationQuery($id: String!) {
    application: getApplicationById(id: $id) {
      ...ApplicationFragment
    }
  }
  ${APPLICATION_FRAGMENT}
`;

export const SAVE_APPLICATION_MUTATION = gql`
  mutation SaveApplicationMutation($data: SaveApplicationInput!) {
    application: saveApplication(data: $data) {
      ...ApplicationFragment
    }
  }
  ${APPLICATION_FRAGMENT}
`;

export const DELETE_APPLICATION_MUTATION = gql`
  mutation DeleteApplicationMutation($id: String!) {
    deleted: deleteApplicationById(id: $id)
  }
`;

/* Text styles */
._CommunityListView_1u6ov_2 ._header_1u6ov_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CommunityListView_1u6ov_2 ._header_1u6ov_2 ._createButton_1u6ov_7 {
  margin-left: auto;
}
._CommunityListView_1u6ov_2 ._table_1u6ov_10 ._idCell_1u6ov_10 {
  color: #3996e0;
  cursor: pointer;
}
._CommunityListView_1u6ov_2 ._table_1u6ov_10 ._clientCell_1u6ov_14 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
._CommunityListView_1u6ov_2 ._table_1u6ov_10 ._clientCell_1u6ov_14 ._clientTag_1u6ov_19 {
  cursor: pointer;
  user-select: none;
}
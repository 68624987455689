import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  SAVE_ORGANIZATION_MUTATION,
  GET_ORGANIZATIONS_QUERY,
} from '@frontend/app/queries';
import { GetOrganizationsQuery } from '@frontend/app/queries/types/GetOrganizationsQuery';
import {
  SaveOrganizationMutation,
  SaveOrganizationMutationVariables,
} from '@frontend/app/queries/types/SaveOrganizationMutation';
import { IOrganization } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, organization: IOrganization) => {
  let data: GetOrganizationsQuery;
  try {
    data = store.readQuery<GetOrganizationsQuery>({ query: GET_ORGANIZATIONS_QUERY });
  } catch (err) {
    console.log(`cache missing: ${GET_ORGANIZATIONS_QUERY}`);
    return;
  }

  // Write our data back to the cache.
  store.writeQuery<GetOrganizationsQuery>({
    query: GET_ORGANIZATIONS_QUERY,
    data: {
      ...data,
      organizations: unionBy(data.organizations, [organization], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<SaveOrganizationMutation, SaveOrganizationMutationVariables>;

export function useSaveOrganization(options: IOptions = {}) {
  const [saveOrganization, { loading, error }] = useMutation<
    SaveOrganizationMutation,
    SaveOrganizationMutationVariables
  >(SAVE_ORGANIZATION_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data.organization);
    },
    onCompleted() {
      message.info('Successfully saved organization');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    saveOrganization,
    loading,
    error,
  };
}

import { ClientFeatureState } from './ClientFeatureStateType';

export interface ICreateClientFeatureInput {
  name: string;
  state?: ClientFeatureState;
}

export interface ISaveClientFeatureInput {
  id: number;
  name: string;
  state: ClientFeatureState;
}

export enum ClientFeature {
  AD_INSIGHTS = 'AdInsights',
  ADD_ASPIRE_SHIPPING_LINE = 'addAspireShippingLine',
  ADD_BILLING_ADDRESS = 'addBillingAddress',
  ALL_PEOPLE_SEGMENT = 'all_people_segment',
  ALLOW_GMAIL_OUTLOOK_TOGETHER = 'allowGmailOutlookTogether',
  ALLOY_INTEGRATIONS = 'alloyIntegrations',
  APPLICANT_REVIEW = 'applicantReview',
  ARCHIVE_PROJECT = 'archiveProject',
  AUTOMATED_APPROVAL_CPS = 'automatedApprovalCPS',
  BASIC_TERMS = 'basicTerms',
  BULK_TERMS = 'bulkTerms',
  BUDGET_ALLOCATION = 'budgetAllocation',
  REASSIGN_PAYMENT_TO_PROJECT = 'reassignPaymentToProject',
  BYPASS_SHOPIFY_INVENTORY = 'bypassShopifyInventory',
  CLIENT_IDLE_TIMEOUT = 'clientIdleTimeout',
  CONTENT_GUIDELINES = 'contentGuidelines',
  CREATOR_SEARCH_RELATIONSHIP_FILTERS = 'creatorSearchRelationshipFilters',
  DEMO = 'demo',
  DEMO_ACCOUNT_MODE = 'DemoAccountMode',
  DISABLE_IMAGE_SEARCH = 'disableImageSearch',
  DISABLE_MEMBER_PARTITION_PATH = 'disableMemberPartitionPath',
  EMAIL_SUBADDRESSING = 'EmailSubaddressing',
  EMAIL_TEMPLATE = 'emailTemplate',
  EMBEDDED_REPORTING = 'embeddedReporting',
  ENABLE_MULTIPLE_SHOPIFY = 'enableMultipleShopify',
  ENABLE_REQUIREMENTS = 'enableRequirements',
  EVENT_TRACKING_DISABLED = 'eventTrackingDisabled',
  GCR_SUBMISSION_HISTORY_ENABLED = 'gcrSubmission',
  KLAVIYO_ADVANCED_SETTINGS = 'klaviyoAdvancedSettings',
  GOOGLE_DRIVE_LIMITS = 'googleDriveLimits',
  GROUP_CONTENT_REVIEW = 'groupContentReview',
  HIDE_CREATOR_SEARCH = 'hideCreatorSearch',
  HIDE_IG_AUD_AUTH_ELIGIBILITY_RESTRICTION = 'hideIgAudAuthEligibilityRestriction',
  IGDM_ADOPTION_BANNER = 'igdmAdoptionBanner',
  IG_ESTIMATED_IMPRESSIONS = 'IgEstimatedImpressions',
  IG_PARTNERSHIP_ADS = 'IgPartnershipAds',
  IG_SETTINGS_UPDATE = 'IgSettingsUpdate',
  INCLUDE_COMPLETED_CONTENT_REVIEWS = 'include_completed_content_reviews',
  INBOX_FOLDERS_V2 = 'inboxFoldersV2',
  INBOX_SEARCH_FILTER = 'inboxSearchFilter',
  INSTAGRAM_BRANDED_CONTENT_TAGGING_V2 = 'InstagramBrandedContentTaggingV2',
  INSTAGRAM_CREATOR_SEACH_V2 = 'instagramCreatorSearchV2',
  INSTAGRAM_DM = 'instagramDM',
  INSTAGRAM_PARTNERSHIP_ADS = 'InstagramPartnershipAds',
  LIMITED_SHOPIFY_READ_CUSTOMERS = 'limitShopifyScope',
  MEMBER_PORTAL = 'MemberPortal',
  META_BRANDED_CONTENT = 'MetaBrandedContent',
  MIGRATE_TO_GRAPHQL = 'migrateToGraphQL',
  MULTICURRENCY = 'multiCurrency',
  OFFLINE_CREATOR_PRODUCT_SELECTION = 'OfflineCreatorProductSelection',
  ON_DEMAND_SOCIAL_ANALYTICS_REFRESH = 'onDemandSocialAnalyticsRefresh',
  PREPOPULATE_APPLICATION_FORM = 'PrepopulateApplicationForm',
  PRODUCT_COST = 'productCost',
  PROJECT_DASHBOARD_ACCEPTED_TERMS = 'projectDashboardAcceptedTerms',
  PROJECT_DASHBOARD_TERMS_CONTENT = 'projectDashboardTermsContent',
  PROJECT_PFA_COLUMNS = 'projectPFAColumns',
  REJECTMEMBERCONVERSIONS = 'rejectMemberConversions',
  RENAME_TERMS = 'renameTermsToBriefs',
  SHOPIFY_GDPR_DELETE = 'shopifyGDPRDelete',
  SHOPLESS_SHOPIFY_CONNECTION_ENABLED = 'shoplessShopifyConnectionEnabled',
  SHOW_INSTAGRAM_META = 'showInstagramMeta',
  SHOW_ONBOARDING = 'showOnboarding',
  SHOW_ONBOARDING_FOR_TESTING = 'showOnboardingForTesting',
  SOCIAL_ANALYTICS_ALL_TIME_DEFAULT = 'SocialAnalyticsAllTimeDefault',
  SOCIAL_ANALYTICS_COST_METRICS = 'socialAnalyticsCostMetrics',
  SOCIAL_REPORTS_V2 = 'socialReportsV2',
  SPA_INSIGHTS_DATE_FILTER = 'SpaInsightsDateFilter',
  TERMS_RECOMMENDED_PRICING = 'termsRecommendedPricing',
  TIKTOK_CREATOR_SEARCH_V2 = 'tiktokCreatorSearchV2',
  TIKTOK_SPARK_ADS = 'TiktokSparkAds',
  TIKTOK_SPARK_ADS_RECOMMENDATIONS = 'TikTokSparkAdsRecommendations',
  TIKTOK_TCM_ORDER = 'TikTokTCMOrder',
  TOP_NAVIGATION = 'topNavigation',
  TRAY_INTEGRATION = 'trayIntegration',
  UCE_INFLUENCER_WHITELISTING = 'UCEInfluencerWhitelisting',
  UPSELL_GATES = 'upsellGates',
  WORKFLOW = 'workflow',
  WORKET_MENU = 'workletMenu',
  YOUTUBE_CREATOR_SEARCH_V2 = 'youtubeCreatorSearchV2',
  PFA_V2 = 'pfaV2',
  PARSE_MEMBER_SEARCH_QUERY = 'parseMemberSearchQuery',
  SEND_WITH_SENDGRID = 'sendWithSendgrid',
  TEST_SHAREDRESOURCE_FOR_QA_LOGIN = 'testSharedResourceForQALogin',
  ARCHIVE_OFFER = 'archiveOffer',
  READ_UNREAD_FOR_ASPIRE_EMAIL = 'readUnreadForAspireEmail',
  INBOX_CC = 'inbox_cc',
  EDIT_UTM_FIELDS_ENABLED = 'editUtmFieldsEnabled',
  SHADCN_LAYOUT = 'shadcnLayout',
  SOCIAL_ANALYTICS_SOURCE_FILTER = 'socialAnalyticsSourceFilter',
  SOCIAL_ANALYTICS_MANUAL_ASSINGMENT_FILTER = 'socialAnalyticsManualAssignmentFilter',
  ALLOW_MULTIPLE_CONVERSIONS = 'allowMultipleConversions',
  THIRD_PARTY_TRACKING_URL = 'thirdPartyTrackingUrl',
  APPROVE_WITH_COMMENTS = 'approveWithComments',
  GCR_VERSION_SWITCH = 'gcrVersionSwitch',
  BUDGET_DASHBOARD = 'budgetDashboard',
  IS_MANAGE_DEEPLINKS_ALLOWED = 'isManageDeepLinksAllowed',
  AUTO_REJECT_CONVERSION = 'autoRejectConversion',
  PFA_DASHBOARD = 'pfaDashboard',
  REFRESH_UI = 'refreshUi',
  DEDUPLICATE_CONVERSIONS = 'deduplicateConversions',
  COUNTER_SIGN_BRIEF = 'counterSignBrief',
  CONTENT_SUBMISSION_FORM = 'contentSubmissionForm',
  SHIPPING_LINE_SETTINGS = 'shippingLineSettings',
  DEFAULT_AUTOMATIONS = 'defaultAutomations',
}

import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  INSTALL_APPLICATION_MUTATION,
  GET_APPLICATION_INSTANCES_QUERY,
} from '@frontend/app/queries';
import {
  GetApplicationInstancesQuery,
  GetApplicationInstancesQueryVariables,
} from '@frontend/app/queries/types/GetApplicationInstancesQuery';
import {
  InstallApplicationMutation,
  InstallApplicationMutationVariables,
} from '@frontend/app/queries/types/InstallApplicationMutation';
import { IApplicationInstance } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, instance: IApplicationInstance) => {
  let data: GetApplicationInstancesQuery;
  try {
    data = store.readQuery<
      GetApplicationInstancesQuery,
      GetApplicationInstancesQueryVariables
      >({
        query: GET_APPLICATION_INSTANCES_QUERY,
        variables: {
          applicationId: instance.applicationId,
        },
      });
  } catch (err) {
    console.log(`cache missing: ${GET_APPLICATION_INSTANCES_QUERY}`);
    return;
  }

  // Write our data back to the cache.
  store.writeQuery<GetApplicationInstancesQuery, GetApplicationInstancesQueryVariables>({
    query: GET_APPLICATION_INSTANCES_QUERY,
    variables: {
      applicationId: instance.applicationId,
    },
    data: {
      ...data,
      instances: unionBy(data.instances, [instance], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<InstallApplicationMutation, InstallApplicationMutationVariables>;

export function useInstallApplication(options: IOptions = {}) {
  const [installApplication, { loading, error }] = useMutation<
    InstallApplicationMutation,
    InstallApplicationMutationVariables
  >(INSTALL_APPLICATION_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data.instance);
    },
    onCompleted() {
      message.info('Successfully installed application');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    installApplication,
    loading,
    error,
  };
}

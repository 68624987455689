import * as React from 'react';
import cx from 'classnames';
import { split, last } from 'lodash';

import {
  Link, Route, Redirect, Switch,
  useRouteMatch, useParams, useLocation,
} from 'react-router-dom';

import {
  useGetCommunityById,
} from '@frontend/app/hooks';

import {
  Button, Typography,
  Menu, Dropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
const { Title } = Typography;
import { LoadSpinner } from '@frontend/lib';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy, useState } = React;
import styles from './CommunityDetailView.scss';

const CommunityDetails = lazy(() => import('./CommunityDetailView/CommunityDetails'));
const CommunityPrograms = lazy(() => import('./CommunityDetailView/CommunityPrograms'));

interface IMatchParams {
  communityId: string;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const CommunityDetailView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();
  const location = useLocation();
  const { communityId } = useParams<IMatchParams>();
  const { community, loading } = useGetCommunityById({
    variables: {
      id: Number(communityId),
    }
  });
  const lastRoute = last(split(location.pathname, '/'));
  const [selectedRoute, setSelectedRoute] = useState(
    ['details', 'programs'].includes(lastRoute) ? lastRoute : 'details',
  );

  const handleActionClick = ({ key }) => {
    switch (key) {
      case 'edit': {
        alert('Not implemented yet');

        break;
      }
      case 'delete': {
        alert('Not implemented yet');

        break;
      }
      default: {
        return;
      }
    }
  };
  const handleNavClick = ({ key }) => setSelectedRoute(key);

  const ActionMenu = (
    <Menu onClick={handleActionClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  return (
    <div className={cx(styles.CommunityDetailView, props.className)}>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && community &&
        <>
          <div className={styles.header}>
            <Title>{community.title}</Title>
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <Button className={styles.action}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className={styles.children}>
            <Menu
              className={styles.menu}
              onClick={handleNavClick}
              mode="horizontal"
              selectedKeys={[selectedRoute]}
            >
              <Menu.Item key="details">
                <Link to={`${match.url}/details`}>Details</Link>
              </Menu.Item>
              <Menu.Item key="programs">
                <Link to={`${match.url}/programs`}>Programs</Link>
              </Menu.Item>
            </Menu>
            <Switch>
              <Route path={`${match.path}/details`} render={
                (props) => lazyLoadComponent(CommunityDetails)({ ...props, community })
              } />
              <Route path={`${match.path}/programs`} render={
                (props) => lazyLoadComponent(CommunityPrograms)({ ...props, community })
              } />
              <Redirect from={`${match.path}`} to={`${match.path}/details`} />
            </Switch>
          </div>
        </>
      }
    </div>
  );
});

export default CommunityDetailView;

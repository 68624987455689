import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';

import {
  Form, Drawer, Checkbox,
  Button, Input, Select,
} from 'antd';
const { Option } = Select;

import { IUser, useGetClients, useGetClientFeatures, } from '@frontend/app/hooks';

const { useEffect } = React;
import styles from './EditUserDrawer.scss';

export interface ICreateUserParams {
  email: string;
  clientIds: string[];
  isAdmin: boolean;
  name: string;
  featureIds: number[];
}
interface IProps {
  open: boolean;
  onRequstClose(): void;

  onSubmit(client: ICreateUserParams): void;
  isSubmitting: boolean;
  // edit mode
  user?: IUser;

  className?: string;
}
interface IEditUserFormProps {
  onSubmit(client: ICreateUserParams): void;
  isSubmitting: boolean;

  user?: IUser;
}
const EditUserForm: React.FunctionComponent<IEditUserFormProps> = React.memo((props) => {
  const [form] = Form.useForm();
  const { loading, clients } = useGetClients();
  const { features } = useGetClientFeatures();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  useEffect(() => {
    const { user } = props;
    if (!isEmpty(user)) {
      const values = {
        email: user.email,
        clientIds: map(user.clients, 'id'),
        isAdmin: user.isAdmin,
        name: user.name,
        featureIds: user.featureIds,
      };

      form.setFieldsValue(values);
    }
  }, [form, props.user]);

  const onFinish = (values) => {
    console.log('Success:', values);
    const { featureIds, ...rest } = values;

    props.onSubmit({
      ...rest,
      featureIds: map(featureIds, (featureId) => parseInt(featureId, 10)),
    });
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={styles.form}
      {...formItemLayout}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: 'email',
            message: `The input is not a valid Email!`,
          },
          {
            required: true,
            message: `Please enter user's Email!`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Clients"
        name="clientIds"
        rules={[
          { type: 'array', required: true, message: 'Please select the clients this user can access!' },
        ]}
      >
        <Select
          mode="multiple"
          loading={loading}
          disabled={loading}
          placeholder="Select clients this user has access to"
          optionFilterProp="name"
        >
          {map(clients, (client) => (
            <Option
              key={client.id}
              value={client.id}
              name={client.name}
            >{client.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: `Please enter user's name!`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Client Features"
        name="featureIds"
      >
        <Select
          mode="multiple"
          placeholder="Please select client features"
          optionFilterProp="name"
        >
          {map(features, (feature) => (
            <Option
              key={feature.id}
              value={feature.id}
              name={feature.name}
            >{feature.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Admin Portal Access"
        name="isAdmin"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isSubmitting}
        className={styles.submitButton}
      >
        Submit
      </Button>
    </Form>
  );
});
/**
 * @type {React.FunctionComponent}
 */
export const EditUserDrawer: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <Drawer
      title={isEmpty(props.user) ? 'Create User' : 'Edit User'}
      visible={props.open}
      width={600}
      onClose={props.onRequstClose}
      className={cx(styles.EditUserDrawer, props.className)}
    >
      <EditUserForm
        // this resets form when closed
        user={props.open && props.user}
        onSubmit={props.onSubmit}
        isSubmitting={props.isSubmitting}
      />
    </Drawer>
  );
});

EditUserDrawer.defaultProps = {
  className: null,
};

import * as React from 'react';
import { isNull, isUndefined } from 'lodash';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import type { ClientFeature } from '@services/users/types/ClientFeature';
import {
  IsClientFeatureEnabled,
  IsClientFeatureEnabledVariables,
} from '@frontend/app/queries/types/IsClientFeatureEnabled';
import { IS_CLIENT_FEATURE_ENABLED } from '../../queries';

const { useMemo } = React;

type IOptions = QueryHookOptions<IsClientFeatureEnabled, IsClientFeatureEnabledVariables>;

export const useClientFeatureEnabled = (name: ClientFeature, clientId: string, options?: IOptions): boolean => {
  const { loading, data: { isClientFeatureEnabled } = {} } = useQuery<IsClientFeatureEnabled>(
    IS_CLIENT_FEATURE_ENABLED,
    {
      ...options,
      variables: {
        name,
        clientId,
      },
      skip: isNull(name) || options?.skip,
    },
  );

  return useMemo((): boolean => {
    if (loading || isUndefined(isClientFeatureEnabled)) {
      return false;
    }
    return isClientFeatureEnabled;
  }, [isClientFeatureEnabled, loading]);
};

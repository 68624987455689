import * as React from 'react';
import cx from 'classnames';
import { toString } from 'lodash';

import {
  useHistory, useRouteMatch,
} from 'react-router-dom';

import { Button, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Column } = Table;
const { Title } = Typography;
import { EditAppDrawer } from './EditAppDrawer';
import { LoadSpinner } from '@frontend/lib';

import {
  IApplication,
  useGetApplications,
  useSaveApplication,
} from '@frontend/app/hooks';
import { logger } from '@common';

const { useState } = React;
import styles from './ApplicationListView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ApplicationListView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { loading, applications } = useGetApplications();
  const {
    saveApplication, loading: creating,
  } = useSaveApplication();

  logger.debug({
    loading,
    applications,
  });

  return (
    <div className={cx(styles.ApplicationListView, props.className)}>
      <div className={styles.header}>
        <Title>Applications</Title>
        <Button
          className={styles.createButton}
          icon={<PlusOutlined />}
          type="primary"
          onMouseDown={() => setOpenDrawer(true)}
        >
          Create Application
        </Button>
        <EditAppDrawer
          open={openDrawer}
          onRequstClose={() => setOpenDrawer(false)}
          isSubmitting={creating}
          onSubmit={(data) => {
            saveApplication({
              variables: {
                data,
              },
            }).then(() => setOpenDrawer(false));
          }}
        />
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && applications &&
        <Table
          rowKey={(app: IApplication) => toString(app.id)}
          dataSource={applications}
          className={styles.table}
        >
          <Column title="Id" key="id" render={(_, app: IApplication) => (
            <span
              className={styles.nameCell}
              onClick={() => history.push(`${match.url}/${app.id}`)}
            >
              {app.id}
            </span>
          )} />
          <Column title="Name" dataIndex="name" />
          <Column title="Icon" dataIndex="icon" />
        </Table>
      }
    </div>
  );
});

export default ApplicationListView;

import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import {
  UPLOAD_TAGS_CSV,
} from '@frontend/app/queries';
import {
  UploadTagsCSV,
  UploadTagsCSVVariables,
} from '@frontend/app/queries/types/UploadTagsCSV';

const { useEffect } = React;

type IOptions = MutationHookOptions<UploadTagsCSV, UploadTagsCSVVariables>;

export function useUploadMemberTagCSV(options: IOptions = {}) {
  const [uploadMemberTagCSV, { loading, error }] = useMutation<
    UploadTagsCSV,
    UploadTagsCSVVariables
  >(UPLOAD_TAGS_CSV, {
    ...options,
    onCompleted() {
      message.info('Successfully uploaded the csv');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    uploadMemberTagCSV,
    loading,
    error,
  };
}

import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { USER_QA_LOGIN_MUTATION } from '@frontend/app/queries';
import {
  UserQALoginMutation,
  UserQALoginMutationVariables,
} from '@frontend/app/queries/types/UserQALoginMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<UserQALoginMutation, UserQALoginMutationVariables>;
export function useUserQALogin(options: IOptions = {}) {
  const [userQALogin, { loading, error }] = useMutation<
    UserQALoginMutation,
    UserQALoginMutationVariables
  >(USER_QA_LOGIN_MUTATION, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    userQALogin,
    loading,
  };
}

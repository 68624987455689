import { useSaveEligibleMemberFieldForApplicantReview } from '@frontend/app/hooks/useSaveEligibleMemberSchemaForApplicantReviewMutation';
import { LoadSpinner } from '@frontend/lib';
import { Button, Form, Input, Row, Table } from '@revfluence/fresh';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { logger } from '../../../../../../../common/Logger';
import { useGetEligibleMemberForApplicantReview } from '@frontend/app/hooks/useGetEligibleMemberForApplicantReview';
import { useGetClientMetadata } from '@frontend/app/hooks/useGetClientMetaData';
import { message, Select } from 'antd';
import { cloneDeep, find, map } from 'lodash';
import { GET_CLIENT_META_DATA } from '@frontend/app/queries/getClientMetaDataQuery';
import { ColorPicker } from '@frontend/app/components/ColorPicker/ColorPicker';



interface IMatchParams {
    clientId: string;
}
interface IProps extends RouteComponentProps<IMatchParams> {
    className?: string;
}

const { Column } = Table;
const { Option } = Select;
const DEFAULT_VALUE = "Select member field"
interface IAttribute{
    id: number,
    name: string,
    description: string,
    fieldId?: string | number,
    fieldName?: string,
}

interface ITag {
    id: number,
    tagId: string,
    tagName?: string,
    tagColor?: string,
}
const defaultColor = '#086161'
const initAttributes: IAttribute[] = [
    { id: 1, name: 'Brand Approval', description: 'This field configures ability to approve or reject the creator. Field should be named Brand Approval and contain Approved/Rejected/Maybe as options'},
    { id: 2, name: 'Notes', description: 'This field configures ability to add notes. Field should named Brand Note and be a text type'},
    { id: 3, name: 'Pronouns', description: 'This field will show in front of the name of the creator as their pronoun'},
    { id: 4, name: 'Attribute 1', description: 'Attribute for creator' },
    { id: 5, name: 'Attribute 2', description: 'Attribute for creator' },
    { id: 6, name: 'Attribute 3', description: 'Attribute for creator' },
    { id: 7, name: 'Attribute 4', description: 'Attribute for creator' },
    { id: 8, name: 'Attribute 5', description: 'Attribute for creator' },
    { id: 9, name: 'Table Column 1', description: 'Customize column 1 in group data' },
    { id: 10, name: 'Table Column 2', description: 'Customize column 2 in group data' },
];

const initTags: ITag[] = [
  { id: 1, tagId: 'Tag 1', tagColor: defaultColor },
  { id: 2, tagId: 'Tag 2', tagColor: defaultColor },
  { id: 3, tagId: 'Tag 3', tagColor: defaultColor },
  { id: 4, tagId: 'Tag 4', tagColor: defaultColor },
  { id: 5, tagId: 'Tag 5', tagColor: defaultColor },
  { id: 6, tagId: 'Tag 6', tagColor: defaultColor },
  { id: 7, tagId: 'Tag 7', tagColor: defaultColor },
  { id: 8, tagId: 'Tag 8', tagColor: defaultColor },
];

const ApplicantReview: React.FunctionComponent<IProps> = React.memo((props) => {
    const { clientId } = props.match.params;
    const { data: eligibleMemberData, loading: getEligibleMemberLoading } = useGetEligibleMemberForApplicantReview(clientId); // to get the eligible member schema
    const { data: clientMetaData, loading: clientMetaDataLoading } = useGetClientMetadata(clientId); // to get the save eligible member schema
    const [attributes, setAttributes] = useState<IAttribute[]>(initAttributes);
    const [tags, setTags] = useState<ITag[]>(initTags);
    const [saveEligibleMemberFieldForApplicantReview] = useSaveEligibleMemberFieldForApplicantReview(
        {
            refetchQueries: [
                { query: GET_CLIENT_META_DATA, variables: { clientId: clientId } },
            ]
        }
    );
    useEffect(() => {
        if (clientMetaData?.getClientMetadata?.applicantReviewFields && eligibleMemberData?.MemberFieldForApplicantReview) {
            setAttributes((prevAttributes) => {
                return map(prevAttributes, (curAttribute) => {
                    if (clientMetaData.getClientMetadata.applicantReviewFields?.[curAttribute.name]) {
                      const fieldId = clientMetaData.getClientMetadata.applicantReviewFields[curAttribute.name];
                      const fieldInMemberSchema = find(eligibleMemberData.MemberFieldForApplicantReview, curMemberField => curMemberField.id === fieldId)
                      if (
                        fieldInMemberSchema
                      ) {
                        return {
                          ...curAttribute,
                          fieldId,
                          fieldName: fieldInMemberSchema.name
                        };
                      }
                    }
                    return curAttribute
                })
            })
            if(clientMetaData.getClientMetadata.applicantReviewFields.tags){
                const { tags: tagsInApplicantReview } = clientMetaData.getClientMetadata.applicantReviewFields;
                setTags(prevTags => {
                  return map(prevTags, curTag => {
                    const tagDataForCurTag = tagsInApplicantReview[curTag.tagId];
                    if (tagDataForCurTag) {
                      return {
                        ...curTag,
                        tagColor: tagDataForCurTag.tagColor,
                        tagName: tagDataForCurTag.tagName,
                      };
                    }
                    return {
                      ...curTag,
                      tagColor: undefined,
                      tagName: undefined,
                    };
                  });
                });
            }
        }
    }, [clientMetaData, eligibleMemberData]);


    const handleSave = async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const fields: Record<string, string | number | any> = {}
            map(attributes, attribute => {
              if (attribute.fieldId) {
                fields[attribute.name] = attribute.fieldId;
              }
            });
            map(tags, (curTag: ITag)=>{
                if(curTag.tagName){
                    fields.tags = {
                      ...(fields.tags || {}),
                      [curTag.tagId]: {
                        tagColor: curTag.tagColor || defaultColor,
                        tagName: curTag.tagName.trim(),
                      },
                    };
                }
            })
            const result = await saveEligibleMemberFieldForApplicantReview({ variables: { clientId, fields } });
            if (result) {
                message.info('Member fields saved successfully');
            }
        } catch (error) {
            logger.error(error);
            message.error('Failed to save');
        }
    };

    const handleAttributeChange = (value, record) => {
        const currentAttributes = cloneDeep(attributes);
        const recordInAttributes = find(currentAttributes, attribute => attribute.id === record.id);
        if (value !== DEFAULT_VALUE) {
            recordInAttributes.fieldId = value;
            recordInAttributes.fieldName = find(
              eligibleMemberData.MemberFieldForApplicantReview,
              curMemberField => curMemberField.id === value,
            )?.name;
        } else {
            recordInAttributes.fieldId = undefined;
            recordInAttributes.fieldName = undefined
        }
        setAttributes(currentAttributes);
    }

    const handleTagNameChange = (value, record)=>{
        const currentTags = cloneDeep(tags)
        const recordInTags = find(currentTags, currenttag => currenttag.tagId === record.tagId);
        recordInTags.tagName = value
        setTags(currentTags)
    }

    const handleTagColourChange = (value, record)=>{
        const currentTags = cloneDeep(tags)
        const recordInTags = find(currentTags, currenttag => currenttag.tagId === record.tagId);
        recordInTags.tagColor = value
        setTags(currentTags)
    }

    if (getEligibleMemberLoading || clientMetaDataLoading) return <LoadSpinner />;
    return (
      <>
        <Form>
          <Table dataSource={attributes.sort((a, b) => a.id - b.id)} rowKey="id" pagination={false}>
            <Column title="Attribute" dataIndex="name" key="name" width={150} />
            <Column title="Description" dataIndex="description" key="description" />
            <Column
              title="Select"
              key="select"
              render={(_text, record: any) => (
                <Select
                  value={record.fieldName || DEFAULT_VALUE}
                  onChange={value => handleAttributeChange(value, record)}
                  style={{ width: 220 }}
                >
                  <Option value={DEFAULT_VALUE}>Select member field</Option>
                  {eligibleMemberData.MemberFieldForApplicantReview.sort((a, b) => a.name.localeCompare(b.name)).map(
                    item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ),
                  )}
                </Select>
              )}
            />
          </Table>

          <Table dataSource={tags.sort((a, b) => a.id - b.id)} rowKey="id" pagination={false}>
            <Column title="Tag" dataIndex="tagId" key="tagId" width={150} />
            <Column
              title="Tag name"
              dataIndex="tagName"
              key="tagName"
              render={(_, record: any)=>{
                return <Input
                   placeholder ={'Please enter tag name'}
                     value={record.tagName}
                     onChange={event => handleTagNameChange(event.target.value, record)}
                   />
              }}
            />
            <Column
              title="Color"
              key="select"
              render={(_text, record: any) => (
                <ColorPicker
                  defaultColor={record.tagColor || defaultColor}
                  onChange={value => handleTagColourChange(value, record)}
                  color={record.tagColor}
                />
              )}
            />
          </Table>
          <Row justify="end" style={{ marginTop: '20px' }}>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Row>
        </Form>
      </>
    );
});

export default ApplicantReview;

/* Text styles */
._UserDetailView_1sa7i_2 ._header_1sa7i_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._UserDetailView_1sa7i_2 ._header_1sa7i_2 ._action_1sa7i_7 {
  margin-left: auto;
}
._UserDetailView_1sa7i_2 ._children_1sa7i_10 ._menu_1sa7i_10 {
  margin-bottom: 20px;
}
import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { Dropdown, Menu, Avatar } from 'antd';
import { SettingOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';

import { useAuth } from '@frontend/context/authContext';
import { useRestoreClientsAndUsers, useSetRotatingTokens } from '@frontend/app/hooks';

import styles from './UserMenu.scss';

interface IProps {
  className?: string;
}


/**
 * @type {React.FC}
 */
export const UserMenu: React.FC<IProps> = React.memo((props) => {
  const history = useHistory();
  const { user, logout } = useAuth();
  const { restoreClientsAndUsers } = useRestoreClientsAndUsers();
  const { setRotatingTokens } = useSetRotatingTokens();

  const menu = (
    <Menu className={styles.UserMenuDropdown}>
      <Menu.Item key="0" onClick={() => {
        restoreClientsAndUsers().then(() => {
          console.log('succeeded');

          history.push('/');
        });
      }}>
        <SettingOutlined className={styles.icon} />
        Restore brands and users
      </Menu.Item>
      <Menu.Item key="1" onClick={() => {
        setRotatingTokens().then(() => {
          console.log('succeeded');

          history.push('/');
        });
      }}>
        <SettingOutlined className={styles.icon} />
        Set Rotating Tokens on all clients.
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={() => logout()}>
        <LogoutOutlined className={styles.icon} />
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown className={cx(styles.UserMenu, props.className)} overlay={menu} trigger={['click']}>
      <div>
        <Avatar className={styles.avatar} src={user.picture} />
        <span className={styles.name}>{user.name}</span>
        <DownOutlined />
      </div>
    </Dropdown>
  );
});

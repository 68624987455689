import * as React from 'react';
import cx from 'classnames';
import { split, last } from 'lodash';

import {
  Link, Route, Redirect, Switch,
  useRouteMatch, useHistory, useParams, useLocation,
} from 'react-router-dom';

import {
  useGetOrganizationById,
  useDeleteOrganizationById,
} from '@frontend/app/hooks';

import {
  Button, Typography,
  Menu, Dropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
const { Title } = Typography;
import { LoadSpinner } from '@frontend/lib';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy, useState } = React;
import styles from './OrganizationDetailView.scss';

const OrganizationDetails = lazy(() => import('./OrganizationDetailView/OrganizationDetails'));
const ClientGroupsView = lazy(() => import('./ClientGroup/ClientGroupsView'));

interface IMatchParams {
  organizationId: string;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const OrganizationDetailView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { organizationId } = useParams<IMatchParams>();
  const { organization, loading } = useGetOrganizationById({
    variables: {
      id: Number(organizationId),
    }
  });
  const { deleteOrganizationById } = useDeleteOrganizationById();
  const lastRoute = last(split(location.pathname, '/'));
  const [selectedRoute, setSelectedRoute] = useState(
    ['details', 'client_groups'].includes(lastRoute) ? lastRoute : 'details',
  );

  const handleActionClick = ({ key }) => {
    switch (key) {
      case 'edit': {
        alert('Not implemented yet');

        break;
      }
      case 'delete': {
        if (confirm(
          `You are going to delete ${organization.name}. This is irreversible action. \nAre you sure?`,
        )) {
          deleteOrganizationById({
            variables: {
              id: organization.id,
            },
          }).then(() => history.push('/orgs'));
        }

        break;
      }
      default: {
        return;
      }
    }
  };
  const handleNavClick = ({ key }) => setSelectedRoute(key);

  const ActionMenu = (
    <Menu onClick={handleActionClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  return (
    <div className={cx(styles.OrganizationDetailView, props.className)}>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && organization &&
        <>
          <div className={styles.header}>
            <Title>{organization.name}</Title>
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <Button className={styles.action}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className={styles.children}>
            <Menu
              className={styles.menu}
              onClick={handleNavClick}
              mode="horizontal"
              selectedKeys={[selectedRoute]}
            >
              <Menu.Item key="details">
                <Link to={`${match.url}/details`}>Details</Link>
              </Menu.Item>
              <Menu.Item key="client_groups">
                <Link to={`${match.url}/client_groups`}>Client Groups</Link>
              </Menu.Item>
            </Menu>
            <Switch>
              <Route path={`${match.path}/details`} render={
                (props) => lazyLoadComponent(OrganizationDetails)({ ...props, organization: organization })
              } />
              <Route path={`${match.path}/client_groups`} render={
                (props) => lazyLoadComponent(ClientGroupsView)({ ...props, organization: organization })
              } />
              <Redirect from={`${match.path}`} to={`${match.path}/details`} />
            </Switch>
          </div>
        </>
      }
    </div>
  );
});

export default OrganizationDetailView;

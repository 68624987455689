import gql from 'graphql-tag';

export const RESTORE_CLIENTS_AND_USERS_MUTATION = gql`
  mutation RestoreClientsAndUsersMutation {
    succeeded: restoreClientsAndUsers
  }
`;

export const SET_ROTATING_TOKENS_MUTATION = gql`
  mutation SetRotatingTokensMutation {
    succeeded: setRotatingTokens
  }
`;

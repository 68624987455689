import * as React from 'react';
import cx from 'classnames';

import { Button, Alert } from 'antd';

import { IAuthError } from '@frontend/context/authContext';

import styles from './LoginView.scss';

interface IProps {
  onSignIn(pathname: string): void;
  error: IAuthError;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const LoginView: React.FunctionComponent<IProps> = React.memo((props) => {
  const handleSignIn = () => props.onSignIn(window.location.pathname);

  return (
    <div className={cx(styles.LoginView, props.className)}>
      <div className={styles.card}>
        <div className={styles.logo} />
        <Button
          onMouseDownCapture={handleSignIn}
          className={styles.loginButton}
          type="primary"
          size="large"
        >
          Sign In
        </Button>
        {
          props.error
          && (
            <Alert
              className={styles.errorMessage}
              type="error"
              message={props.error.message}
            />
          )
        }
      </div>
    </div>
  );
});

LoginView.displayName = 'LoginView'; // Add display name to the component

import * as React from 'react';
import { useGetProgramById } from '@frontend/app/hooks';

const { useContext, useState, useEffect } = React;

export interface IProgramLandingPageProperties {
  accentColor?: string;
  primaryColor?: string;
  logoUrl?: string;
  brandImage1?: string;
  brandImage2?: string;
  brandImage3?: string;
  brandImage4?: string;
  brandImage5?: string;
  tagLine?: string;
  description?: string;
  perks?: ITextContent[];
  whoYouAre?: ITextContent[];
  joinText?: string;
  copyright?: string;
  email?: string;
  favicon?: string;
}

interface ITextContent {
  content: string;
}

interface IProgramOnboardingContext {
  programId: number;
  properties: IProgramLandingPageProperties;
  onChangeProperties(properties: IProgramLandingPageProperties);
}

interface IProps {
  programId: number;
}

const ProgramOnboardingContext = React.createContext<IProgramOnboardingContext>(null);

export const useProgramOnboardingContext = () => useContext(ProgramOnboardingContext);

export const ProgramOnboardingProvider: React.FC<IProps> = ({ children, programId }) => {
  const {
    data: {
      program = null,
    } = {},
  } = useGetProgramById({
    variables: {
      id: programId,
    },
    skip: !programId,
  });

  const [properties, setProperties] = useState<IProgramLandingPageProperties>();

  useEffect(() => {
    if (program) {
      setProperties(program.customLandingPageProperties);
    }
  }, [program]);

  return (
    <ProgramOnboardingContext.Provider
      value={{
        programId,
        properties,
        onChangeProperties: setProperties,
      }}
    >
      {children}
    </ProgramOnboardingContext.Provider>
  );
};

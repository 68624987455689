import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  GET_CLIENTS_QUERY,
  CLIENT_COMPLETED_ONBOARDING_MUTATION,
} from '@frontend/app/queries';
import { GetClientsQuery } from '@frontend/app/queries/types/GetClientsQuery';
import {
  ClientCompletedOnboardingMutation,
  ClientCompletedOnboardingMutationVariables,
} from '@frontend/app/queries/types/ClientCompletedOnboardingMutation';
import { IClient } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, client: IClient) => {
  let data: GetClientsQuery;
  try {
    data = store.readQuery<GetClientsQuery>({ query: GET_CLIENTS_QUERY });
  } catch (err) {
    console.log(`cache missing: ${GET_CLIENTS_QUERY}`);
    return;
  }

  // Write our data back to the cache.
  store.writeQuery<GetClientsQuery>({
    query: GET_CLIENTS_QUERY,
    data: {
      ...data,
      clients: unionBy(data.clients, [client], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<ClientCompletedOnboardingMutation, ClientCompletedOnboardingMutationVariables>;

export function useClientCompletedOnboarding(options: IOptions = {}) {
  const [clientCompletedOnboarding, { loading, error }] = useMutation<
  ClientCompletedOnboardingMutation,
  ClientCompletedOnboardingMutationVariables
  >(CLIENT_COMPLETED_ONBOARDING_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data.client);
    },
    onCompleted() {
      message.info('Successfully completed client onboarding.');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    clientCompletedOnboarding,
    loading,
    error,
  };
}

import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import {
  DELETE_CLIENT_FEATURE_MUTATION,
} from '@frontend/app/queries';
import {
  DeleteClientFeatureMutation,
  DeleteClientFeatureMutationVariables,
} from '@frontend/app/queries/types/DeleteClientFeatureMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<DeleteClientFeatureMutation, DeleteClientFeatureMutationVariables>;
export function useDeleteClientFeatureById(options: IOptions = {}) {
  const [deleteClientFeatureById, { loading, error }] = useMutation<
    DeleteClientFeatureMutation,
    DeleteClientFeatureMutationVariables
  >(DELETE_CLIENT_FEATURE_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully deleted client feature');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteClientFeatureById,
    loading,
  };
}

._ClientUsers_fti0l_1 ._header_fti0l_1 {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientUsers_fti0l_1 ._header_fti0l_1 ._manageUsersButton_fti0l_7 {
  margin-left: auto;
}
._ClientUsers_fti0l_1 ._table_fti0l_10 ._nameCell_fti0l_10 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientUsers_fti0l_1 ._table_fti0l_10 ._nameCell_fti0l_10 ._alertIcon_fti0l_15 {
  margin-right: 6px;
  color: #da3737;
}
._ClientUsers_fti0l_1 ._table_fti0l_10 ._nameCell_fti0l_10 ._avatar_fti0l_19 {
  margin-right: 10px;
}
._ClientUsers_fti0l_1 ._table_fti0l_10 ._nameCell_fti0l_10 ._name_fti0l_10 {
  flex: 1;
  cursor: pointer;
  user-select: none;
  color: #3996e0;
}
._ClientUsers_fti0l_1 ._table_fti0l_10 ._roleCell_fti0l_28 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
._ClientUsers_fti0l_1 ._table_fti0l_10 ._roleCell_fti0l_28 ._roleItem_fti0l_33 {
  margin-bottom: 5px;
}

._AccessModal_fti0l_37 ._select_fti0l_37 {
  width: 100%;
}
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';
import { GetClientMetadata, GetClientMetadataVariables } from '../queries/types/GetClientMetadata';
import { GET_CLIENT_META_DATA } from '../queries/getClientMetaDataQuery';

type IOptions = QueryHookOptions<GetClientMetadata, GetClientMetadataVariables>;
export const useGetClientMetadata = (clientId: string, options: IOptions = {}) => (
    useQuery<GetClientMetadata, GetClientMetadataVariables>(GET_CLIENT_META_DATA, {
        variables: {
            clientId,
        },
        ...options,
    })
);

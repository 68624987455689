import * as React from 'react';
import cx from 'classnames';
import { toString } from 'lodash';

import {
  useHistory, useRouteMatch,
} from 'react-router-dom';

import { Button, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Column } = Table;
const { Title } = Typography;
import { EditOrgDrawer } from './EditOrgDrawer';
import { LoadSpinner } from '@frontend/lib';
import getColumnSearchProps from '@frontend/app/utils/getColumnSearchProps/getColumnSearchProps';

import {
  IOrganization,
  useGetOrganizations,
  useSaveOrganization,
} from '@frontend/app/hooks';
import { logger } from '@common';

const { useState } = React;
import styles from './OrganizationListView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const OrganizationListView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { loading, organizations } = useGetOrganizations();
  const {
    saveOrganization, loading: creating,
  } = useSaveOrganization();

  const goToDetailView = (orgId) => {
    history.push(`${match.url}/${orgId}`);
  };

  logger.debug({
    loading,
    data: organizations,
  });

  return (
    <div className={cx(styles.OrganizationListView, props.className)}>
      <div className={styles.header}>
        <Title>Organizations</Title>
        <Button
          className={styles.createButton}
          icon={<PlusOutlined />}
          type="primary"
          onMouseDown={() => setOpenDrawer(true)}
        >
          Create Organization
        </Button>
        <EditOrgDrawer
          open={openDrawer}
          onRequstClose={() => setOpenDrawer(false)}
          isSubmitting={creating}
          onSubmit={(org) => {
            saveOrganization({
              variables: {
                data: org,
              },
            }).then(({ data: { organization }}) => {
              setOpenDrawer(false);

              goToDetailView(organization.id);
            });
          }}
        />
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && organizations &&
        <Table
          rowKey={(record: IOrganization) => toString(record.id)}
          dataSource={organizations}
          className={styles.table}
        >
          <Column
            title="Name"
            key="name"
            render={(_, record: IOrganization) => (
              <span
                className={styles.nameCell}
                onClick={goToDetailView.bind(this, record.id)}
              >
                {record.name}
              </span>
            )}
            {...getColumnSearchProps('name')}
          />
          <Column title="Id" dataIndex="id" />
        </Table>
      }
    </div>
  );
});

export default OrganizationListView;

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_ALL_CLIENT_FEATURES_QUERY } from '@frontend/app/queries';
import {
  GetAllClientFeaturesQuery,
  GetAllClientFeaturesQuery_features,
} from '@frontend/app/queries/types/GetAllClientFeaturesQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetAllClientFeaturesQuery>;

export type IClientFeature = GetAllClientFeaturesQuery_features;

export function useGetClientFeatures(options: IOptions = {}) {
  const {
 loading, data: { features = [] } = {}, error, refetch,
} = useQuery<GetAllClientFeaturesQuery>(
    GET_ALL_CLIENT_FEATURES_QUERY,
    {
      ...options,
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    features,
    error,
    refetch,
  };
}

import * as React from 'react';
import { capitalize } from 'lodash';
import { logger } from '@common';
import { CircleCheckIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';

import {
 Button, Tag, message,
} from '@revfluence/fresh';

import { useDeprecateAuthProviderMutation } from '@frontend/app/hooks';

const { useState, useCallback } = React;

interface IActionCellProps {
  email: string;
  clientId: string;
}
const ActionCell: React.FC<IActionCellProps> = React.memo(({ email, clientId }) => {
  const [localState, setLocalState] = useState<'default' | 'success' | 'error'>('default');
  const [disconnect, { loading }] = useDeprecateAuthProviderMutation({
    onCompleted() {
      setLocalState('success');
    },
    onError(error) {
      setLocalState('error');
      logger.error(`Failed to disconnect emails ${email}: ${error}`, error);
      message.error('Failed to disconnect. Please try refreshing.');
    },
    context: {
      headers: {
        'requested-client-id': clientId,
      },
    },
  });

  const onDisconnectClick = useCallback(() => {
    disconnect({
      variables: {
        userExternalDisplayId: email,
      },
    });
  }, [email, disconnect]);

  const renderState = useCallback(() => (
    <Tag icon={localState === 'error' ? <TriangleExclamationIcon /> : <CircleCheckIcon />} color={localState}>
      {capitalize(localState)}
    </Tag>
  ), [localState]);

  const renderDefault = useCallback(() => (
    <Button type="primary" danger onClick={onDisconnectClick} loading={loading}>
      Disconnect
    </Button>
  ), [onDisconnectClick, loading]);

  const renderCell = useCallback(() => {
    if (localState === 'success' || localState === 'error') return renderState();
    return renderDefault();
  }, [localState, renderState, renderDefault]);

  return renderCell();
});
ActionCell.displayName = 'ActionCell';

export default ActionCell;

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_CLIENT_QUERY } from '@frontend/app/queries';
import {
  GetClientQuery,
  GetClientQueryVariables,
  GetClientQuery_client,
} from '@frontend/app/queries/types/GetClientQuery';

const { useEffect } = React;
export type IClient = GetClientQuery_client;
type IOptions = QueryHookOptions<GetClientQuery, GetClientQueryVariables>;

export function useGetClientById(options: IOptions = {}) {
  const {
 loading, data: { client = null } = {}, error, refetch,
} = useQuery<
    GetClientQuery,
    GetClientQueryVariables
  >(GET_CLIENT_QUERY, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    client,
    error,
    refetch,
  };
}

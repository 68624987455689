import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_CLIENT_GROUPS_QUERY } from '@frontend/app/queries';
import { GetClientGroupsQuery } from '@frontend/app/queries/types/GetClientGroupsQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetClientGroupsQuery>;

export function useGetClientGroups(options: IOptions = {}) {
  const {
 loading, data: { groups = [] } = {}, error, refetch,
} = useQuery<GetClientGroupsQuery>(
    GET_CLIENT_GROUPS_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    groups,
    error,
    refetch,
  };
}

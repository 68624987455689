import * as React from 'react';
import cx from 'classnames';

import { Spin } from 'antd';

import styles from './ClientGroupDetailView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ClientGroupDetailView: React.FC<IProps> = React.memo((props) => {
  return (
    <div className={cx(styles.ClientGroupDetailView, props.className)}>
      <Spin size="large" />
    </div>
  );
});

export default ClientGroupDetailView;

import {
 Button, Form, Space, Table, Tag, Tooltip,
} from 'antd';
import React, { useState, useCallback, useMemo, MouseEvent } from 'react';
import { castArray } from 'lodash';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { LoadSpinner } from '@frontend/lib';
import { useFindAllContentFields } from '@frontend/app/hooks/contentFields/useFindAllContentField';
import { useDeleteContentField } from '@frontend/app/hooks/contentFields/useDeleteContentField';
import { ContentFieldEnum, ContentType } from '@frontend/app/types/globalTypes';

import { useCreateContentField } from '@frontend/app/hooks/contentFields/useCreateContentField';
import { useUpdateContentField } from '@frontend/app/hooks/contentFields/useUpdateContentField';
import { useGetPrograms } from '@frontend/app/hooks';
import { GetProgramsQuery_programs } from '@frontend/app/queries/types/GetProgramsQuery';
import ContentFieldDrawer from './ContentFieldDrawer';
import { ContentFieldsFormValue } from './types';

import styles from './ContentFields.scss';

interface IProps {
  clientId: string;
  className?: string;
}

const DEFAULT_VALUE: ContentFieldsFormValue = {
  name: 'New Content Field',
  description: '',
  fieldType: ContentFieldEnum.TEXT,
  selectOptions: [],
  postTypes: [],
  programIds: [],
  isMandatory: true,
  canMarkNa: false,
  createdDate: null,
  updatedDate: null,
  clientId: '',
};

const ContentFields: React.FC<IProps> = React.memo((props) => {
  const { clientId } = props;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<ContentFieldsFormValue>(DEFAULT_VALUE);

  const { loading: deletingContentField, deleteContentField } = useDeleteContentField();

  const { loading: loadingContentFields, contentFields, refetch: refetchContentFields } = useFindAllContentFields({
    variables: {
      data: {
        clientId,
      },
    },
  });

  const handleDelete = useCallback(
    async (e, record) => {
      e.stopPropagation();
      await deleteContentField({
        variables: {
          id: record.id,
        },
      });
      await refetchContentFields();
    },
    [deleteContentField, refetchContentFields],
  );

  const { loading: loadingPrograms, programs }: { loading: boolean, programs: GetProgramsQuery_programs[] } = useGetPrograms({
    variables: {
      clientId,
    },
  });

  const handleDuplicate = useCallback((e, record) => {
    e.stopPropagation();
    const newRecord = {
      ...record,
      id: undefined,
      name: `${record.name} (copy)`,
    };
    delete newRecord.id;
    setFormData(newRecord);
    setVisible(true);
  }, [setFormData, setVisible]);

  const columns = useMemo(() => ([
    {
      title: 'Content Field',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: 'Post Type',
      dataIndex: 'postTypes',
      key: 'postTypes',
      render: (postTypes?: ContentType[] | null | undefined) => {
        if (!postTypes) return null;
        const displayedTypes = postTypes.slice(0, 2);
        const remainingCount = postTypes.length - 2;
        return (
          <>
            {displayedTypes.map((text: string) => (
              <Tag key={text}>
                {text
                  ?.split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ')}
              </Tag>
            ))}
            {remainingCount > 0 && (
            <Tag>
              +
              {remainingCount}
            </Tag>
)}
          </>
        );
      },
    },
    {
      title: 'Project',
      dataIndex: 'programIds',
      key: 'programIds',
      render: (programIds?: number[] | null | undefined) => (
        <>
          {programIds?.map((programId: number) => {
              const program: GetProgramsQuery_programs = programs.find((p) => p.id === programId) as GetProgramsQuery_programs;
              return program ? (
                <Tag color="processing" key={programId}>
                  {program.title}
                </Tag>
              ) : null;
            })}
        </>
        ),
    },
    {
      title: 'Type',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (text: ContentFieldEnum) => (text as string)
          ?.split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' '),
    },
    {
      title: 'Actions',
      key: 'actions',
      // @ts-ignore: Parameter '_' implicitly has an 'any' type
      render: (_, record: ContentFieldsFormValue) => (
        <Space size="middle">
          <Tooltip title="Copy">
            <Button type="link" icon={<CopyOutlined />} onClick={(e) => handleDuplicate(e, record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={async (e: MouseEvent<HTMLButtonElement>) => {
                await handleDelete(e, record);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]), [handleDelete, handleDuplicate, programs]);

  const showDrawer = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const onClose = useCallback(() => {
    setVisible(false);
    form.resetFields();
    setFormData(DEFAULT_VALUE);
  }, [form, setVisible, setFormData]);

  const { loading: creatingContentField, createContentField } = useCreateContentField();
  const { loading: updatingContentField, updateContentField } = useUpdateContentField();

  const onFinish = useCallback(async (values: ContentFieldsFormValue) => {
    if (values.selectOptions) {
      values.selectOptions = values.selectOptions
        .map((option) => option?.trim())
        .filter((option) => option !== '' && option !== null && option !== undefined);
    }
    if (values.postTypes) {
      values.postTypes = values.postTypes.filter((type) => type !== null && type !== undefined);
    }
    if (formData.id) {
      await updateContentField({
        variables: {
          id: formData.id,
          data: {
            ...values,
            clientId,
          },
        },
      });
    } else {
      await createContentField({
        variables: {
          data: {
            ...values,
            clientId,
          },
        },
      });
    }
    await refetchContentFields();
    form.resetFields();
    setFormData(DEFAULT_VALUE);
    onClose();
  }, [createContentField, updateContentField, refetchContentFields, form, formData, clientId, onClose]);

  const onFormChange = useCallback((changedValues: ContentFieldsFormValue) => {
    const selectOptions: string[] = castArray(changedValues.selectOptions || formData.selectOptions || []);
    const postTypes: ContentType[] = castArray(changedValues.postTypes || formData.postTypes || []);
    const programIds = changedValues.programIds || formData.programIds || [];
    const filteredSelectOptions = selectOptions.reduce((acc: string[], option) => {
      if (option === undefined || option === null || option === '') {
        if (acc.length === 0 || acc[acc.length - 1] !== option) {
          acc.push(option);
        }
      } else {
        acc.push(option);
      }
      return acc;
    }, [] as string[]);

    changedValues = {
      ...changedValues,
      selectOptions: filteredSelectOptions,
      postTypes,
      programIds,
    };

    setFormData({
      ...formData,
      ...changedValues,
    });
  }, [formData, setFormData]);

  if (loadingContentFields || deletingContentField || loadingPrograms) return <LoadSpinner />;

  return (
    <div className={styles.contentFieldsContainer}>
      <div className={styles.newContentField}>
        <Button type="primary" onClick={showDrawer}>
          New Field
        </Button>
      </div>
      <ContentFieldDrawer
        formData={formData}
        visible={visible}
        onClose={onClose}
        onFinish={onFinish}
        form={form}
        creatingContentField={creatingContentField}
        updatingContentField={updatingContentField}
        loadingContentFields={loadingContentFields}
        onDelete={handleDelete}
        onFormChange={onFormChange}
        programs={programs}
      />
      <Table
        dataSource={contentFields}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            setVisible(true);
            setFormData(record);
          },
        })}
      />
    </div>
  );
});

export default ContentFields;

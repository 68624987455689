import * as React from 'react';
import createAuthClient from '@auth0/auth0-spa-js';

import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

import { logger } from '@common';

interface IAuthContext {
  loading: boolean;
  isAuthenticated: boolean;
  user: IUser;
  token: string;
  authError: IAuthError;
  targetUrl: string;

  apolloClient: ApolloClient<any>;

  loginWithRedirect(pathBeforeRedirect?: string): Promise<void>;
  logout(): void;
}
interface ServerParseError extends Error {
  response: Response;
  statusCode: number;
  bodyText: string;
};

type TAuthError = 'unauthorized';
export interface IAuthError {
  type: TAuthError;
  message: string;
}

interface IAuthClient {
  loginWithPopup(options: PopupLoginOptions): Promise<void>;
  getUser(options?: GetUserOptions): Promise<any>;
  getIdTokenClaims(options?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(options: RedirectLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getTokenSilently(options?: GetTokenSilentlyOptions): Promise<any>;
  getTokenWithPopup(options?: GetTokenWithPopupOptions): Promise<string>;
  isAuthenticated(): Promise<boolean>;
  logout(options?: LogoutOptions): void;
}

interface IUser {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  family_name?: string;
  given_name?: string;
  locale?: string;
}

const { useState, useEffect, useContext } = React;
const { AUTH_API_AUDIENCE } = process.env;
let AUTH_DOMAIN: string, AUTH_CLIENT_ID: string, REDIRECT_URI: string;
if (typeof window !== 'undefined') {
  // determine auth0 tenant and admin client ID from domain name
  switch (window.location.hostname.split('.').slice(-2)[0]) {
    case 'aspireiq':
      // https://manage.auth0.com/dashboard/us/aspireiq/applications/0XBv6UqIXuQe735zTbNRFMPqIev6vDZ0/
      AUTH_DOMAIN = 'aspireiq.auth0.com';
      AUTH_CLIENT_ID = '0XBv6UqIXuQe735zTbNRFMPqIev6vDZ0';
      break;
    case 'aspireiq-staging':
      // https://manage.auth0.com/dashboard/us/aspireiq-staging/applications/2z2GAwu1irZZPMsPtEQXmvG7YXuMnhlH/
      AUTH_DOMAIN = 'aspireiq-staging.auth0.com';
      AUTH_CLIENT_ID = '2z2GAwu1irZZPMsPtEQXmvG7YXuMnhlH';
      break;
    default:
      // https://manage.auth0.com/dashboard/us/aspireiq-development/applications/91526Yi5xn6P9LFbYzvi6Bzi0MX06W6p/
      AUTH_DOMAIN = 'aspireiq-development.auth0.com';
      AUTH_CLIENT_ID = '91526Yi5xn6P9LFbYzvi6Bzi0MX06W6p';
  };

  REDIRECT_URI = window.location.origin;
}

/**
 * Helper function which converts query string to json.
 */
const queryStringToJSON = (): any => {
  const pairs = location.search.slice(1).split('&');

  const result = {};
  pairs.forEach((pair) => {
    const parts = pair.split('=');
    result[parts[0]] = decodeURIComponent(parts[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
};

export const AuthContext = React.createContext<IAuthContext>(null);
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<IUser>(null);
  const [token, setToken] = useState<string>(null);
  const [authClient, setAuthClient] = useState<IAuthClient>(null);
  const [authError, setAuthError] = useState<IAuthError>(null);
  const [targetUrl, setTargetUrl] = useState<string>(null);

  const [apolloClient, setApolloClient] = useState<ApolloClient<any>>(null);

  useEffect(() => {
    const initApolloClient = (token: string) => {
      const errorLink = onError(({ networkError }) => {
        if ((networkError as ServerParseError)?.statusCode === 401) {
          // reload if token has expired
          window.location.reload();
        }
      });
      const httpLink = createUploadLink({ uri: '/api/graphql' });
      const authLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }));

      const client = new ApolloClient({
        link: ApolloLink.from([authLink.concat(ApolloLink.from([
          errorLink, httpLink,
        ]))]),
        cache: new InMemoryCache({
          addTypename: true,
        }),
      });
      setApolloClient(client);
    };

    const onRedirectCallback = (appState) => {
      if (appState && appState.targetUrl) {
        setTargetUrl(appState.targetUrl);
      }

      window.history.replaceState(
        {},
        document.title,
        window.location.pathname,
      );
    };

    const initAuth = async () => {
      const authClient = await createAuthClient({
        audience: AUTH_API_AUDIENCE,
        domain: AUTH_DOMAIN,
        client_id: AUTH_CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        leeway: 30,
      });

      setAuthClient(authClient);

      const search = queryStringToJSON();
      if (search.error) {
        setAuthError({
          type: search.error,
          message: search.error_description,
        });
      } else if (search.code) {
        const { appState } = await authClient.handleRedirectCallback();

        onRedirectCallback(appState);
      }

      // check if user is authenticated
      // 1. from successful login redirect 2. from cache (refresh page)
      const isAuthenticated = await authClient.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await authClient.getUser();
        const token = await authClient.getTokenSilently();

        logger.debug(user, token);

        setUser(user);
        setToken(token);

        initApolloClient(token);
      }

      setLoading(false);
    };

    initAuth();
  }, []);

  const loginWithRedirect = async (pathBeforeRedirect?: string): Promise<void> => {
    await authClient.loginWithRedirect({
      redirect_uri: REDIRECT_URI,
      prompt: 'select_account',
      appState: { targetUrl: pathBeforeRedirect },
    });
  };

  const logout = () => {
    authClient.logout({
      returnTo: REDIRECT_URI,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        user,
        token,
        authError,
        targetUrl,
        apolloClient,
        loginWithRedirect,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

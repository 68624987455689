import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_USERS_QUERY } from '@frontend/app/queries';
import { GetUsersQuery } from '@frontend/app/queries/types/GetUsersQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetUsersQuery>;

export function useGetUsers(options: IOptions = {}, customErrorMessage?: string) {
  const {
 loading, data: { users = [] } = {}, error, refetch,
} = useQuery<GetUsersQuery>(
    GET_USERS_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(customErrorMessage ?? error.message);
    }
  }, [error, customErrorMessage]);

  return {
    loading,
    users,
    error,
    refetch,
  };
}

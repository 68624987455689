/* Text styles */
._ClientListView_11402_2 ._header_11402_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientListView_11402_2 ._header_11402_2 ._createButton_11402_7 {
  margin-left: auto;
}
._ClientListView_11402_2 ._table_11402_10 ._nameCell_11402_10 {
  cursor: pointer;
  user-select: none;
  color: #3996e0;
}
import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { pullAllBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  DELETE_APPLICATION_MUTATION,
  GET_APPLICATIONS_QUERY,
} from '@frontend/app/queries';
import { GetApplicationsQuery } from '@frontend/app/queries/types/GetApplicationsQuery';
import {
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables,
} from '@frontend/app/queries/types/DeleteApplicationMutation';

const { useEffect } = React;

const updateCache = (store: DataProxy, id: string) => {
  let data: GetApplicationsQuery;
  try {
    data = store.readQuery<GetApplicationsQuery>({ query: GET_APPLICATIONS_QUERY });
  } catch (err) {
    return;
  }

  const applications = [...data.applications];
  pullAllBy(applications, [{ id }], 'id');

  store.writeQuery<GetApplicationsQuery>({
    query: GET_APPLICATIONS_QUERY,
    data: {
      ...data,
      applications,
    },
  });
};

type IOptions = MutationHookOptions<DeleteApplicationMutation, DeleteApplicationMutationVariables>;
export function useDeleteApplicationById(options: IOptions = {}) {
  const [deleteApplicationById, { loading, error }] = useMutation<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >(DELETE_APPLICATION_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully deleted organization');
    },
  });

  // so that we can capture the id during actual delete call
  const _deleteApplicationById: typeof deleteApplicationById = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [store, result] = args;
        if (!result.data.deleted) {
          return;
        }

        const { id } = _options.variables || options.variables;
        updateCache(store, id);
      },
    };

    return deleteApplicationById(...args);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteApplicationById: _deleteApplicationById,
    loading,
  };
}

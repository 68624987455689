import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import {
  Drawer, Form, Input, Button,
} from 'antd';

import {
  IClientGroup,
} from '@frontend/app/hooks';

const { useEffect } = React;
import styles from './EditClientGroupDrawer.scss';

export interface ICreateClientGroupParams {
  name: string;
}
interface IProps {
  open: boolean;
  onRequestClose(): void;

  isSubmitting: boolean;
  onSubmit(client: ICreateClientGroupParams): void;
  clientGroup?: IClientGroup;

  className?: string;
}

interface IEditClientFormProps {
  onSubmit(client: ICreateClientGroupParams): void;
  isSubmitting: boolean;

  clientGroup?: IClientGroup;
}
const EditClientGroupForm: React.FunctionComponent<IEditClientFormProps> = React.memo((props) => {
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  useEffect(() => {
    const { clientGroup } = props;
    if (!isEmpty(clientGroup)) {
      const values = {
        name: clientGroup.name,
      };

      form.setFieldsValue(values);
    }
  }, [form, props.clientGroup]);

  const onFinish = (values) => {
    console.log('Success:', values);

    props.onSubmit(values);
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={styles.form}
      {...formItemLayout}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: `Please input the client group's name!` }]}
      >
        <Input
          placeholder="Client group name"
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isSubmitting}
        className={styles.submitButton}
      >
        Submit
      </Button>
    </Form>
  );
});

/**
 * @type {React.FunctionComponent}
 */
export const EditClientGroupDrawer: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <Drawer
      title={isEmpty(props.clientGroup) ? 'Create Client Group' : 'Edit Client Group'}
      visible={props.open}
      width={600}
      onClose={props.onRequestClose}
      className={cx(styles.EditClientGroupDrawer, props.className)}
    >
      <EditClientGroupForm
        clientGroup={props.open && props.clientGroup}
        onSubmit={props.onSubmit}
        isSubmitting={props.isSubmitting}
      />
    </Drawer>
  );
});

EditClientGroupDrawer.defaultProps = {
  className: null,
};

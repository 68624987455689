import gql from 'graphql-tag';

import { ORGANIZATION_FRAGMENT } from './fragments';

export const GET_ORGANIZATIONS_QUERY = gql`
  query GetOrganizationsQuery {
    organizations: getOrganizations {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const GET_ORGANIZATION_QUERY = gql`
  query GetOrganizationQuery($id: Int!) {
    organization: getOrganizationById(id: $id) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const SAVE_ORGANIZATION_MUTATION = gql`
  mutation SaveOrganizationMutation($data: SaveOrganizationInput!) {
    organization: saveOrganization(data: $data) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const DELETE_ORGANIZATION_MUTATION = gql`
  mutation DeleteOrganizationMutation($id: Int!) {
    deleted: deleteOrganizationById(id: $id)
  }
`;

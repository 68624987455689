import * as React from 'react';

import {
  IOrganization,
} from '@frontend/app/hooks';
import { Descriptions } from 'antd';

interface IProps {
  organization: IOrganization;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const OrganizationDetails: React.FunctionComponent<IProps> = React.memo((props) => {
  const organization = props.organization;
  return (
    <Descriptions title={organization.name} column={1} bordered >
      <Descriptions.Item label="ID">{organization.id}</Descriptions.Item>
      <Descriptions.Item label="Created Date">{organization.createdDate}</Descriptions.Item>
    </Descriptions>
  );
});

export default OrganizationDetails;

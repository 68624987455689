import gql from 'graphql-tag';

import { USER_FRAGMENT, USER_WITH_AUTH0_FRAGMENT, CLIENT_FRAGMENT } from './fragments';

export const GET_USERS_QUERY = gql`
  query GetUsersQuery {
    users: getUsers {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
export const GET_USER_QUERY = gql`
  query GetUserQuery($id: String!) {
    user: getUserById(id: $id) {
      ...UserWithAuth0Fragment
      clients {
        ...ClientFragment
      }
    }
  }
  ${USER_WITH_AUTH0_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;
export const SAVE_USER_MUTATION = gql`
  mutation SaveUserMutation($user: SaveUserInput!) {
    user: saveUser(user: $user) {
      ...UserWithAuth0Fragment
      clients {
        ...ClientFragment
      }
    }
  }
  ${USER_WITH_AUTH0_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;

export const MARK_USER_EMAIL_AS_VERIFIED_MUTATION = gql`
  mutation MarkUserEmailAsVerifiedMutation($userId: String!) {
    markUserEmailAsVerified(userId: $userId)
  }
`;

export const SEND_RESET_PASSWORD_EMAIL_MUTATION = gql`
  mutation SendResetPasswordEmailMutation($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;

export const SEND_VERIFICATION_EMAIL_MUTATION = gql`
  mutation SendVerificationEmailMutation($userId: String!) {
    sendVerificationEmail(userId: $userId)
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUserMutation($id: String!) {
    deleted: deleteUserById(id: $id)
  }
`;

export const USER_QA_LOGIN_MUTATION = gql`
  mutation UserQALoginMutation($clientId: String!) {
    redirectUrl: userQALogin(clientId: $clientId)
  }
`;

/* Text styles */
._OrganizationDetailView_e7o4j_2 ._header_e7o4j_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._OrganizationDetailView_e7o4j_2 ._header_e7o4j_2 ._action_e7o4j_7 {
  margin-left: auto;
}
._OrganizationDetailView_e7o4j_2 ._children_e7o4j_10 ._menu_e7o4j_10 {
  margin-bottom: 20px;
}
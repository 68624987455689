import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { SAVE_EMBEDDED_REPORTING_ITEM } from '@frontend/app/queries';
import { SaveEmbeddedReportingItem, SaveEmbeddedReportingItemVariables } from '@frontend/app/queries/types/SaveEmbeddedReportingItem';

type IOptions = MutationHookOptions<SaveEmbeddedReportingItem, SaveEmbeddedReportingItemVariables>;

export function useSaveEmbeddedReportingItem(options: IOptions = {}) {
  return useMutation<SaveEmbeddedReportingItem, SaveEmbeddedReportingItemVariables>(SAVE_EMBEDDED_REPORTING_ITEM, options);
}

import * as React from 'react';
import { Button, Form, Input, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useUpdateProgramLandingPageHTML } from '@frontend/app/hooks';

import { useProgramOnboardingContext } from './context/ProgramOnboardingContext';

const { TextArea } = Input;

const { useState } = React;

import styles from './ProgramOnboardingHTML.scss';

export const ProgramOnboardingHTML = () => {
  const { programId } = useProgramOnboardingContext();

  const [rawHTML, setRawHTML] = useState<string>('');

  const [updateHTML, {
    loading: updatingHTML,
  }] = useUpdateProgramLandingPageHTML({
    onCompleted() {
      message.success('Successfully published landing page!');
    },
    onError(error) {
      message.error(error.message);
    },
  });

  const handlePublish = () => {
    if (confirm('Are you sure? This will go live immediately.')) {
      updateHTML({
        variables: {
          id: programId,
          rawHTML,
        },
      });
    }
  };

  return (
    <Form className={styles.ProgramOnboardingHTML}>
      <Form.Item label="HTML" name="html">
        <TextArea placeholder="Paste the HTML for the onboarding page" autoSize={true} onChange={(e) => setRawHTML(e.target.value)} />
      </Form.Item>
      <Button
        disabled={updatingHTML}
        icon={updatingHTML && <LoadingOutlined />}
        type="default"
        onClick={handlePublish}
      >
        Publish
      </Button>
    </Form>
  );
};

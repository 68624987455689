import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import {
  SAVE_CLIENT_FEATURE_MUTATION,
} from '@frontend/app/queries';
import {
  SaveClientFeatureMutation,
  SaveClientFeatureMutationVariables,
} from '@frontend/app/queries/types/SaveClientFeatureMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<SaveClientFeatureMutation, SaveClientFeatureMutationVariables>;

export function useSaveClientFeature(options: IOptions = {}) {
  const [saveClientFeature, { loading, error }] = useMutation<
    SaveClientFeatureMutation,
    SaveClientFeatureMutationVariables
  >(SAVE_CLIENT_FEATURE_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully saved client feature');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    saveClientFeature,
    loading,
    error,
  };
}

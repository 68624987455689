import 'css-chunk:src/app/containers/Home/Home.scss';export default {
  "Home": "_Home_z7wao_2",
  "header": "_header_z7wao_2",
  "headerContent": "_headerContent_z7wao_7",
  "logo": "_logo_z7wao_15",
  "userMenu": "_userMenu_z7wao_23",
  "content": "_content_z7wao_26",
  "nav": "_nav_z7wao_36",
  "menu": "_menu_z7wao_46",
  "link": "_link_z7wao_49",
  "icon": "_icon_z7wao_56",
  "children": "_children_z7wao_59",
  "breadcrumb": "_breadcrumb_z7wao_63"
};
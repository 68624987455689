import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { pullAllBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import { DELETE_CLIENT_MUTATION, GET_CLIENTS_QUERY } from '@frontend/app/queries';
import { GetClientsQuery } from '@frontend/app/queries/types/GetClientsQuery';
import {
  DeleteClientMutation,
  DeleteClientMutationVariables,
} from '@frontend/app/queries/types/DeleteClientMutation';

const { useEffect } = React;

const updateCache = (store: DataProxy, clientId: string) => {
  let data: GetClientsQuery;
  try {
    data = store.readQuery<GetClientsQuery>({ query: GET_CLIENTS_QUERY });
  } catch (err) {
    return;
  }

  const clients = [...data.clients];
  pullAllBy(clients, [{ id: clientId }], 'id');

  store.writeQuery<GetClientsQuery>({
    query: GET_CLIENTS_QUERY,
    data: {
      ...data,
      clients,
    },
  });
};

type IOptions = MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>;
export function useDeleteClientById(options: IOptions = {}) {
  const [deleteClientById, { loading, error }] = useMutation<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >(DELETE_CLIENT_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully deleted client');
    },
  });

  // so that we can capture the id during actual delete call
  const _deleteClientById: typeof deleteClientById = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [store, result] = args;
        if (!result.data.deleted) {
          return;
        }

        const { id } = _options.variables || options.variables;
        updateCache(store, id);
      },
    };

    return deleteClientById(...args);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteClientById: _deleteClientById,
    loading,
  };
}

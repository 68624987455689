import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import {
  UnArchiveProgramMutation,
  UnArchiveProgramMutationVariables,
} from '@frontend/app/queries/types/UnArchiveProgramMutation';
import { UNARCHIVE_PROGRAM_MUTATION } from '@frontend/app/queries';

type TOptions = MutationHookOptions<UnArchiveProgramMutation, UnArchiveProgramMutationVariables>;
export const useUnArchiveProgramById = (options: TOptions = {}) => (
  useMutation<UnArchiveProgramMutation, UnArchiveProgramMutationVariables>(UNARCHIVE_PROGRAM_MUTATION, options)
);

import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { first, map, capitalize } from 'lodash';

import { Table, Typography, Select, Alert } from 'antd';

import {
  IUser,
  IClient,
  useUpdateRoleForClient,
} from '@frontend/app/hooks';

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;
// hard-code manager roles for now
const MANAGER_ROLES: Array<{
  name: string;
  description: string;
  disabled: boolean;
}> = [{
  name: 'manager:default',
  description: 'Default',
  disabled: false,
}, {
  name: 'manager:admin',
  description: 'Admin',
  disabled: false,
}, {
  name: 'manager:content_approver',
  description: 'Content Approver',
  disabled: true,
}, {
  name: 'manager:content_viewer',
  description: 'Content Viewer',
  disabled: true,
}];
import styles from './UserClients.scss';

interface IProps {
  user: IUser;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const UserClients: React.FunctionComponent<IProps> = React.memo((props) => {
  const { user } = props;
  const history = useHistory();
  // const { loading, roles } = useGetManagerRoles();
  const { updateRoleForClient, loading: updating } = useUpdateRoleForClient();

  const goToClientDetail = (clientId) => {
    history.push(`/clients/${clientId}`);
  };
  const handleRoleChange = (brandId: string, role: string) => {
    updateRoleForClient({
      variables: {
        id: user.id,
        clientId: brandId,
        role,
      },
    });
  };

  if (!user.auth0User) {
    return (
      <Alert
        type="error"
        message={`User ${user.id} has been deleted on Auth0.`}
      />
    );
  }

  return (
    <div className={cx(styles.UserClients, props.className)}>
      <Text className={styles.text}>Clients this user has access to.</Text>
      <Table
        rowKey={(record: IClient) => record.id}
        dataSource={user.clients}
        className={styles.table}
        loading={updating}
      >
        <Column title="Name" key="name" render={(_, client: IClient) => (
          <span
            className={styles.nameCell}
            onClick={goToClientDetail.bind(this, client.id)}
          >
            {client.name}
          </span>
        )} />
        <Column title="Id" dataIndex="id" />
        <Column
          title="Role"
          key="role"
          render={(_, client: IClient) => {
            const clientsData = user.auth0User?.appMetadata?.clients || {};
            const currentRole = first(clientsData[client.id]?.roles) as string;

            return (
              <Select
                value={currentRole}
                onChange={(role) => handleRoleChange(client.id, role)}
              >
                {map(MANAGER_ROLES, ({ name, description, disabled }) => (
                  <Option key={name} value={name} disabled={disabled}>
                    {capitalize(description)}
                  </Option>
                ))}
              </Select>
            );
          }}
        />
      </Table>
    </div>
  );
});

export default UserClients;

import gql from 'graphql-tag';

import { CLIENT_GROUP_FRAGMENT, CLIENT_FRAGMENT } from './fragments';

export const GET_CLIENT_GROUPS_QUERY = gql`
  query GetClientGroupsQuery {
    groups: getClientGroups {
      ...ClientGroupFragment
    }
  }
  ${CLIENT_GROUP_FRAGMENT}
`;

export const GET_CLIENT_GROUPS_FOR_ORG_QUERY = gql`
  query GetClientGroupsForOrgQuery($id: Int!) {
    groups: getClientGroupsForOrganization(id: $id) {
      ...ClientGroupFragment
      clients {
        ...ClientFragment
      }
    }
  }
  ${CLIENT_GROUP_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;

export const GET_CLIENT_GROUP_QUERY = gql`
  query GetClientGroupQuery($id: Int!) {
    group: getClientGroupById(id: $id) {
      ...ClientGroupFragment
      clients {
        ...ClientFragment
      }
    }
  }
  ${CLIENT_GROUP_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;

export const SAVE_CLIENT_GROUP_MUTATION = gql`
  mutation SaveClientGroupMutation($data: SaveClientGroupInput!) {
    group: saveClientGroup(data: $data) {
      ...ClientGroupFragment
      clients {
        ...ClientFragment
      }
    }
  }
  ${CLIENT_GROUP_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;

export const DELETE_CLIENT_GROUP_MUTATION = gql`
  mutation DeleteClientGroupMutation($groupId: Int!) {
    deleted: deleteClientGroupById(id: $groupId)
  }
`;

import * as React from 'react';
import { Button, Form, Input, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LoadSpinner } from '@frontend/lib';
import { useUpdateProgram, useGetProgramById } from '@frontend/app/hooks';

import { useProgramOnboardingContext } from './context/ProgramOnboardingContext';

const { TextArea } = Input;

import styles from './ProgramInviteTemplate.scss';

export const ProgramInviteTemplate = () => {
  const { programId } = useProgramOnboardingContext();

  const {
    data: {
      program = null,
    } = {},
  } = useGetProgramById({
    variables: {
      id: programId,
    },
    skip: !programId,
  });

  const [updateProperties, {
    loading: updatingProperties,
  }] = useUpdateProgram({
    onCompleted() {
      message.success('Successfully saved program');
    },
    onError(error) {
      message.error(error.message);
    },
  });

  const validateText = (field: string, text: string) => {
    if (!text) {
      return;
    }
    const variableWrapperRegex = /\$\{[^\}]*\}/g;
    const variableVerifyRegex = /^\$\{(baseUrl|landingPageUrl|((client|member|program|user)\.\w+))\}$/g;
    const variables = text.match(variableWrapperRegex) || new Array();
    for(const variable of variables) {
      if (!variable.match(variableVerifyRegex)) {
        throw new Error(`Typo in ${field} for variable ${variable}.`);
      }
    }
  };

  const handleSave = () => {
    try {
      validateText('Invite email subject', program.inviteEmailSubject);
      validateText('Invite email html', program.inviteEmailText);
    }
    catch (err) {
      message.error(err.message);
      return;
    }
    updateProperties({
      variables: {
        id: programId,
        properties: {
          inviteEmailSubject: program.inviteEmailSubject,
          inviteEmailText: program.inviteEmailText
        },
      },
    });
  };

  const onChange = (values) => {
    program.inviteEmailSubject = values.inviteEmailSubject || null;
    program.inviteEmailText = values.inviteEmailText || null;
  }

  if (!program) {
    return <LoadSpinner />;
  }

  return (
    <Form
      initialValues={program}
      onValuesChange={(_, allValues) => {
        onChange(allValues);
      }}
      className={styles.ProgramInviteTemplate}
    >
      <Form.Item label="Invite email subject" name="inviteEmailSubject">
        <Input placeholder="Invite email subject" />
      </Form.Item>
      <Form.Item label="Invite email html" name="inviteEmailText">
        <TextArea placeholder="Invite email html" autoSize={true} />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          icon={updatingProperties && <LoadingOutlined />}
          onClick={handleSave}
        >
          Save
        </Button>
      </Form.Item>

      <blockquote>
        Important note regarding landing page url. <br />
        If you decide to change a link, don't forget to add ?member={'${member.id}'} portion to this link in order to keep track of invited member.
      </blockquote>

      <legend>
        Available placeholders:
        <ul>
          <li>{'${member.name}'}, {'${member.email}'}</li>
          <li>{'${client.name}'}, {'${client.id}'}, {'${client.hostname}'}</li>
          <li>{'${user.name}'}</li>
          <li>{'${program.title}'}, {'${program.customLandingPagePath}'}</li>
          <li>{'${baseUrl}'} - stores https://community.aspireiq.com</li>
          <li>{"<a href='${landingPageUrl}'>review the program details here</a>"} - example of program landing page</li>
        </ul>
      </legend>
    </Form>
  );
};

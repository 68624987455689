import * as React from 'react';
import cx from 'classnames';

import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy } = React;
import styles from './ClientGroupsView.scss';
const ClientGroupListView = lazy(() => import('./ClientGroupListView'));
const ClientGroupDetailView = lazy(() => import('./ClientGroupDetailView'));

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientGroupsView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={cx(styles.ClientGroupsView, props.className)}>
      <Switch>
        <Route path={`${match.path}`} exact render={lazyLoadComponent(ClientGroupListView)} />
        <Route path={`${match.path}/:groupId`} render={lazyLoadComponent(ClientGroupDetailView)} />
      </Switch>
    </div>
  );
});

export default ClientGroupsView;

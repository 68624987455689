import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import {
  UPDATE_ALLOY_INTEGRATIONS_MUTATION,
} from '@frontend/app/queries';
import {
  UpdateAlloyIntegrationsMutation,
  UpdateAlloyIntegrationsMutationVariables,
} from '@frontend/app/queries/types/UpdateAlloyIntegrationsMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<UpdateAlloyIntegrationsMutation, UpdateAlloyIntegrationsMutationVariables>;

export function useUpdateAlloyIntegrations(options: IOptions = {}) {
  const [updateAlloyIntegrations, { loading, error }] = useMutation<
    UpdateAlloyIntegrationsMutation,
    UpdateAlloyIntegrationsMutationVariables
  >(UPDATE_ALLOY_INTEGRATIONS_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully updated Alloy integrations for client.');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    updateAlloyIntegrations,
    loading,
    error,
  };
}

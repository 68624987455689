._SearchDropdown_1nlf8_1 {
  padding: 8px;
}
._SearchDropdown_1nlf8_1 ._searchInput_1nlf8_4 {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}
._SearchDropdown_1nlf8_1 ._searchSubmitButton_1nlf8_9 {
  width: 90px;
  margin-right: 8px;
}
._SearchDropdown_1nlf8_1 ._searchResetButton_1nlf8_13 {
  width: 90px;
}
._SearchDropdown_1nlf8_1 ._selectedFilterIcon_1nlf8_16 {
  color: #3996e0;
}
._SearchDropdown_1nlf8_1 ._unselectedFilterIcon_1nlf8_19 {
  color: #e9e8ea;
}
import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_CLIENT_GROUP_QUERY } from '@frontend/app/queries';
import {
  GetClientGroupQuery,
  GetClientGroupQueryVariables,
  GetClientGroupQuery_group,
} from '@frontend/app/queries/types/GetClientGroupQuery';

const { useEffect } = React;
export type IClientGroup = GetClientGroupQuery_group;
type IOptions = QueryHookOptions<GetClientGroupQuery, GetClientGroupQueryVariables>;

export function useGetClientGroupById(options: IOptions = {}) {
  const {
 loading, data: { group = null } = {}, error, refetch,
} = useQuery<
    GetClientGroupQuery,
    GetClientGroupQueryVariables
  >(GET_CLIENT_GROUP_QUERY, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    group,
    error,
    refetch,
  };
}

._contentFieldsDrawer_867p5_1 .ant-input,
._contentFieldsDrawer_867p5_1 .ant-select-selector,
._contentFieldsDrawer_867p5_1 .ant-input-affix-wrapper {
  border-radius: 6px !important;
}
._contentFieldsDrawer_867p5_1 .ant-form-item-row {
  display: flex;
  flex-direction: column;
}
._contentFieldsDrawer_867p5_1 .ant-select-dropdown {
  z-index: auto;
}
._contentFieldsDrawer_867p5_1 ._optionContainer_867p5_13 {
  margin-bottom: 8px;
}

._footer_867p5_17 {
  display: flex;
  justify-content: space-between;
}
import * as React from 'react';
import cx from 'classnames';

import { Button, Table, Tooltip, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
const { Column } = Table;
const { Text } = Typography;
const { useState } = React;

import {
  IClient,
  IProgram,
} from '@frontend/app/hooks';
import { Link } from 'react-router-dom';
import ClientBriefDownloadDrawer from './ClientBriefDownloadDrawer';

import styles from './ClientPrograms.scss';

interface IProps {
  client: IClient;
  downloadBriefsForProgram(programId: number, startDate: string, endDate: string): void;
  exportingBriefs: boolean;
  downloadBriefsUrl: string | null;
  className?: string;
}

/**
 * @type {React.FC}
 */
const ClientPrograms: React.FC<IProps> = React.memo((props) => {
  const { client, downloadBriefsForProgram, exportingBriefs, downloadBriefsUrl } = props;
  const [selectedProgram, setSelectedProgram] = useState<IProgram | null>(null);

  return (
    <div className={cx(styles.ClientPrograms, props.className)}>
      <Text className={styles.text}>Programs under this client.</Text>
      <Table rowKey={(program: IProgram) => program.id} dataSource={client.programs}>
        <Column
          title="Id"
          dataIndex="id"
          render={(_, program: IProgram) => {
            return <Link to={`/programs/${program.id}`}>{program.id}</Link>;
          }}
        />
        <Column title="Id" dataIndex="id" render={(_, program: IProgram) => {
          return (
            <Link to={`/programs/${program.id}`}>{program.id}</Link>
          );
        }} />
        <Column title="Title" dataIndex="title" />
        <Column title="Custom Landing Page Path" dataIndex="customLandingPagePath" />
        <Column title="Owner" dataIndex="owner" />
        <Column
          title="Actions"
          dataIndex="actions"
          render={(_, program: IProgram) => {
            return (
              <Tooltip title="Download program Brief's">
                <Button icon={<DownloadOutlined />} onClick={() => setSelectedProgram(program)} />
              </Tooltip>
            );
          }}
        />
      </Table>
      <ClientBriefDownloadDrawer
        onClose={() => {
          setSelectedProgram(null);
        }}
        downloadBriefsForProgram={downloadBriefsForProgram}
        exportingBriefs={exportingBriefs}
        program={selectedProgram}
        downloadBriefsUrl={downloadBriefsUrl}
        open={selectedProgram !== null}
      />
    </div>
  );
});

export default ClientPrograms;

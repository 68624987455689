import { useEffect } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import {
    GetAuthorizedAgent, GetAuthorizedAgentVariables,
  } from '@frontend/app/queries/types/GetAuthorizedAgent';
import { message } from 'antd';
import { GET_AUTHORIZED_AGENT } from '../../queries/getClientMetaDataQuery';

type IOptions = QueryHookOptions<GetAuthorizedAgent, GetAuthorizedAgentVariables>;
export const useGetAuthorizedAgent = (clientId: string, options: IOptions = {}, customErrorMessage?: string) => {
    const {
        loading, data: { getClientMetadataWithAuthorizedAgent: { authorizedAgent = undefined } = {} } = {}, error, refetch,
       } = useQuery<GetAuthorizedAgent, GetAuthorizedAgentVariables>(GET_AUTHORIZED_AGENT, {
        variables: {
            clientId,
        },
        ...options,
    });

    useEffect(() => {
        if (error) {
          message.error(customErrorMessage ?? error.message);
        }
      }, [error, customErrorMessage]);

    return {
        loading,
        authorizedAgent,
        error,
        refetch,
    };
};

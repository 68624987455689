import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_MEMBER_TAGS_QUERY } from '@frontend/app/queries';
import { GetMemberTagsQuery } from '@frontend/app/queries/types/GetMemberTagsQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetMemberTagsQuery>;

export function useGetMemberTags(options: IOptions = {}) {
  const {
 loading, data: { tags = [] } = {}, error, refetch,
} = useQuery<GetMemberTagsQuery>(
    GET_MEMBER_TAGS_QUERY,
    {
      ...options,
      notifyOnNetworkStatusChange: false,
    },
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    tags,
    error,
    refetch,
  };
}

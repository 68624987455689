const appConfig = {
  app: {
    head: {
      title: 'AspireX Admin',
      titleTemplate: '%s',
      meta: [
        { charset: 'utf-8' },
        { 'http-equiv': 'x-ua-compatible', 'content': 'ie=edge' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'AspireX Admin' },
      ],
    },
  },
};

export {
  appConfig,
};

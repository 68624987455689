import gql from 'graphql-tag';

export const GET_ELIGIBLE_MEMBER_FIELD_FOR_APPLICANT_REVIEW = gql`
  query GetEligibleMemberFieldForApplicantReview($clientId: String!) {
    MemberFieldForApplicantReview:eligibleMemberFieldsForApplicantReview(clientId: $clientId) {
        id
        name
        choices
        type
    }
  }
`;

import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import {
  UPDATE_PROGRAM_CAMPAIGN_MUTATION,
} from '@frontend/app/queries';
import {
  UpdateProgramCampaignMutation,
  UpdateProgramCampaignMutationVariables,
} from '@frontend/app/queries/types/UpdateProgramCampaignMutation';

type IOptions = MutationHookOptions<UpdateProgramCampaignMutation, UpdateProgramCampaignMutationVariables>;

export function useUpdateProgramCampaign(options: IOptions = {}) {
  const [updateProgramCampaign, { loading, error }] = useMutation<
    UpdateProgramCampaignMutation,
    UpdateProgramCampaignMutationVariables
  >(UPDATE_PROGRAM_CAMPAIGN_MUTATION, {
    ...options,
  });

  return {
    updateProgramCampaign,
    loading,
    error,
  };
}

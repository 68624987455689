import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { pullAllBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  UNINSTALL_APPLICATION_MUTATION,
  GET_APPLICATION_INSTANCES_QUERY,
} from '@frontend/app/queries';
import { GetApplicationInstancesQuery } from '@frontend/app/queries/types/GetApplicationInstancesQuery';
import {
  UninstallApplicationMutation,
  UninstallApplicationMutationVariables,
} from '@frontend/app/queries/types/UninstallApplicationMutation';

const { useEffect } = React;

const updateCache = (store: DataProxy, id: number) => {
  let data: GetApplicationInstancesQuery;
  try {
    data = store.readQuery<GetApplicationInstancesQuery>({ query: GET_APPLICATION_INSTANCES_QUERY });
  } catch (err) {
    return;
  }

  const instances = [...data.instances];
  pullAllBy(instances, [{ id }], 'id');

  store.writeQuery<GetApplicationInstancesQuery>({
    query: GET_APPLICATION_INSTANCES_QUERY,
    data: {
      ...data,
      instances,
    },
  });
};

type IOptions = MutationHookOptions<UninstallApplicationMutation, UninstallApplicationMutationVariables>;
export function useUninstallApplication(options: IOptions = {}) {
  const [uninstallApplication, { loading, error }] = useMutation<
    UninstallApplicationMutation,
    UninstallApplicationMutationVariables
  >(UNINSTALL_APPLICATION_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully uninstalled application');
    },
  });

  // so that we can capture the id during actual delete call
  const _uninstallApplication: typeof uninstallApplication = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [store, result] = args;
        if (!result.data.uninstalled) {
          return;
        }

        const { instanceId } = _options.variables || options.variables;
        updateCache(store, instanceId);
      },
    };

    return uninstallApplication(...args);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    uninstallApplication: _uninstallApplication,
    loading,
  };
}

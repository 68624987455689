import gql from 'graphql-tag';

import { COMMUNITY_FRAGMENT, PROGRAM_FRAGMENT } from './fragments';

export const GET_COMMUNITIES_QUERY = gql`
  query GetCommunitiesQuery($admin: Boolean) {
    communities: getCommunities(admin: $admin) {
      ...CommunityFragment
      programs {
        ...ProgramFragment
      }
    }
  }
  ${COMMUNITY_FRAGMENT}
  ${PROGRAM_FRAGMENT}
`;

export const GET_COMMUNITY_QUERY = gql`
  query GetCommunityQuery($id: Int!) {
    community: getCommunityById(id: $id) {
      ...CommunityFragment
      programs {
        ...ProgramFragment
      }
    }
  }
  ${COMMUNITY_FRAGMENT}
  ${PROGRAM_FRAGMENT}
`;

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_COMMUNITY_QUERY } from '@frontend/app/queries';
import {
  GetCommunityQuery,
  GetCommunityQueryVariables,
  GetCommunityQuery_community,
} from '@frontend/app/queries/types/GetCommunityQuery';

const { useEffect } = React;
export type ICommunity = GetCommunityQuery_community;
type IOptions = QueryHookOptions<GetCommunityQuery, GetCommunityQueryVariables>;

export function useGetCommunityById(options: IOptions = {}) {
  const {
 loading, data: { community = null } = {}, error, refetch,
} = useQuery<
    GetCommunityQuery,
    GetCommunityQueryVariables
  >(GET_COMMUNITY_QUERY, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    community,
    error,
    refetch,
  };
}

import * as React from 'react';
import cx from 'classnames';

import {
  IApplication,
} from '@frontend/app/hooks';

import styles from './ApplicationDetails.scss';

interface IProps {
  application: IApplication;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ApplicationDetails: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <div className={cx(styles.ApplicationDetails, props.className)}>
      <pre>
        {JSON.stringify(props.application, undefined, 2)}
      </pre>
    </div>
  );
});

export default ApplicationDetails;

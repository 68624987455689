import * as React from 'react';
import cx from 'classnames';

import { IClient } from '@frontend/app/hooks';

import styles from './ClientDetails.scss';

interface IProps {
  client: IClient;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientDetails: React.FunctionComponent<IProps> = React.memo((props) => (
  <div className={cx(styles.ClientDetails, props.className)}>
    <pre>
      {JSON.stringify(props.client, undefined, 2)}
    </pre>
  </div>
  ));

export default ClientDetails;

import * as React from 'react';
import { Button, Form, Input, message } from 'antd';
import { PlusOutlined, MinusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { LoadSpinner } from '@frontend/lib';
import { useUpdateProgramLandingPageProperties, useUpdateProgramLandingPageHTML } from '@frontend/app/hooks';

import { useProgramOnboardingContext } from './context/ProgramOnboardingContext';

const { TextArea } = Input;

import styles from './ProgramOnboardingPageConfig.scss';

export const ProgramOnboardingPageConfig = () => {
  const { properties, onChangeProperties, programId } = useProgramOnboardingContext();

  const [updateProperties, {
    loading: updatingProperties,
  }] = useUpdateProgramLandingPageProperties({
    onCompleted() {
      message.success('Successfully saved form properties');
    },
    onError(error) {
      message.error(error.message);
    },
  });

  const [updateHTML, {
    loading: updatingHTML,
  }] = useUpdateProgramLandingPageHTML({
    onCompleted() {
      message.success('Successfully published landing page!');
    },
    onError(error) {
      message.error(error.message);
    },
  });

  const handleSave = () => {
    updateProperties({
      variables: {
        id: programId,
        properties,
      },
    });
  };

  const handlePublish = () => {
    if (confirm('Are you sure? This will go live immediately.')) {
      updateHTML({
        variables: {
          id: programId,
          properties,
        },
      });
    }
  };

  if (!properties) {
    return <LoadSpinner />;
  }

  return (
    <Form
      initialValues={properties}
      onValuesChange={(_, allValues) => {
        onChangeProperties(allValues);
      }}
      className={styles.ProgramOnboardingPageConfig}
    >
      <Form.Item label="Primary color" name="primaryColor">
        <Input placeholder="Primary color" />
      </Form.Item>
      <Form.Item label="Accent color" name="accentColor">
        <Input placeholder="Accent color" />
      </Form.Item>
      <Form.Item label="Favicon" name="favicon">
        <Input placeholder="Favicon url" />
      </Form.Item>
      <Form.Item label="Logo Image" name="logoUrl">
        <Input placeholder="Logo url" />
      </Form.Item>
      <Form.Item label="Brand Image 1" name="brandImage1">
        <Input placeholder="Brand image url" />
      </Form.Item>
      <Form.Item label="Tagline" name="tagLine">
        <Input placeholder="Tagline" />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <TextArea placeholder="Description" autoSize={true} />
      </Form.Item>

      <Form.Item label="Brand Image 2" name="brandImage2">
        <Input placeholder="Brand image url" />
      </Form.Item>
      <Form.Item label="Brand Image 3" name="brandImage3">
        <Input placeholder="Brand image url" />
      </Form.Item>
      <Form.Item label="Brand Image 4" name="brandImage4">
        <Input placeholder="Brand image url" />
      </Form.Item>

      <Form.Item label="Perks">
        <Form.List name="perks">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    label={`#${index + 1}`}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'content']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input perk or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Perk name" style={{ width: '60%' }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: '150px' }}
                  >
                    <PlusOutlined /> Add field
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form.Item>

      <Form.Item label="Who You Are">
        <Form.List name="whoYouAre">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    label={`#${index + 1}`}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'content']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input \"who you are\" or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <TextArea placeholder="Who you are" style={{ width: '60%' }} autoSize={true} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: '150px' }}
                  >
                    <PlusOutlined /> Add field
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form.Item>

      <Form.Item label="Brand Image 5" name="brandImage5">
        <Input placeholder="Brand image url" />
      </Form.Item>

      <Form.Item label="Join Text" name="joinText">
        <TextArea placeholder="Description" autoSize={true} />
      </Form.Item>

      <Form.Item label="Copyright" name="copyright">
        <Input placeholder="Copyright info" />
      </Form.Item>

      <Form.Item label="Email address" name="email">
        <Input placeholder="Email address" />
      </Form.Item>

      <Form.Item>
        <Button
          disabled={updatingProperties || updatingHTML}
          type="primary"
          htmlType="submit"
          icon={updatingProperties && <LoadingOutlined />}
          className={styles.save}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          disabled={updatingProperties || updatingHTML}
          icon={updatingHTML && <LoadingOutlined />}
          type="default"
          onClick={handlePublish}
        >
          Publish
        </Button>
      </Form.Item>
    </Form>
  );
};

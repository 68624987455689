import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  SAVE_CLIENT_MUTATION,
  GET_CLIENTS_QUERY,
} from '@frontend/app/queries';
import { GetClientsQuery } from '@frontend/app/queries/types/GetClientsQuery';
import {
  SaveClientMutation,
  SaveClientMutationVariables,
} from '@frontend/app/queries/types/SaveClientMutation';
import { IClient } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, client: IClient) => {
  let data: GetClientsQuery;
  try {
    data = store.readQuery<GetClientsQuery>({ query: GET_CLIENTS_QUERY });
  } catch (err) {
    console.log(`cache missing: ${GET_CLIENTS_QUERY}`);
    return;
  }

  // Write our data back to the cache.
  store.writeQuery<GetClientsQuery>({
    query: GET_CLIENTS_QUERY,
    data: {
      ...data,
      clients: unionBy(data.clients, [client], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<SaveClientMutation, SaveClientMutationVariables>;

export function useSaveClient(options: IOptions = {}) {
  const [saveClient, { loading, error }] = useMutation<
    SaveClientMutation,
    SaveClientMutationVariables
  >(SAVE_CLIENT_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data.client);
    },
    onCompleted() {
      message.info('Successfully saved client');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    saveClient,
    loading,
    error,
  };
}

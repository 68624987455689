import * as React from 'react';
import cx from 'classnames';

import {
  Drawer, Form, Input,
  Button,
} from 'antd';

import styles from './EditOrgDrawer.scss';

export interface ICreateOrgParams {
  name: string;
}
interface IProps {
  open: boolean;
  onRequstClose(): void;

  isSubmitting: boolean;
  onSubmit(organization: ICreateOrgParams): void;

  className?: string;
}

interface IEditOrgFormProps {
  onSubmit(organization: ICreateOrgParams): void;
  isSubmitting: boolean;
}
const EditOrgForm: React.FunctionComponent<IEditOrgFormProps> = React.memo((props) => {
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const onFinish = (values) => {
    console.log('Success:', values);

    props.onSubmit(values);
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={styles.form}
      {...formItemLayout}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: `Please enter the Organization's name!` }]}
      >
        <Input placeholder="Organization name" />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isSubmitting}
        className={styles.submitButton}
      >
        Submit
      </Button>
    </Form>
  );
});

/**
 * @type {React.FunctionComponent}
 */
export const EditOrgDrawer: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <Drawer
      title="Create Organization"
      visible={props.open}
      width={600}
      onClose={props.onRequstClose}
      className={cx(styles.EditOrgDrawer, props.className)}
    >
      <EditOrgForm
        onSubmit={props.onSubmit}
        isSubmitting={props.isSubmitting}
      />
    </Drawer>
  );
});

EditOrgDrawer.defaultProps = {
  className: null,
};

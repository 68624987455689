import * as React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';
const { Title } = Typography;

import styles from './RolesView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const RolesView: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <div className={cx(styles.RolesView, props.className)}>
      <div>
        <Title>Roles</Title>
      </div>
    </div>
  );
});

export default RolesView;

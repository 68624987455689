import * as React from 'react';
import cx from 'classnames';

import { RouteComponentProps } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import {
  Spin, Table, Upload, Button, message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { logger } from '@common';
import { GET_MEMBERS_QUERY, UPLOAD_MEMBERS_CSV } from '@frontend/app/queries/members';
import { GetMembersQuery, GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';
import { UploadMembersCSV, UploadMembersCSVVariables } from '@frontend/app/queries/types/UploadMembersCSV';

import styles from './ClientMembers.scss';

const { Column } = Table;

const { useEffect } = React;

interface IMatchParams {
  clientId: string;
}
interface IProps extends RouteComponentProps<IMatchParams> {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientMembers: React.FunctionComponent<IProps> = React.memo((props) => {
  const { clientId } = props.match.params;

  const context = {
    headers: {
      'requested-client-id': clientId,
    },
  };

  const [getMembers, {
    loading,
    data,
    error,
  }] = useLazyQuery<GetMembersQuery>(GET_MEMBERS_QUERY, { context, fetchPolicy: 'no-cache' });

  const [uploadMemberCSV] = useMutation<UploadMembersCSV, UploadMembersCSVVariables>(UPLOAD_MEMBERS_CSV, {
    onCompleted: (data) => {
      if (data) {
        logger.debug('Successfully uploaded CSV!');
        getMembers();
      }
    },

    onError(error) {
      message.error(error.message);
      logger.error(error);
    },
  });

  useEffect(() => {
    if (clientId) {
      getMembers();
    }
  }, [clientId]);

  const uploadProps = {
    beforeUpload: (file) => {
      const fileSizeLimit = file.size / 1024 / 1024 < 100;
      if (!fileSizeLimit) {
        const errorMessage = 'File cannot exceed 100MB';
        message.error(errorMessage);
        logger.error(errorMessage);
        return false;
      }

      // Check file type
      const regex = /^.+(\.csv)$/g;
      const isCSV = file.type === 'text/csv' || regex.test(file.name);

      if (!isCSV) {
        const errorMessage = 'File format must be .csv';
        message.error(errorMessage);
        logger.error(errorMessage);
        return false;
      }

      uploadMemberCSV({
        variables: { file },
        context,
      });

      return false;
    },
  };

  if (error) {
    return <div>There's an error when trying to load the members for this client.</div>;
  }

  return (
    <div className={cx(styles.ClientMembers, props.className)}>
      <Upload {...uploadProps}>
        <Button
          className={styles.uploadButton}
          icon={<UploadOutlined />}
        >
          Upload members CSV
        </Button>
      </Upload>
      {
        loading
        && <Spin size="large" />
      }
      {
        !loading && data
        && (
        <>
          <Table
            rowKey={(record: IMember) => record.id.toString()}
            dataSource={data.members}
          >
            <Column title="Name" dataIndex="name" />
            <Column title="Email" dataIndex="email" />
          </Table>
        </>
)
      }
    </div>
  );
});

export default ClientMembers;

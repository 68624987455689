import gql from 'graphql-tag';

import {
  ROLE_FRAGMENT,
} from './fragments';

export const GET_ROLES_QUERY = gql`
  query GetRolesQuery {
    roles: getRoles {
      ...RoleFragment
    }
  }
  ${ROLE_FRAGMENT}
`;

export const GET_MANAGER_ROLES_QUERY = gql`
  query GetManagerRolesQuery {
    roles: getManagerRoles {
      ...RoleFragment
    }
  }
  ${ROLE_FRAGMENT}
`;

export const UPDATE_ROLE_FOR_CLIENT_MUTATION = gql`
  mutation UpdateRoleForClientMutation($id: String!, $clientId: String!, $role: String!) {
    succeeded: updateRoleForClient(id: $id, clientId: $clientId, role: $role)
  }
`;

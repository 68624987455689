import * as React from 'react';

import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import styles from './getColumnSearchProps.scss';

export default function getColumnSearchProps (dataIndex) {
  return {
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div className={styles.SearchDropdown}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
          className={styles.searchInput}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          className={styles.searchSubmitButton}
        >
          Search
        </Button>
        <Button
          onClick={clearFilters}
          size="small"
          className={styles.searchResetButton}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined className={filtered ? styles.selectedFilterIcon : styles.unselectedFilterIcon }/>
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  };
};

import { useQuery, QueryHookOptions } from '@apollo/react-hooks';
import { GET_ELIGIBLE_MEMBER_FIELD_FOR_APPLICANT_REVIEW } from '../queries/eligibleMemberForApplicantReviewQuery';
import { GetEligibleMemberFieldForApplicantReview, GetEligibleMemberFieldForApplicantReviewVariables } from '../queries/types/GetEligibleMemberFieldForApplicantReview';

type IOptions = QueryHookOptions<GetEligibleMemberFieldForApplicantReview, GetEligibleMemberFieldForApplicantReviewVariables>;
export const useGetEligibleMemberForApplicantReview = (clientId: string, options: IOptions = {}) => (
    useQuery<GetEligibleMemberFieldForApplicantReview, GetEligibleMemberFieldForApplicantReviewVariables>(GET_ELIGIBLE_MEMBER_FIELD_FOR_APPLICANT_REVIEW, {
        variables: {
            clientId,
        },
        ...options,
    })
);

import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  SAVE_CLIENT_GROUP_MUTATION,
  GET_CLIENT_GROUPS_QUERY,
} from '@frontend/app/queries';
import {
  GetClientGroupsQuery,
} from '@frontend/app/queries/types/GetClientGroupsQuery';
import {
  SaveClientGroupMutation,
  SaveClientGroupMutationVariables,
} from '@frontend/app/queries/types/SaveClientGroupMutation';
import { IClientGroup } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, group: IClientGroup) => {
  let data: GetClientGroupsQuery;
  try {
    data = store.readQuery<GetClientGroupsQuery>({
      query: GET_CLIENT_GROUPS_QUERY,
    });
  } catch (err) {
    console.log(`cache missing: ${GET_CLIENT_GROUPS_QUERY}`);
    return;
  }

  // Write our data back to the cache.
  store.writeQuery<GetClientGroupsQuery>({
    query: GET_CLIENT_GROUPS_QUERY,
    data: {
      ...data,
      groups: unionBy(data.groups, [group], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<SaveClientGroupMutation, SaveClientGroupMutationVariables>;

export function useSaveClientGroup(options: IOptions = {}) {
  const [saveClientGroup, { loading, error }] = useMutation<
    SaveClientGroupMutation,
    SaveClientGroupMutationVariables
  >(SAVE_CLIENT_GROUP_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data.group);
    },
    onCompleted() {
      message.info('Successfully saved client group');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    saveClientGroup,
    loading,
    error,
  };
}

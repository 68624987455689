import gql from 'graphql-tag';

import { PROGRAM_FRAGMENT } from './fragments';

export const GET_PROGRAMS_QUERY = gql`
  query GetProgramsQuery($clientId: String!) {
    programs: getPrograms(clientId: $clientId) {
      ...ProgramFragment
    }
  }
  ${PROGRAM_FRAGMENT}
`;

export const GET_PROGRAM_BY_ID = gql`
  query GetProgramByIdQuery($id: Int!) {
    program: getProgramById(id: $id) {
      ...ProgramFragment
      customLandingPageProperties
    }
  }
  ${PROGRAM_FRAGMENT}
`;

export const GENERATE_ONBOARDING_PAGE = gql`
  query GenerateOnboardingPage($id: Int!, $properties: JSONObject!) {
    html: generateOnboardingPage(id: $id, properties: $properties)
  }
`;

export const EXPORT_BRIEFS = gql`
  mutation ExportBriefs($programId: Int!, $startDate: Int!, $endDate: Int!) {
    url: exportBriefs(programId: $programId, startDate: $startDate, endDate: $endDate)
  }
`;

export const UPDATE_PROGRAM_LANDING_PAGE_PROPERTIES = gql`
  mutation ProgramLandingPagePropertiesMutation($id: Int!, $properties: JSONObject!) {
    program: updateProgramLandingPageProperties(id: $id, properties: $properties) {
      ...ProgramFragment
      customLandingPageProperties
    }
  }
  ${PROGRAM_FRAGMENT}
`;

export const UPDATE_PROGRAM_CAMPAIGN_MUTATION = gql`
  mutation UpdateProgramCampaignMutation($programId: Int!, $campaign: JSONObject!) {
    campaign: updateProgramCampaign(programId: $programId, campaign: $campaign)
  }
`;

export const UPDATE_PROGRAM_LANDING_PAGE_HTML = gql`
  mutation ProgramLandingPageHTMLMutation($id: Int!, $properties: JSONObject, $rawHTML: String) {
    program: updateProgramLandingPageHTML(id: $id, properties: $properties, rawHTML: $rawHTML) {
      ...ProgramFragment
      customLandingPageProperties
    }
  }
  ${PROGRAM_FRAGMENT}
`;

export const UPDATE_PROGRAM = gql`
  mutation ProgramUpdateMutation($id: Int!, $properties: JSONObject!) {
    program: updateProgram(id: $id, properties: $properties) {
      ...ProgramFragment
    }
  }
  ${PROGRAM_FRAGMENT}
`;

export const UNARCHIVE_PROGRAM_MUTATION = gql`
  mutation UnArchiveProgramMutation($id: Int!) {
    program: unArchiveProgramById(id: $id) {
      ...ProgramFragment
    }
  }
  ${PROGRAM_FRAGMENT}
`;

import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { GET_CLIENT_ELEVATE_CAMPAIGNS_QUERY } from '@frontend/app/queries';
import {
  GetClientElevateCampaignsQuery,
  GetClientElevateCampaignsQueryVariables,
} from '@frontend/app/queries/types/GetClientElevateCampaignsQuery';

type IOptions = QueryHookOptions<GetClientElevateCampaignsQuery, GetClientElevateCampaignsQueryVariables>;

export function useGetClientElevateCampaigns(options: IOptions = {}) {
  const {
 loading, data: { campaigns = [] } = {}, refetch,
} = useQuery<
    GetClientElevateCampaignsQuery,
    GetClientElevateCampaignsQueryVariables
  >(GET_CLIENT_ELEVATE_CAMPAIGNS_QUERY, options);

  return {
    loading,
    campaigns,
    refetch,
  };
}

import * as React from 'react';
import cx from 'classnames';

import { Alert } from 'antd';

import styles from './ErrorNotice.scss';

interface IProps {
  message?: string;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const ErrorNotice: React.FC<IProps> = React.memo((props) => {
  return (
    <div className={cx(styles.ErrorNotice, props.className)}>
      <Alert
        message="Error"
        description={props.message}
        type="error"
        closable
      />
    </div>
  );
});

ErrorNotice.defaultProps = {
  message: `There's an error when trying to load the data.`,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { map, isEmpty, first, size } from 'lodash';

import { Table, Typography, Button, Popconfirm } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { LoadSpinner } from '@frontend/lib';
import { InstallApplicationDrawer } from '@frontend/app/components';
const { Column } = Table;
const { Text } = Typography;

import {
  IApplication,
  IApplicationInstance,
  useGetApplicationInstances,
  useInstallApplication,
  useUninstallApplication,
} from '@frontend/app/hooks';

const { useState } = React;
import styles from './ApplicationInstances.scss';

interface IProps {
  application: IApplication;

  className?: string;
}

/**
 * @type {React.FC}
 */
const ApplicationInstances: React.FC<IProps> = React.memo((props) => {
  const { application } = props;
  const history = useHistory();
  const { instances, loading, refetch } = useGetApplicationInstances({
    variables: {
      applicationId: application.id,
    },
    fetchPolicy: 'no-cache',
  });
  const { installApplication, loading: installing } = useInstallApplication();
  const { uninstallApplication, loading: uninstalling } = useUninstallApplication();
  const [showModal, setShowModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onUninstallApplicationsClick = async () => {
    await Promise.all(map(selectedRowKeys, async (instanceId) => await uninstallApplication({
      variables: {
        instanceId: Number(instanceId),
      },
    })));

    refetch();

    setSelectedRowKeys([]);
  };

  return (
    <div className={cx(styles.ApplicationInstances, props.className)}>
      <div className={styles.header}>
        <Text ellipsis>Installed instances of this application.</Text>
        <Popconfirm
          title={`Are you sure you want to uninstall ${size(selectedRowKeys)} applications?`}
          onConfirm={onUninstallApplicationsClick}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className={styles.uninstallButton}
            icon={<MinusOutlined />}
            type="danger"
            disabled={isEmpty(selectedRowKeys) || uninstalling}
            loading={uninstalling}
          >
            Uninstall({size(selectedRowKeys)})
          </Button>
        </Popconfirm>
        <Button
          className={styles.installButton}
          type="primary"
          onClick={() => setShowModal(true)}
        >
          Install Application
        </Button>
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && instances &&
        <Table
          rowKey={(instance: IApplicationInstance) => instance.id}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          dataSource={instances}
          className={styles.table}
        >
          <Column title="Id" dataIndex="id" />
          <Column title="Client" key="client" render={(_, instance: IApplicationInstance) => (
            <span
              className={styles.cell}
              onClick={() => history.push(`/clients/${instance.client.id}`)}
            >
              {instance.client.name}
            </span>
          )} />
          <Column title="Application Id" dataIndex="applicationId" />
        </Table>
      }
      <InstallApplicationDrawer
        applicationIds={[application.id]}
        open={showModal}
        onRequstClose={() => setShowModal(false)}
        onSubmit={(params) => {
          const { clientId, applicationIds, ...rest } = params;
          const applicationId = first(applicationIds);

          installApplication({
            variables: {
              data: {
                ...rest,
                applicationId,
              },
            },
            context: {
              headers: {
                'requested-client-id': params.clientId,
              },
            },
          }).then(() => {
            refetch();
            setShowModal(false);
          });
        }}
        isSubmitting={installing}
      />
    </div>
  );
});

export default ApplicationInstances;

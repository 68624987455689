/* Text styles */
._ClientGroupListView_13zht_2 ._header_13zht_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientGroupListView_13zht_2 ._header_13zht_2 ._deleteButton_13zht_7 {
  margin-left: auto;
}
._ClientGroupListView_13zht_2 ._header_13zht_2 ._createButton_13zht_10 {
  margin-left: 20px;
}
._ClientGroupListView_13zht_2 ._table_13zht_13 ._idCell_13zht_13 {
  color: #3996e0;
  cursor: pointer;
}
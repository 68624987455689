import * as React from 'react';
import cx from 'classnames';
import { map, find, toString } from 'lodash';

import {
  useHistory, useRouteMatch,
} from 'react-router-dom';

import { Tag, Table, Typography } from 'antd';
const { Column } = Table;
const { Title } = Typography;
import { LoadSpinner } from '@frontend/lib';

import {
  ICommunity,
  useGetCommunities,
  useGetClients,
} from '@frontend/app/hooks';
import { logger } from '@common';

import styles from './CommunityListView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const CommunityListView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { loading: loadingCommunities, communities } = useGetCommunities({
    variables: {
      admin: true,
    },
  });
  const { loading: loadingClients, clients } = useGetClients();

  const loading = loadingCommunities || loadingClients;

  logger.debug({
    loading,
    data: communities,
  });

  return (
    <div className={cx(styles.CommunityListView, props.className)}>
      <div className={styles.header}>
        <Title>Communities</Title>
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && communities &&
        <Table
          rowKey={(record: ICommunity) => toString(record.id)}
          dataSource={communities}
          className={styles.table}
        >
          <Column title="Id" key="id" render={(_, community: ICommunity) => (
            <span
              className={styles.idCell}
              onClick={() => history.push(`${match.url}/${community.id}`)}
            >
              {community.id}
            </span>
          )} />
          <Column title="Title" dataIndex="title" />
          <Column title="Description" dataIndex="description" />
          <Column title="Clients" key="clients" render={(_, community: ICommunity) => {
            const { clientIds } = community;

            return (
              <span className={styles.clientCell}>
                {map(clientIds, (clientId) => {
                  const client = find(clients, (c) => c.id === clientId);

                  if (!client) {
                    return null;
                  }

                  return (
                    <Tag
                      key={client.id}
                      onClick={() => history.push(`/clients/${client.id}`)}
                      className={styles.clientTag}
                    >
                      {client.name}
                    </Tag>
                  );
                })}
              </span>
            );
          }} />
        </Table>
      }
    </div>
  );
});

export default CommunityListView;

import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { pullAllBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import { DELETE_USER_MUTATION, GET_USERS_QUERY } from '@frontend/app/queries';
import { GetUsersQuery } from '@frontend/app/queries/types/GetUsersQuery';
import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
} from '@frontend/app/queries/types/DeleteUserMutation';

const { useEffect } = React;

const updateCache = (store: DataProxy, userId: string) => {
  let data: GetUsersQuery;
  try {
    // Read the data from our cache for this query.
    data = store.readQuery<GetUsersQuery>({ query: GET_USERS_QUERY });
  } catch (err) {
    // Cache doesn't exist. Do nothig.
    return;
  }

  const users = [...data.users];
  pullAllBy(users, [{ id: userId }], 'id');

  // Write our data back to the cache.
  store.writeQuery<GetUsersQuery>({
    query: GET_USERS_QUERY,
    data: {
      ...data,
      users,
    },
  });
};

type IOptions = MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export function useDeleteUserById(options: IOptions = {}) {
  const [deleteUserById, { loading, error }] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DELETE_USER_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully deleted user');
    },
  });

  // so that we can capture the id during actual delete call
  const _deleteUserById: typeof deleteUserById = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [store, result] = args;
        if (!result.data.deleted) {
          return;
        }

        const { id } = _options.variables || options.variables;
        updateCache(store, id);
      },
    };

    return deleteUserById(...args);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteUserById: _deleteUserById,
    loading,
  };
}

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_ORGANIZATION_QUERY } from '@frontend/app/queries';
import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  GetOrganizationQuery_organization,
} from '@frontend/app/queries/types/GetOrganizationQuery';

const { useEffect } = React;
export type IOrganization = GetOrganizationQuery_organization;
type IOptions = QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>;

export function useGetOrganizationById(options: IOptions = {}) {
  const {
 loading, data: { organization = null } = {}, error, refetch,
} = useQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GET_ORGANIZATION_QUERY, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    organization,
    error,
    refetch,
  };
}

import gql from 'graphql-tag';

export const MEMBER_TAG_FRAGMENT = gql`
  fragment MemberTagFragment on MemberTag {
    id
    clientId
    name
    createdDate
    updatedDate
  }
`;

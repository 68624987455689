import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Main from './app/containers/Main';

const renderMethod = ReactDOM.render;

renderMethod(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
  document.getElementById('app'),
);

import gql from 'graphql-tag';

export const GET_EMBEDDED_REPORTING_ITEMS = gql`
  query GetEmbeddedReportingItems {
    embeddedReportingItems: getEmbeddedReportingItems {
      id
      name
      url
      position
      createdDate
      updatedDate
    }
  }
`;

export const SAVE_EMBEDDED_REPORTING_ITEM = gql`
  mutation SaveEmbeddedReportingItem($input: EmbeddedReportingItemInput!) {
    saveEmbeddedReportingItem(embeddedReportingItemInput: $input) {
      id
      name
      url
      position
      createdDate
      updatedDate
    }
  }
`;

export const DELETE_EMBEDDED_REPORTING_ITEM_BY_ID = gql`
  mutation DeleteEmbeddedReportingItem($id: Int!) {
    deleteEmbeddedReportingItemById(id: $id)
  }
`;

import * as React from 'react';
import cx from 'classnames';

import { GetUserQuery_user as IUser } from '@frontend/app/queries/types/GetUserQuery';

import styles from './UserDetails.scss';

interface IProps {
  user: IUser;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const UserDetails: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <div className={cx(styles.UserDetails, props.className)}>
      <pre>
        {JSON.stringify(props.user, undefined, 2)}
      </pre>
    </div>
  );
});

export default UserDetails;

import * as React from 'react';
import cx from 'classnames';
import { split, last } from 'lodash';

import {
  Link, Route, Redirect, Switch,
  useParams, useHistory, useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Spin, Typography, Button,
  Menu, Dropdown, Tooltip,
  message,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { EditUserDrawer } from './EditUserDrawer';

import {
  useGetUserById,
  useSaveUser,
  useDeleteUserById,
  useMarkUserEmailAsVerified,
  useSendVerificationEmail,
  useSendResetPasswordEmail,
} from '@frontend/app/hooks';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy, useState, useMemo } = React;
const { Title } = Typography;
import styles from './UserDetailView.scss';

const UserDetails = lazy(() => import('./UserDetailView/UserDetails'));
const UserClients = lazy(() => import('./UserDetailView/UserClients'));

interface IMatchParams {
  userId: string;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const UserDetailView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { userId } = useParams<IMatchParams>();;
  const { saveUser, loading: saving } = useSaveUser();
  const { markUserEmailAsVerified } = useMarkUserEmailAsVerified();
  const { sendVerificationEmail } = useSendVerificationEmail();
  const { sendResetPasswordEmail } = useSendResetPasswordEmail();
  const { deleteUserById } = useDeleteUserById();

  const { loading, user, error, refetch } = useGetUserById({
    variables: {
      id: userId,
    },
  });

  const lastRoute = last(split(location.pathname, '/'));
  const userEmailVerified = useMemo(() => user?.auth0User?.emailVerified, [user]);
  const [selectedRoute, setSelectedRoute] = useState(
    ['details', 'clients'].includes(lastRoute) ? lastRoute : 'details',
  );
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleMenuItemClick = async ({ key }) => {
    switch (key) {
      case 'edit': {
        setOpenUpdateModal(true);

        break;
      }
      // mark user email as verified
      case 'mark_email_verified': {
        await markUserEmailAsVerified({ variables: { userId: user.id } });
        message.success('User email is now verified.');

        refetch();

        break;
      }
      // verification email
      case 'verify': {
        sendVerificationEmail({ variables: { userId: user.id } });
        message.success('Verification email has been sent.');

        break;
      }
      // reset password email
      case 'reset': {
        sendResetPasswordEmail({ variables: { email: user.email } });
        message.success('Reset password email has been sent.');

        break;
      }
      case 'delete': {
        deleteUserById({
          variables: {
            id: user.id,
          },
        }).then(() => history.push('/users'));

        break;
      }
      default: {
        return;
      }
    }
  };
  const handleNavClick = ({ key }) => setSelectedRoute(key);

  if (error) {
    return <div>There's an error when trying to load the user detail.</div>;
  }

  const hasUsernamePasswordConnection = useMemo(
    () => user?.auth0User?.hasUsernamePasswordConnection,
    [user],
  );
  const emailVerified = useMemo(
    () => user?.auth0User?.emailVerified,
    [user],
  );
  const ActionMenu = (
    <Menu onClick={handleMenuItemClick}>
      <Menu.Item
        disabled={!hasUsernamePasswordConnection}
        key="edit"
      >
        <Tooltip
          title="Cannot edit user that has no 'Username-Password-Authentication' connection."
          overlayStyle={{
            display: hasUsernamePasswordConnection ? 'none' : undefined,
          }}
        >
          <p>Edit</p>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="mark_email_verified" disabled={userEmailVerified}>
        Mark user email as verified
      </Menu.Item>
      <Menu.Item
        disabled={!hasUsernamePasswordConnection || emailVerified}
        key="verify"
      >
        <Tooltip
          title={
            !hasUsernamePasswordConnection ?
              `Cannot send reset password email to user that has no 'Username-Password-Authentication' connection.` :
              (emailVerified ? 'This user has already verified their email.' : '')
          }
          overlayStyle={{
            display: (hasUsernamePasswordConnection && !emailVerified) ? 'none' : undefined,
          }}
        >
          <p>Send verification email</p>
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        disabled={!hasUsernamePasswordConnection}
        key="reset"
      >
        <Tooltip
          title="Cannot send reset password email to user that has no 'Username-Password-Authentication' connection."
          overlayStyle={{
            display: hasUsernamePasswordConnection ? 'none' : undefined,
          }}
        >
          <p>Send reset password email</p>
        </Tooltip>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  return (
    <div className={cx(styles.UserDetailView, props.className)}>
      {
        loading &&
        <Spin size="large" />
      }
      {
        !loading && user &&
        <>
          <div className={styles.header}>
            <Title>{`${user.name} <${user.email}>`}</Title>
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <Button className={styles.action}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className={styles.children}>
            <Menu
              className={styles.menu}
              onClick={handleNavClick}
              mode="horizontal"
              selectedKeys={[selectedRoute]}
            >
              <Menu.Item key="details">
                <Link to={`${match.url}/details`}>Details</Link>
              </Menu.Item>
              <Menu.Item key="clients">
                <Link to={`${match.url}/clients`}>Clients</Link>
              </Menu.Item>
            </Menu>
            <Switch>
              <Route path={`${match.path}/details`} render={
                (props) => lazyLoadComponent(UserDetails)({ ...props, user: user })
              } />
              <Route path={`${match.path}/clients`} render={
                (props) => lazyLoadComponent(UserClients)({ ...props, user: user })
              } />
              <Redirect from={`${match.path}`} to={`${match.path}/details`} />
            </Switch>
          </div>
          <EditUserDrawer
            user={user}
            open={openUpdateModal}
            onRequstClose={() => setOpenUpdateModal(false)}
            isSubmitting={saving}
            onSubmit={(params) => {
              saveUser({
                variables: {
                  user: {
                    ...params,
                    id: user.id,
                  },
                },
              }).then(() => setOpenUpdateModal(false))
            }}
          />
        </>
      }
    </div>
  );
});

export default UserDetailView;

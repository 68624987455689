import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { EXPORT_BRIEFS } from '@frontend/app/queries';
import { ExportBriefs, ExportBriefsVariables } from '@frontend/app/queries/types/ExportBriefs';

type IOptions = MutationHookOptions<ExportBriefs, ExportBriefsVariables>;

export function useExportBriefs(options: IOptions = {}) {
  return useMutation<ExportBriefs, ExportBriefsVariables>(
    EXPORT_BRIEFS,
    options,
  );
}

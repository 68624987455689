import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { SET_ROTATING_TOKENS_MUTATION } from '@frontend/app/queries';
import {
  SetRotatingTokensMutation,
} from '@frontend/app/queries/types/SetRotatingTokensMutation';

const { useEffect } = React;
type IOptions = MutationHookOptions<SetRotatingTokensMutation>;

export function useSetRotatingTokens(options: IOptions = {}) {
  const [
    setRotatingTokens, { loading, error },
  ] = useMutation<SetRotatingTokensMutation>(SET_ROTATING_TOKENS_MUTATION, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    setRotatingTokens,
    error,
  };
}

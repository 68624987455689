import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { UPDATE_PROGRAM } from '@frontend/app/queries';
import { ProgramUpdateMutation, ProgramUpdateMutationVariables } from '@frontend/app/queries/types/ProgramUpdateMutation';

type IOptions = MutationHookOptions<ProgramUpdateMutation, ProgramUpdateMutationVariables>;

export function useUpdateProgram(options: IOptions = {}) {
  return useMutation<ProgramUpdateMutation, ProgramUpdateMutationVariables>(
    UPDATE_PROGRAM,
    options,
  );
}

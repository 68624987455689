import gql from 'graphql-tag';

import {
  APPLICATION_INSTANCE_FRAGMENT,
  APPLICATION_FRAGMENT,
  CLIENT_FRAGMENT,
} from './fragments';

export const GET_APPLICATION_INSTANCES_QUERY = gql`
  query GetApplicationInstancesQuery($applicationId: String) {
    instances: getApplicationInstances(applicationId: $applicationId) {
      ...ApplicationInstanceFragment
      client {
        ...ClientFragment
      }
      application {
        ...ApplicationFragment
      }
    }
  }
  ${APPLICATION_INSTANCE_FRAGMENT}
  ${CLIENT_FRAGMENT}
  ${APPLICATION_FRAGMENT}
`;

export const INSTALL_APPLICATION_MUTATION = gql`
  mutation InstallApplicationMutation($data: InstallApplicationInput!) {
    instance: installApplication(data: $data) {
      ...ApplicationInstanceFragment
      client {
        ...ClientFragment
      }
      application {
        ...ApplicationFragment
      }
    }
  }
  ${APPLICATION_INSTANCE_FRAGMENT}
  ${CLIENT_FRAGMENT}
  ${APPLICATION_FRAGMENT}
`;

export const UNINSTALL_APPLICATION_MUTATION = gql`
  mutation UninstallApplicationMutation($instanceId: Int!) {
    uninstalled: uninstallApplication(instanceId: $instanceId)
  }
`;

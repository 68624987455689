import gql from 'graphql-tag';

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    name
    hostname
    domains
    memberDomainsRestricted
    featureIds
    disabledFeatureIds
    salesForceId
    isTestClient
    clientGroupId,
    salesForceMetaData
    pfaOrderTag
    active
    forceActive
    pfaLocationIds
  }
`;

import gql from 'graphql-tag';

export const GET_MEMBERS_QUERY = gql`
  query GetMembersQuery {
    members {
      id, name, email
    }
  }
`;

export const UPLOAD_MEMBERS_CSV = gql`
  mutation UploadMembersCSV($file: Upload!){
    uploadMemberCSV(file: $file)
  }
`;

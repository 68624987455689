._ClientEmbeddedReporting_u4n95_1 ._header_u4n95_1 {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientEmbeddedReporting_u4n95_1 ._header_u4n95_1 ._addButton_u4n95_7 {
  margin-left: auto;
}

._AddOrEditModal_u4n95_11 ._select_u4n95_11 {
  width: 100%;
}
._AddOrEditModal_u4n95_11 ._input_u4n95_14 {
  width: 100%;
  margin-bottom: 10px;
}
._AddOrEditModal_u4n95_11 ._inputLabel_u4n95_18 {
  font-weight: bold !important;
}
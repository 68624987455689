import gql from 'graphql-tag';

export const GET_CLIENT_META_DATA = gql`
  query GetClientMetadata($clientId: String!) {
    getClientMetadata:getClientMetadata(clientId: $clientId) {
        applicantReviewFields
        allowedDomains
    }
  }
`;

export const GET_AUTHORIZED_AGENT = gql`
  query GetAuthorizedAgent($clientId: String!) {
    getClientMetadataWithAuthorizedAgent:getClientMetadataWithAuthorizedAgent(clientId: $clientId) {
      authorizedAgent {
        id,
        title,
        useFullNameForSignature,
        signatureText
      }
    }
  }
`;

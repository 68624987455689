import * as React from 'react';
import cx from 'classnames';

import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy } = React;
import styles from './ClientsView.scss';
const ClientListView = lazy(() => import('./ClientListView'));
const ClientDetailView = lazy(() => import('./ClientDetailView'));

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientsView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={cx(styles.ClientsView, props.className)}>
      <Switch>
        <Route path={`${match.path}`} exact render={lazyLoadComponent(ClientListView)} />
        <Route path={`${match.path}/:clientId`} render={lazyLoadComponent(ClientDetailView)} />
      </Switch>
    </div>
  );
});

export default ClientsView;

import gql from 'graphql-tag';

export const CLIENT_FEATURE_FRAGMENT = gql`
  fragment ClientFeatureFragment on ClientFeature {
    id
    name
    description
    state
  }
`;

import gql from 'graphql-tag';

import { MEMBER_TAG_FRAGMENT } from './fragments';

export const GET_MEMBER_TAGS_QUERY = gql`
  query GetMemberTagsQuery {
    tags: getMemberTags {
      ...MemberTagFragment
    }
  }
  ${MEMBER_TAG_FRAGMENT}
`;

export const UPLOAD_TAGS_CSV = gql`
  mutation UploadTagsCSV($file: Upload!){
    uploadTagCSV(file: $file)
  }
`;

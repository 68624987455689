import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { UPDATE_PROGRAM_LANDING_PAGE_HTML } from '@frontend/app/queries';
import {
  ProgramLandingPageHTMLMutation,
  ProgramLandingPageHTMLMutationVariables,
} from '@frontend/app/queries/types/ProgramLandingPageHTMLMutation';

type IOptions = MutationHookOptions<ProgramLandingPageHTMLMutation, ProgramLandingPageHTMLMutationVariables>;

export function useUpdateProgramLandingPageHTML(options: IOptions = {}) {
  return useMutation<ProgramLandingPageHTMLMutation, ProgramLandingPageHTMLMutationVariables>(
    UPDATE_PROGRAM_LANDING_PAGE_HTML,
    options,
  );
}

import * as React from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';

import {
  Upload, Button, Table, message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import {
  useGetMemberTags,
  useUploadMemberTagCSV,
} from '@frontend/app/hooks';

const { Column } = Table;
const { useEffect } = React;
import styles from './ClientMemberTags.scss';

interface IMatchParams {
  clientId: string;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientMemberTags: React.FunctionComponent<IProps> = React.memo((props) => {
  const { clientId } = useParams<IMatchParams>();
  const context = {
    headers: {
      'requested-client-id': clientId,
    },
  };
  const { tags, loading, refetch } = useGetMemberTags({ context });
  const { uploadMemberTagCSV, loading: uploading } = useUploadMemberTagCSV();

  useEffect(() => {
    if (clientId) {
      refetch();
    }
  }, [clientId]);

  const uploadProps = {
    beforeUpload: (file) => {
      const fileSizeLimit = file.size / 1024 / 1024 < 100;
      if (!fileSizeLimit) {
        const errorMessage = 'File cannot exceed 100MB';
        message.error(errorMessage);
        return false;
      }

      // Check file type
      const regex = /^.+(\.csv)$/g;
      const isCSV = file.type === 'text/csv' || regex.test(file.name);

      if (!isCSV) {
        const errorMessage = 'File format must be .csv';
        message.error(errorMessage);
        return false;
      }

      uploadMemberTagCSV({
        variables: { file },
        context,
      }).then(() => refetch());

      return false;
    },
  };

  return (
    <div className={cx(styles.ClientMemberTags, props.className)}>
      <Upload {...uploadProps}>
        <Button
          className={styles.uploadButton}
          icon={<UploadOutlined />}
          loading={uploading}
        >
          Upload member tags CSV
        </Button>
      </Upload>
      <Table
        rowKey={(record) => record.id}
        dataSource={tags}
        loading={loading}
      >
        <Column title="Name" dataIndex="name" />
        <Column title="Created Date" dataIndex="createdDate" />
      </Table>
    </div>
  );
});

export default ClientMemberTags;

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_ALLOY_WORKFLOWS_QUERY } from '@frontend/app/queries';
import { GetAlloyWorkflowsQuery } from '@frontend/app/queries/types/GetAlloyWorkflowsQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetAlloyWorkflowsQuery>;

export function useGetAlloyWorkflows(options: IOptions = {}) {
  const {
    loading, data: { workflows = [] } = {}, error, refetch,
  } = useQuery<GetAlloyWorkflowsQuery>(
    GET_ALLOY_WORKFLOWS_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    workflows,
    error,
    refetch,
  };
}

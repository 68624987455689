import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { GET_PROGRAM_BY_ID } from '@frontend/app/queries';
import {
  GetProgramByIdQuery,
  GetProgramByIdQueryVariables,
} from '@frontend/app/queries/types/GetProgramByIdQuery';

type IOptions = QueryHookOptions<GetProgramByIdQuery, GetProgramByIdQueryVariables>;

export function useGetProgramById(options: IOptions = {}) {
  return useQuery<GetProgramByIdQuery, GetProgramByIdQueryVariables>(
    GET_PROGRAM_BY_ID,
    options,
  );
}

._ClientApplications_chvax_1 ._header_chvax_1 {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ClientApplications_chvax_1 ._header_chvax_1 ._uninstallButton_chvax_7 {
  margin-left: auto;
}
._ClientApplications_chvax_1 ._header_chvax_1 ._installButton_chvax_10 {
  margin-left: 20px;
}
._ClientApplications_chvax_1 ._table_chvax_13 ._cell_chvax_13 {
  color: #3996e0;
  cursor: pointer;
}
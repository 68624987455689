import * as React from 'react';
import { map } from 'lodash';

import {
 Table, Typography,
} from '@revfluence/fresh';

import { LoadSpinner } from '@frontend/lib';
import { useGetIGDMResourcesQuery } from '@frontend/app/hooks';
import ActionCell from './ActionCell';

const { useMemo } = React;

const { Text } = Typography;

interface TableDataType {
  igdmResource: string;
}

interface IClientConnectedIGDMProps {
  className?: string;
  clientId: string;
}

const ClientConnectedIGDM: React.FC<IClientConnectedIGDMProps> = React.memo((props) => {
  const { data, loading } = useGetIGDMResourcesQuery(
    {
      variables: {
        clientId: props.clientId,
      },
      fetchPolicy: 'no-cache',
    },
);
  const columns = [
    {
      title: 'IGDM',
      dataIndex: 'igdmResource',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: TableDataType) => <ActionCell igdm={record.igdmResource} />,
      width: 200,
    },
  ];
  const dataSource: TableDataType[] = useMemo(
    () => map(data?.igdmResources || [], (igdmResource) => ({ igdmResource })), [data],
  );

  if (loading) return <LoadSpinner />;
  return (
    <div className={props.className}>
      {dataSource.length ? (
        <Table rowKey="igdmResource" columns={columns} dataSource={dataSource} pagination={false} />
      ) : (
        <Text>No connected igdm accounts</Text>
      )}
    </div>
  );
});
ClientConnectedIGDM.displayName = 'ClientConnectedIGDM';

export default ClientConnectedIGDM;

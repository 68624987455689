import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { pullAllBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  DELETE_CLIENT_GROUP_MUTATION,
  GET_CLIENT_GROUPS_QUERY,
} from '@frontend/app/queries';
import { GetClientGroupsQuery } from '@frontend/app/queries/types/GetClientGroupsQuery';
import {
  DeleteClientGroupMutation,
  DeleteClientGroupMutationVariables,
} from '@frontend/app/queries/types/DeleteClientGroupMutation';

const { useEffect } = React;

const updateCache = (store: DataProxy, groupId: number) => {
  let data: GetClientGroupsQuery;
  try {
    data = store.readQuery<GetClientGroupsQuery>({ query: GET_CLIENT_GROUPS_QUERY });
  } catch (err) {
    return;
  }

  const groups = [...data.groups];
  pullAllBy(groups, [{ id: groupId }], 'id');

  store.writeQuery<GetClientGroupsQuery>({
    query: GET_CLIENT_GROUPS_QUERY,
    data: {
      ...data,
      groups,
    },
  });
};

type IOptions = MutationHookOptions<DeleteClientGroupMutation, DeleteClientGroupMutationVariables>;
export function useDeleteClientGroupById(options: IOptions = {}) {
  const [deleteClientGroupById, { loading, error }] = useMutation<
    DeleteClientGroupMutation,
    DeleteClientGroupMutationVariables
  >(DELETE_CLIENT_GROUP_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully deleted client group');
    },
  });

  // so that we can capture the id during actual delete call
  const _deleteClientGroupById: typeof deleteClientGroupById = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [store, result] = args;
        if (!result.data.deleted) {
          return;
        }

        const { groupId } = _options.variables || options.variables;
        updateCache(store, groupId);
      },
    };

    return deleteClientGroupById(...args);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteClientGroupById: _deleteClientGroupById,
    loading,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from 'react';
import cx from 'classnames';
import {
  useHistory,
} from 'react-router-dom';
import { map, isEmpty, size } from 'lodash';

import { Table, Typography, Button, Popconfirm } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { LoadSpinner } from '@frontend/lib';
import { InstallApplicationDrawer } from '@frontend/app/components';
const { Column } = Table;
const { Text } = Typography;

import {
  IClient,
  IApplicationInstance,
  useGetApplicationInstances,
  useInstallApplication,
  useUninstallApplication,
} from '@frontend/app/hooks';

const { useState } = React;
import styles from './ClientApplications.scss';

interface IProps {
  client: IClient;

  className?: string;
}

/**
 * @type {React.FC}
 */
const ClientApplications: React.FC<IProps> = React.memo((props) => {
  const { client } = props;
  const history = useHistory();
  const { instances, loading, refetch } = useGetApplicationInstances({
    context: {
      headers: {
        'requested-client-id': client.id,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const { installApplication, loading: installing } = useInstallApplication();
  const { uninstallApplication, loading: uninstalling } = useUninstallApplication();
  const [showModal, setShowModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onUninstallApplicationsClick = async () => {
    await Promise.all(map(selectedRowKeys, async (instanceId) => await uninstallApplication({
      variables: {
        instanceId: Number(instanceId),
      },
      context: {
        headers: {
          'requested-client-id': client.id,
        },
      },
    })));

    refetch();

    setSelectedRowKeys([]);
  };

  return (
    <div className={cx(styles.ClientApplications, props.className)}>
      <div className={styles.header}>
        <Text ellipsis>Installed applications for this client.</Text>
        <Popconfirm
          title={`Are you sure you want to uninstall ${size(selectedRowKeys)} applications?`}
          onConfirm={onUninstallApplicationsClick}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className={styles.uninstallButton}
            icon={<MinusOutlined />}
            type="danger"
            disabled={isEmpty(selectedRowKeys) || uninstalling}
            loading={uninstalling}
          >
            Uninstall({size(selectedRowKeys)})
          </Button>
        </Popconfirm>
        <Button
          className={styles.installButton}
          type="primary"
          onClick={() => setShowModal(true)}
        >
          Install Applications
        </Button>
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && instances &&
        <Table
          rowKey={(instance: IApplicationInstance) => instance.id}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          dataSource={instances}
          className={styles.table}
        >
          <Column title="Id" dataIndex="id" />
          <Column title="Client Id" dataIndex="clientId" />
          <Column title="Application" key="application" render={(_, instance: IApplicationInstance) => (
            <span
              className={styles.cell}
              onClick={() => history.push(`/apps/${instance.application.id}`)}
            >
              {instance.application.name}
            </span>
          )} />
        </Table>
      }
      <InstallApplicationDrawer
        clientId={client.id}
        installedApplications={instances}
        open={showModal}
        onRequstClose={() => setShowModal(false)}
        onSubmit={async (params) => {
          const { clientId, applicationIds, ...rest } = params;

          await Promise.all(map(applicationIds, (applicationId) => installApplication({
            variables: {
              data: {
                applicationId,
                ...rest,
              },
            },
            context: {
              headers: {
                'requested-client-id': params.clientId,
              },
            },
          })));

          refetch();
          setShowModal(false);
        }}
        isSubmitting={installing}
      />
    </div>
  );
});

export default ClientApplications;

/* Text styles */
._ApplicationDetailView_239m9_2 ._header_239m9_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ApplicationDetailView_239m9_2 ._header_239m9_2 ._action_239m9_7 {
  margin-left: auto;
}
._ApplicationDetailView_239m9_2 ._children_239m9_10 ._menu_239m9_10 {
  margin-bottom: 20px;
}

._warning_239m9_14 {
  color: #da3737;
}
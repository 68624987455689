import * as React from 'react';
import cx from 'classnames';

import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy } = React;
import styles from './UsersView.scss';
const UserListView = lazy(() => import('./UserListView'));
const UserDetailView = lazy(() => import('./UserDetailView'));

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const UsersView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={cx(styles.UsersView, props.className)}>
      <Switch>
        <Route path={`${match.path}`} exact render={lazyLoadComponent(UserListView)} />
        <Route path={`${match.path}/:userId`} render={lazyLoadComponent(UserDetailView)} />
      </Switch>
    </div>
  );
});

export default UsersView;

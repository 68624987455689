import {
 Button, Checkbox, Col, Form, Input, Select, Spin,
} from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IClient, useGetUsers, useUpdateClientAuthorizedAgent } from '@frontend/app/hooks';
import { useGetAuthorizedAgent } from '@frontend/app/hooks/clients/useGetAuthorizedAgent';
import { CheckboxProps } from 'antd/lib/checkbox';

import styles from './ClientBrief.scss';

const { useForm } = Form;

interface ClientBriefProps extends RouteComponentProps {
  client: IClient
}

interface FormValues {
  authorizedAgent: string | null;
  title: string | null;
  useFullName: boolean | null;
  signatureText: string | null;
}

const formInitialValues: FormValues = {
  authorizedAgent: null,
  title: null,
  useFullName: true,
  signatureText: null,
};

const ClientBrief: React.FunctionComponent<ClientBriefProps> = ({ client }) => {
  const clientId = client.id;
  const { users, loading: loadingUsers } = useGetUsers({}, 'An error occured while fetching users');
  const {
    authorizedAgent: currentAuthorizedAgent,
    loading: loadingCurrentAgent,
    refetch: refetchAuthorizedAgent
  } = useGetAuthorizedAgent(clientId, {}, 'An error occured while fetching the current settings');
  const {
    updateClientAuthorizedAgent,
    loading: updateInProgress,
  } = useUpdateClientAuthorizedAgent({}, 'Settings saved successfully', 'An error occured while saving the settings');
  const [form] = useForm();

  
  const agentOptions = React.useMemo(() => client.users?.map((user) => ({ value: user.id, label: `${user.name} <${user.email}>` })), [users]);
  
  const [useFullName, setUseFullName] = React.useState<CheckboxProps['checked']>(formInitialValues.useFullName ?? true );
  const [selectedAgent, setSelectedAgent] = React.useState(formInitialValues.authorizedAgent);

  
  React.useEffect(() => {
    if (!currentAuthorizedAgent) return;
    form.setFieldsValue({
      authorizedAgent: currentAuthorizedAgent.id,
      title: currentAuthorizedAgent.title,
      useFullName: currentAuthorizedAgent.useFullNameForSignature,
      signatureText: currentAuthorizedAgent.signatureText,
    });
    setUseFullName(currentAuthorizedAgent.useFullNameForSignature ?? true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAuthorizedAgent]);



  React.useEffect(() => {
    if (!selectedAgent) return;
    const selectedAgentDetails = users.find((user) => user.id === selectedAgent);
    if (selectedAgentDetails) {
      const useFullNameLocal = selectedAgentDetails.useFullNameForSignature === null ? true : selectedAgentDetails.useFullNameForSignature;
      form.setFieldsValue({
        title: selectedAgentDetails.title,
        useFullName: useFullNameLocal,
        signatureText: selectedAgentDetails.signatureText,
      });
      setUseFullName(useFullNameLocal);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgent, users]);

  const submitData = async (values: Partial<FormValues>) => {
    if(values.authorizedAgent) {
      await updateClientAuthorizedAgent({
        variables: {
          clientId,
          data: {
            authorizedAgentId: values.authorizedAgent,
            userSignature: {
              title: values.title,
              useFullNameForSignature: values.useFullName,
              signatureText: values.signatureText,
            },
          },
        },
      });
      refetchAuthorizedAgent();
    }
  };

  const handleCheckBoxChange: CheckboxProps['onChange'] = (e) => {
    setUseFullName(e.target.checked)
  } 

  return (
    <Spin spinning={loadingCurrentAgent || updateInProgress}>
      <div className={styles.ClientBrief}>
        <h2>Countersign Briefs</h2>
        <p>
          Select a team member to be an Authorized Agent to countersign briefs. Their signature will appear in the PDF
          download of all sent briefs.
        </p>
        <Form
          form={form}
          layout="vertical"
          labelCol={{ span: 24 }}
          colon
          autoFocus
          onFinish={submitData}
          initialValues={formInitialValues}
        >
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="Authorized Agent"
              name="authorizedAgent"
              rules={[{ required: true, message: 'Please select an agent!' }]}
            >
              <Select
                placeholder="Select"
                loading={loadingUsers}
                options={agentOptions}
                optionFilterProp="label"
                showSearch
                onChange={(value: string) => {setSelectedAgent(value)}}
              />
            </Form.Item>

            <Form.Item label="Authorized Agent Title" name="title">
              <Input placeholder="Enter title" />
            </Form.Item>

            <Form.Item label="Authorized Agent Signature" name="useFullName" valuePropName="checked">
              <Checkbox onChange={handleCheckBoxChange} checked={useFullName}>Use full name in handwriting font</Checkbox>
            </Form.Item>

            {!useFullName && (
              <Form.Item
                name="signatureText"
                required={!useFullName}
                label="Alternate text for signature"
                rules={[{ required: !useFullName, message: 'Please provide alternate text for signature' }]}
              >
                <Input placeholder="Enter signature text" />
              </Form.Item>
            )}

            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Form>
      </div>
    </Spin>
  );
};

export default ClientBrief;

import * as React from 'react';
import { useGenerateProgramOnboardingPage } from '@frontend/app/hooks';
import { LoadSpinner } from '@frontend/lib';

import { useProgramOnboardingContext } from './context/ProgramOnboardingContext';

export const ProgramOnboardingPagePreview = () => {
  const { programId, properties } = useProgramOnboardingContext();

  const {
    loading,
    error,
    data: {
      html = null,
    } = {},
  } = useGenerateProgramOnboardingPage({
    variables: {
      properties,
      id: programId,
    },
  });

  return (
    <div>
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      {loading && <LoadSpinner />}
      {error && (
        <div>{error.message}</div>
      )}
    </div>
  );
};

import gql from 'graphql-tag';

export const SAVE_ELIGIBLE_MEMBER_FIELD_FOR_APPLICANT_REVIEW = gql`
  mutation SaveEligibleMemberFieldForApplicantReview($clientId: String!,$fields: JSONObject!) {
    saveEligibleMemberFieldForApplicantReview: updateApplicantReviewFields(clientId: $clientId, fields: $fields) {
        id
        applicantReviewFields
    }
  }
`;

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_MANAGER_ROLES_QUERY } from '@frontend/app/queries';
import {
  GetManagerRolesQuery,
} from '@frontend/app/queries/types/GetManagerRolesQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetManagerRolesQuery>;

export function useGetManagerRoles(options: IOptions = {}) {
  const {
 loading, data: { roles = [] } = {}, error, refetch,
} = useQuery<GetManagerRolesQuery>(
    GET_MANAGER_ROLES_QUERY,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    roles,
    error,
    refetch,
  };
}

import gql from 'graphql-tag';

export const PROGRAM_FRAGMENT = gql`
  fragment ProgramFragment on Program {
    id
    title
    communityId
    customLandingPagePath
    owner
    inviteEmailSubject
    inviteEmailText
    status
  }
`;

import * as React from 'react';
import cx from 'classnames';
import { map, trim, isEmpty, sortBy, join, split, filter, toLower } from 'lodash';

import {
  Drawer, Form, Input,
  Button, Select, Checkbox,
} from 'antd';
const { Option } = Select;

import {
  IClient,
  useGetClientGroups,
  useGetClientFeatures
} from '@frontend/app/hooks';

const { useEffect, useMemo, useState } = React;
import styles from './EditClientDrawer.scss';

export interface ICreateClientParams {
  clientGroupId: number;
  name: string;
  hostname: string;
  domains: string[];
  featureIds: number[];
  disabledFeatureIds: number[];
}
interface IProps {
  open: boolean;
  onRequstClose(): void;

  isSubmitting: boolean;
  onSubmit(client: ICreateClientParams): void;
  client?: IClient;

  className?: string;
}

interface IEditClientFormProps {
  onSubmit(client: ICreateClientParams): void;
  isSubmitting: boolean;

  client?: IClient;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const EditClientForm: React.FunctionComponent<IEditClientFormProps> = React.memo((props) => {
  const [form] = Form.useForm();
  const { groups } = useGetClientGroups();
  const { features } = useGetClientFeatures();
  const [isTestClient, setIsTestClient] = useState(false);
  const [enableConversationPrivacy, setEnableConversationPrivacy] = useState(true);
  const defaultFeatures = [
    'messageTemplate',
    'variableReplacement',
    'workflow',
    'deleteMember',
    'showOnboarding',
  ];

  useEffect(() => {
    const { client } = props;
    if (!isEmpty(client)) {
      const values = {
        name: client.name,
        hostname: client.hostname,
        salesForceId: client.salesForceId,
        clientGroupId: client.clientGroupId,
        domains: join(client.domains, ','),
        memberDomainsRestricted: client.memberDomainsRestricted,
        featureIds: client.featureIds,
        disabledFeatureIds: client.disabledFeatureIds,
        isTestClient: !!client.isTestClient,
        pfaOrderTag: client.pfaOrderTag,
        pfaLocationIds: client.pfaLocationIds || [],
      };

      form.setFieldsValue(values);
      setIsTestClient(!!client.isTestClient);
      setEnableConversationPrivacy(!!client.memberDomainsRestricted);
    } else {
      // default values for the fields
      const values = {
        featureIds: map(filter(features, (feature) => defaultFeatures.includes(feature.name)), 'id'),
        memberDomainsRestricted: true,
      };
      form.setFieldsValue(values);
    }
  }, [form, props.client, features]);

  const SFDCRules = useMemo(() => {
    if (isTestClient) {
      return [];
    }

    return [{ required: true, message: `Please enter the client's SFDC ID.` }];
  }, [isTestClient]);

  const domainsRules = useMemo(() => {
    if (!enableConversationPrivacy) {
      return [];
    }

    return [{ required: true, message: 'Please, enter restricted domains' }];
  }, [enableConversationPrivacy]);
  const onFinish = (values) => {
    console.log('Success:', values);
    const { featureIds, disabledFeatureIds, domains, hostname, pfaLocationIds, ...rest } = values;

    props.onSubmit({
      ...rest,
      hostname: isEmpty(hostname) ? null : toLower(trim(hostname)),
      domains: isEmpty(domains) ? [] : map(split(domains, ','), trim),
      featureIds: map(featureIds, (featureId) => parseInt(featureId, 10)),
      disabledFeatureIds: map(disabledFeatureIds, (featureId) => parseInt(featureId, 10)),
      pfaLocationIds: map(pfaLocationIds, (pfaLocationIds) => toLower(pfaLocationIds)),
    });
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };
  const children: JSX.Element[] = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i} value={i}>
        {i}
      </Option>
    );
  }
  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={styles.form}
      {...formItemLayout}
    >
      <Form.Item
        label="Client Group"
        name="clientGroupId"
        rules={[{ required: true, message: 'Please select client group.' }]}
      >
        <Select showSearch placeholder="Select client group...">
          {map(sortBy(groups, (g) => g.id), (group) => (
            <Option key={group.id} value={group.id}>{group.id}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: `Please enter the client's name.` }]}
      >
        <Input placeholder="Client name" />
      </Form.Item>
      <Form.Item
        label="SFDC ID"
        name="salesForceId"
        rules={SFDCRules}
      >
        <Input disabled={isTestClient} placeholder="Enter SFDC ID..." />
      </Form.Item>
      <Form.Item
        {...tailFormItemLayout}
        valuePropName="checked"
        name="isTestClient"
        extra="SFDC ID is not required for test clients."
      >
        <Checkbox
          onChange={(e) => setIsTestClient(e.target.checked)}
        >
          This is a test client
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Hostname"
        name="hostname"
        extra="Hostname has to be unique if the value is set, or you can leave it as empty."
      >
        <Input placeholder="Client hostname" />
      </Form.Item>
      <Form.Item
        label="Domains"
        name="domains"
        rules={domainsRules}
      >
        <Input placeholder="Enter client's domain..." />
      </Form.Item>
      <Form.Item
        {...tailFormItemLayout}
        valuePropName="checked"
        name="memberDomainsRestricted"
        extra="This will enable conversation privacy for client's domains"
      >
        <Checkbox
          onChange={(e) => setEnableConversationPrivacy(e.target.checked)}
        >
          Member domains restricted
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Client Features"
        name="featureIds"
      >
        <Select
          mode="multiple"
          placeholder="Select client features..."
          optionFilterProp="name"
        >
          {map(features, (feature) => (
            <Option
              key={feature.id}
              value={feature.id}
              name={feature.name}
            >{feature.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Disabled Client Features"
        name="disabledFeatureIds"
        extra="Here you can specify feature that will be disabled specifically for this client even if it is enabled for all."
      >
        <Select
          mode="multiple"
          placeholder="Select client features to disable..."
          optionFilterProp="name"
        >
          {map(features, (feature) => (
            <Option
              key={feature.id}
              value={feature.id}
              name={feature.name}
            >{feature.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="PFA Order Tag"
        name="pfaOrderTag"
        extra={
          <span>
            The orders will be tagged with <b>AspireIQ</b> if leave empty.
          </span>
        }
      >
        <Input placeholder="enter tag for Shopify order..." />
      </Form.Item>
      <Form.Item
        label="Online Inventory Locations:"
        name="pfaLocationIds"
        extra={
          <span>
            The <b>sellableOnlineQuantity</b> will be checked for OOS if left empty.
          </span>
        }
      >
        <Select
          mode="tags"
          placeholder="enter the locations ID for warehouse"
        >
        </Select>
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isSubmitting}
        className={styles.submitButton}
      >
        Submit
      </Button>
    </Form>
  );
});

/**
 * @type {React.FunctionComponent}
 */
export const EditClientDrawer: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <Drawer
      title={isEmpty(props.client) ? 'Create Client' : 'Edit Client'}
      visible={props.open}
      width={600}
      onClose={props.onRequstClose}
      className={cx(styles.EditClientDrawer, props.className)}
    >
      <EditClientForm
        client={props.open && props.client}
        onSubmit={props.onSubmit}
        isSubmitting={props.isSubmitting}
      />
    </Drawer>
  );
});

EditClientDrawer.defaultProps = {
  className: null,
};

import * as React from 'react';
import cx from 'classnames';

import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

const { lazy } = React;
import styles from './CommunitiesView.scss';
const CommunityListView = lazy(() => import('./CommunityListView'));
const CommunityDetailView = lazy(() => import('./CommunityDetailView'));

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const CommunitiesView: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={cx(styles.CommunitiesView, props.className)}>
      <Switch>
        <Route path={`${match.path}`} exact render={lazyLoadComponent(CommunityListView)} />
        <Route path={`${match.path}/:communityId`} render={lazyLoadComponent(CommunityDetailView)} />
      </Switch>
    </div>
  );
});

export default CommunitiesView;

import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_COMMUNITIES_QUERY } from '@frontend/app/queries';
import {
  GetCommunitiesQuery,
  GetCommunitiesQueryVariables,
} from '@frontend/app/queries/types/GetCommunitiesQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetCommunitiesQuery, GetCommunitiesQueryVariables>;

export function useGetCommunities(options: IOptions = {}) {
  const {
 loading, data: { communities = [] } = {}, error, refetch,
} = useQuery<GetCommunitiesQuery, GetCommunitiesQueryVariables>(
    GET_COMMUNITIES_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    communities,
    error,
    refetch,
  };
}

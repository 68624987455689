import * as React from 'react';
import cx from 'classnames';

import { Spin } from 'antd';

import styles from './LoadSpinner.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const LoadSpinner: React.FC<IProps> = React.memo((props) => {
  return (
    <div className={cx(styles.LoadSpinner, props.className)}>
      <Spin size="large" />
    </div>
  );
});

LoadSpinner.defaultProps = {
  className: null,
};

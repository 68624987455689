import * as React from 'react';

import { Alert } from 'antd';

import styles from './ErrorBoundary.scss';

interface IState {
  error: any;
  errorInfo: any;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ErrorBoundary extends React.Component<{}, IState> {
  /**
   * @inheritDoc
   */
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (error) {
      return (
        <Alert
          type="error"
          className={styles.ErrorBoundary}
          message={error.toString()}
          description={errorInfo.componentStack}
        />
      );
    }

    return children;
  }
}

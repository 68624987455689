import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { pullAllBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  DELETE_ORGANIZATION_MUTATION,
  GET_ORGANIZATIONS_QUERY,
} from '@frontend/app/queries';
import { GetOrganizationsQuery } from '@frontend/app/queries/types/GetOrganizationsQuery';
import {
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables,
} from '@frontend/app/queries/types/DeleteOrganizationMutation';

const { useEffect } = React;

const updateCache = (store: DataProxy, orgId: number) => {
  let data: GetOrganizationsQuery;
  try {
    data = store.readQuery<GetOrganizationsQuery>({ query: GET_ORGANIZATIONS_QUERY });
  } catch (err) {
    return;
  }

  const organizations = [...data.organizations];
  pullAllBy(organizations, [{ id: orgId }], 'id');

  store.writeQuery<GetOrganizationsQuery>({
    query: GET_ORGANIZATIONS_QUERY,
    data: {
      ...data,
      organizations,
    },
  });
};

type IOptions = MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export function useDeleteOrganizationById(options: IOptions = {}) {
  const [deleteOrganizationById, { loading, error }] = useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DELETE_ORGANIZATION_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully deleted organization');
    },
  });

  // so that we can capture the id during actual delete call
  const _deleteOrganizationById: typeof deleteOrganizationById = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [store, result] = args;
        if (!result.data.deleted) {
          return;
        }

        const { id } = _options.variables || options.variables;
        updateCache(store, id);
      },
    };

    return deleteOrganizationById(...args);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteOrganizationById: _deleteOrganizationById,
    loading,
  };
}

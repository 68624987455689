import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  UPDATE_CLIENT_USERS_MUTATION,
  GET_CLIENTS_QUERY,
} from '@frontend/app/queries';
import { GetClientsQuery } from '@frontend/app/queries/types/GetClientsQuery';
import {
  UpdateClientUsersMutation,
  UpdateClientUsersMutationVariables,
} from '@frontend/app/queries/types/UpdateClientUsersMutation';
import { IClient } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, client: IClient) => {
  let data: GetClientsQuery;
  try {
    data = store.readQuery<GetClientsQuery>({ query: GET_CLIENTS_QUERY });
  } catch (err) {
    console.log(`cache missing: ${GET_CLIENTS_QUERY}`);
    return;
  }

  // Write our data back to the cache.
  store.writeQuery<GetClientsQuery>({
    query: GET_CLIENTS_QUERY,
    data: {
      ...data,
      clients: unionBy(data.clients, [client], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<UpdateClientUsersMutation, UpdateClientUsersMutationVariables>;

export function useUpdateClientUsers(options: IOptions = {}) {
  const [updateClientUsers, { loading, error }] = useMutation<
    UpdateClientUsersMutation,
    UpdateClientUsersMutationVariables
  >(UPDATE_CLIENT_USERS_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data.client);
    },
    onCompleted() {
      message.info('Successfully updated users who have access to this client.');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    updateClientUsers,
    loading,
    error,
  };
}

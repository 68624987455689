import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import {
  GET_CLIENT_GROUPS_FOR_ORG_QUERY,
} from '@frontend/app/queries';
import {
  GetClientGroupsForOrgQuery,
} from '@frontend/app/queries/types/GetClientGroupsForOrgQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetClientGroupsForOrgQuery>;

export function useGetClientGroupsForOrg(options: IOptions = {}) {
  const {
 loading, data: { groups = [] } = {}, error, refetch,
} = useQuery<GetClientGroupsForOrgQuery>(
    GET_CLIENT_GROUPS_FOR_ORG_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    groups,
    error,
    refetch,
  };
}

import { useMutation } from '@apollo/react-hooks';

import { SEND_RESET_PASSWORD_EMAIL_MUTATION } from '@frontend/app/queries';
import {
  SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables,
} from '@frontend/app/queries/types/SendResetPasswordEmailMutation';

export function useSendResetPasswordEmail() {
  const [sendResetPasswordEmail, { loading }] = useMutation<
    SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables
  >(SEND_RESET_PASSWORD_EMAIL_MUTATION);

  return {
    sendResetPasswordEmail,
    loading,
  };
}

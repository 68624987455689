import { useMutation } from '@apollo/react-hooks';

import { MARK_USER_EMAIL_AS_VERIFIED_MUTATION } from '@frontend/app/queries';
import {
  MarkUserEmailAsVerifiedMutation, MarkUserEmailAsVerifiedMutationVariables,
} from '@frontend/app/queries/types/MarkUserEmailAsVerifiedMutation';

export function useMarkUserEmailAsVerified() {
  const [markUserEmailAsVerified, { loading }] = useMutation<
    MarkUserEmailAsVerifiedMutation, MarkUserEmailAsVerifiedMutationVariables
  >(MARK_USER_EMAIL_AS_VERIFIED_MUTATION);

  return {
    markUserEmailAsVerified,
    loading,
  };
}

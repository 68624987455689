import gql from 'graphql-tag';

export const GET_EMAILS_FOR_EMAIL_APPLICATIONS_QUERY = gql`
  query GetEmailsForEmailApplicationsQuery($clientId: String!) {
    emails: getEmailsForEmailApplications(clientId: $clientId)
  }
`;

export const GET_IGDM_RESOURCES_QUERY = gql`
  query GetIGDMResourcesQuery($clientId: String!) {
    igdmResources: getIGDMResources(clientId: $clientId)
  }
`;

export const DEPRECATE_AUTH_PROVIDER_MUTATION = gql`
  mutation DeprecateAuthProviderMutation($userExternalDisplayId: String!) {
    deprecated: deprecateAuthProvider(userExternalDisplayId: $userExternalDisplayId)
  }
`;

import * as React from 'react';
import cx from 'classnames';
import { sortBy } from 'lodash';

import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Button, Table,
  Typography, Alert,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Column } = Table;
const { Title } = Typography;
import { LoadSpinner } from '@frontend/lib';
import getColumnSearchProps from '@frontend/app/utils/getColumnSearchProps/getColumnSearchProps';
import { EditClientDrawer } from './EditClientDrawer';

import { logger } from '@common';

import {
  useGetClients,
  useSaveClient,
  IClient,
} from '@frontend/app/hooks';

const { useState } = React;
import styles from './ClientListView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientListView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { clients, loading, error } = useGetClients();
  const { saveClient, loading: saving } = useSaveClient();
  const [openCreateClientModal, setOpenCreateClientModal] = useState(false);

  const goToDetailView = (clientId) => {
    history.push(`${match.path}/${clientId}`);
  };

  const goToCreateAdmin = (clientId) => {
    window.open(`https://app.aspireiq.com/admin/client/${clientId}`, '_blank');
  };

  logger.debug({
    loading,
    data: clients,
  });

  return (
    <div className={cx(styles.ClientListView, props.className)}>
      <div className={styles.header}>
        <Title>Clients</Title>
        <Button
          className={styles.createButton}
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => setOpenCreateClientModal(true)}
        >
          Create Client
        </Button>
        <EditClientDrawer
          open={openCreateClientModal}
          onRequstClose={setOpenCreateClientModal.bind(this, false)}
          isSubmitting={saving}
          onSubmit={(client) => {
            saveClient({
              variables: {
                client,
              },
            }).then(() => setOpenCreateClientModal(false));
          }}
        />
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && !error &&
        <Table
          rowKey={(record: IClient) => record.id}
          dataSource={sortBy(clients, (client) => client.name)}
          className={styles.table}
        >
          <Column
            title="Name"
            key="name"
            render={(_, record: IClient) => (
              <span
                className={styles.nameCell}
                onClick={goToDetailView.bind(this, record.id)}
              >
                {record.name}
              </span>
            )}
            {...getColumnSearchProps("name")}
          />
          <Column title="Id" dataIndex="id" {...getColumnSearchProps("id")}/>
          <Column title="Admin (Create)"
            render={(_, record: IClient) => (
              <span
                className={styles.nameCell}
                onClick={goToCreateAdmin.bind(this, record.id)}
              >
                Admin (Create)
              </span>
            )}
          />
          <Column title="Client Group" dataIndex="clientGroupId" />
        </Table>
      }
      {
        !loading && error && (
          <Alert
            type="error"
            message={error.message}
            description="This is most likely because your local db stores a record that has already been deleted on auth0. To fix it, try delete the corrupted record from postgres."
          />
        )
      }
    </div>
  );
});

export default ClientListView;

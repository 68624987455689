import * as React from 'react';
import cx from 'classnames';
import { map, toLower } from 'lodash';

import {
  Drawer, Form, Input,
  Button, Select,
} from 'antd';

import { ClientFeatureState } from '@frontend/app/types/globalTypes';
import { IClientFeature } from '@frontend/app/hooks';

import styles from './EditFeatureDrawer.scss';

const { useMemo, useEffect } = React;
const { Option } = Select;

export interface ICreateFeatureParams {
  id?: number;
  name: string;
  description: string;
  state: ClientFeatureState;
}
interface IProps {
  open: boolean;
  onRequstClose(): void;

  isSubmitting: boolean;
  onSubmit(feature: ICreateFeatureParams): void;

  feature?: IClientFeature;

  className?: string;
}

interface IEditAppFormProps {
  onSubmit(feature: ICreateFeatureParams): void;
  isSubmitting: boolean;

  feature?: IClientFeature;
}
const EditFeatureForm: React.FunctionComponent<IEditAppFormProps> = React.memo((props) => {
  const { feature } = props;
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  useEffect(() => {
    if (feature) {
      form.setFieldsValue({
        id: feature.id,
        name: feature.name,
        description: feature.description,
        state: feature.state,
      });
    } else {
      form.setFieldsValue({
        name: '',
        description: '',
        state: ClientFeatureState.DISABLED,
      });
    }
  }, [form, feature]);

  const onFinish = (values) => {
    console.log('Success:', values);

    props.onSubmit(values);
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };
  console.log({ feature });

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={styles.form}
      {...formItemLayout}
    >
      {feature?.id && (
        <Form.Item label="Id" name="id" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the feature\'s name.' }]}
      >
        <Input placeholder="Feature name" />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: 'Please enter the feature\'s description.' }]}
      >
        <Input placeholder="Feature description" />
      </Form.Item>
      <Form.Item
        label="State"
        name="state"
      >
        <Select>
          {map(ClientFeatureState, (state) => (
            <Option key={state} value={state}>
              {toLower(state)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isSubmitting}
        className={styles.submitButton}
      >
        Submit
      </Button>
    </Form>
  );
});

/**
 * @type {React.FunctionComponent}
 */
export const EditFeatureDrawer: React.FunctionComponent<IProps> = React.memo((props) => {
  const { feature } = props;

  const isNewFeature = useMemo(() => !feature, [feature]);

  return (
    <Drawer
      title={isNewFeature ? 'Create Client Feature' : 'Edit Client Feature'}
      visible={props.open}
      width={600}
      onClose={props.onRequstClose}
      className={cx(styles.EditFeatureDrawer, props.className)}
    >
      <EditFeatureForm
        onSubmit={props.onSubmit}
        isSubmitting={props.isSubmitting}
        feature={props.open && feature}
      />
    </Drawer>
  );
});

EditFeatureDrawer.defaultProps = {
  className: null,
};

import * as React from 'react';
import cx from 'classnames';

import {
  ICommunity,
} from '@frontend/app/hooks';

import styles from './CommunityDetails.scss';

interface IProps {
  community: ICommunity;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const CommunityDetails: React.FunctionComponent<IProps> = React.memo((props) => {
  return (
    <div className={cx(styles.CommunityDetails, props.className)}>
      <pre>
        {JSON.stringify(props.community, undefined, 2)}
      </pre>
    </div>
  );
});

export default CommunityDetails;

import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { UPDATE_PROGRAM_LANDING_PAGE_PROPERTIES } from '@frontend/app/queries';
import {
  ProgramLandingPagePropertiesMutation,
  ProgramLandingPagePropertiesMutationVariables,
} from '@frontend/app/queries/types/ProgramLandingPagePropertiesMutation';

type IOptions = MutationHookOptions<ProgramLandingPagePropertiesMutation, ProgramLandingPagePropertiesMutationVariables>;

export function useUpdateProgramLandingPageProperties(options: IOptions = {}) {
  return useMutation<ProgramLandingPagePropertiesMutation, ProgramLandingPagePropertiesMutationVariables>(
    UPDATE_PROGRAM_LANDING_PAGE_PROPERTIES,
    options,
  );
}

import gql from 'graphql-tag';

import {
  CLIENT_FRAGMENT,
  USER_WITH_AUTH0_FRAGMENT,
  PROGRAM_FRAGMENT,
  CLIENT_METADATA_FRAGMENT,
  CLIENT_ALLOY_METADATA_FRAGMENT,
} from './fragments';

export const GET_CLIENTS_QUERY = gql`
  query GetClientsQuery {
    clients: getClients {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const GET_CLIENT_ELEVATE_CAMPAIGNS_QUERY = gql`
  query GetClientElevateCampaignsQuery($clientId: String!) {
    campaigns: getClientElevateCampaigns(clientId: $clientId)
  }
`;

export const SAVE_CLIENT_MUTATION = gql`
  mutation SaveClientMutation($client: SaveClientInput!) {
    client: saveClient(client: $client) {
      ...ClientFragment
      users {
        ...UserWithAuth0Fragment
      }
      programs {
        ...ProgramFragment
      }
      clientMetadata {
        ...ClientMetadataFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${USER_WITH_AUTH0_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${CLIENT_METADATA_FRAGMENT}
`;

export const GET_CLIENT_QUERY = gql`
  query GetClientQuery($clientId: String!) {
    client: getClientById(id: $clientId) {
      ...ClientFragment
      users {
        ...UserWithAuth0Fragment
      }
      programs {
        ...ProgramFragment
      }
      clientMetadata {
        ...ClientMetadataFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${USER_WITH_AUTH0_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${CLIENT_METADATA_FRAGMENT}
`;

export const DELETE_CLIENT_MUTATION = gql`
  mutation DeleteClientMutation($id: String!) {
    deleted: deleteClientById(id: $id)
  }
`;

export const UPDATE_CLIENT_USERS_MUTATION = gql`
  mutation UpdateClientUsersMutation($id: String!, $userIds: [String!]!) {
    client: updateClientUsers(id: $id, userIds: $userIds) {
      ...ClientFragment
      users {
        ...UserWithAuth0Fragment
      }
      programs {
        ...ProgramFragment
      }
      clientMetadata {
        ...ClientMetadataFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${USER_WITH_AUTH0_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${CLIENT_METADATA_FRAGMENT}
`;

export const CLIENT_COMPLETED_ONBOARDING_MUTATION = gql`
  mutation ClientCompletedOnboardingMutation($clientId: String!) {
    client: clientCompletedOnboarding(clientId: $clientId) {
      ...ClientFragment
      users {
        ...UserWithAuth0Fragment
      }
      programs {
        ...ProgramFragment
      }
      clientMetadata {
        ...ClientMetadataFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${USER_WITH_AUTH0_FRAGMENT}
  ${PROGRAM_FRAGMENT}
  ${CLIENT_METADATA_FRAGMENT}
`;

export const GET_ALLOY_WORKFLOWS_QUERY = gql`
  query GetAlloyWorkflowsQuery {
    workflows: getAlloyWorkflows
  }
`;

export const GET_ALLOY_METADATA_QUERY = gql`
  query GetAlloyMetadataQuery($clientId: String!) {
    metadata: getAlloyMetadata(clientId: $clientId) {
      ...ClientAlloyMetadataFragment
    }
  }
  ${CLIENT_ALLOY_METADATA_FRAGMENT}
`;

export const UPDATE_ALLOY_INTEGRATIONS_MUTATION = gql`
  mutation UpdateAlloyIntegrationsMutation($clientId: String!, $availableIntegrations: [AlloyIntegrationType!]!) {
    metadata: updateAlloyIntegrations(clientId: $clientId, availableIntegrations: $availableIntegrations) {
      ...ClientAlloyMetadataFragment
    }
  }
  ${CLIENT_ALLOY_METADATA_FRAGMENT}
`;

export const UPDATE_CLIENT_OFFER_MUTATION = gql`
mutation AppendMembersToOffer($clientId: String!, $offerId: Float!, $data: [AffiliateOfferPromoInputType!]!, $startDate: DateTime!, $endDate: DateTime!) {
  appendMembersToOffer(clientId: $clientId, offerId: $offerId, data: $data, startDate: $startDate, endDate: $endDate)
}
`;

export const UPDATE_CLIENT_AUTHORIZED_AGENT = gql`
mutation UpdateAuthorizedAgent($clientId: String!, $data: AuthorizedAgentInput!) {
  updateAuthorizedAgent(clientId: $clientId, data: $data) {
    authorizedAgent {
      id
      name
      email
      useFullNameForSignature
      signatureText
      title
      
    }
  }
}
`;

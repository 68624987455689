/* Text styles */
._UserListView_vcish_2 ._header_vcish_2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._UserListView_vcish_2 ._header_vcish_2 ._actions_vcish_7 {
  margin-left: auto;
}
._UserListView_vcish_2 ._header_vcish_2 ._actions_vcish_7 ._createButton_vcish_10 {
  margin-left: 10px;
}
._UserListView_vcish_2 ._table_vcish_13 ._nameCell_vcish_13 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._UserListView_vcish_2 ._table_vcish_13 ._nameCell_vcish_13 ._avatar_vcish_18 {
  margin-right: 10px;
}
._UserListView_vcish_2 ._table_vcish_13 ._nameCell_vcish_13 ._name_vcish_13 {
  flex: 1;
  cursor: pointer;
  user-select: none;
  color: #3996e0;
}
._UserListView_vcish_2 ._table_vcish_13 ._clientTag_vcish_27 {
  cursor: pointer;
  user-select: none;
}
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import {
        UPDATE_CLIENT_OFFER_MUTATION,
} from '@frontend/app/queries';
import { AppendMembersToOffer, AppendMembersToOfferVariables } from '@frontend/app/queries/types/AppendMembersToOffer';

type IOptions = MutationHookOptions<AppendMembersToOffer, AppendMembersToOfferVariables>;

export function useUpdateClientOffer(options: IOptions = {}) {
    const [updateOfferData, { loading, error }] = useMutation(UPDATE_CLIENT_OFFER_MUTATION, {
        ...options,
    });

    return {
        updateOfferData,
        loading,
        error,
    };
}

import { useMutation } from '@apollo/react-hooks';

import { SEND_VERIFICATION_EMAIL_MUTATION } from '@frontend/app/queries';
import {
  SendVerificationEmailMutation, SendVerificationEmailMutationVariables,
} from '@frontend/app/queries/types/SendVerificationEmailMutation';

export function useSendVerificationEmail() {
  const [sendVerificationEmail, { loading }] = useMutation<
    SendVerificationEmailMutation, SendVerificationEmailMutationVariables
  >(SEND_VERIFICATION_EMAIL_MUTATION);

  return {
    sendVerificationEmail,
    loading,
  };
}

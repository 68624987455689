import gql from 'graphql-tag';

export const COMMUNITY_FRAGMENT = gql`
  fragment CommunityFragment on Community {
    id
    title
    clientIds
    description
    splashImageUrl
  }
`;

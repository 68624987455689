import * as React from 'react';
import cx from 'classnames';
import {
 map, filter, find, isEmpty,
} from 'lodash';

import {
  Form, Drawer, Button, Select,
} from 'antd';

import {
  IUser,
  IApplicationInstance,
  useGetClients,
  useGetApplications,
} from '@frontend/app/hooks';
import styles from './InstallApplicationDrawer.scss';

const { Option } = Select;

const { useEffect, useMemo } = React;

export interface IInstallApplicationParams {
  applicationIds: string;
  clientId: string;
}
interface IProps {
  open: boolean;
  onRequstClose(): void;

  onSubmit(params: IInstallApplicationParams): void;
  isSubmitting: boolean;

  clientId?: string;
  applicationIds?: string[];

  // already installed applications/clients
  installedApplications?: IApplicationInstance[];

  className?: string;
}
interface IFormProps {
  open: boolean;
  onSubmit(params: IInstallApplicationParams): void;
  isSubmitting: boolean;

  clientId?: string;
  applicationIds?: string[];

  installedApplications?: IApplicationInstance[];

  user?: IUser;
}
const InstallApplicationForm: React.FunctionComponent<IFormProps> = React.memo((props) => {
  const [form] = Form.useForm();
  const { applications, loading: loadingApplications } = useGetApplications();
  const { clients, loading: loadingClients } = useGetClients();
  const availableApplications = useMemo(() => filter(
      applications,
      (a) => !find(
        props.installedApplications,
        (i) => i.applicationId === a.id,
      ),
    ), [applications, props.installedApplications]);
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    if (!props.open) {
      form.resetFields();
    }
  }, [props.open, form]);

  const onFinish = (values) => {
    console.log('Success:', values);

    props.onSubmit(values);
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={styles.form}
      initialValues={{
        applicationIds: props.applicationIds,
        clientId: props.clientId,
      }}
      {...formItemLayout}
    >
      <Form.Item
        label="Application"
        name="applicationIds"
        rules={[
          { required: true, message: 'Please select the application to install!' },
        ]}
      >
        <Select
          mode="multiple"
          disabled={!isEmpty(props.applicationIds) || isEmpty(availableApplications)}
          loading={loadingApplications}
          placeholder={isEmpty(availableApplications) ? 'Already installed all applications' : 'Select application'}
          optionFilterProp="name"
        >
          {map(availableApplications, (params) => (
            <Option
              key={params.id}
              value={params.id}
              name={params.name}
            >
              {params.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Client"
        name="clientId"
        rules={[
          { required: true, message: 'Please select target client!' },
        ]}
      >
        <Select
          disabled={loadingClients || !isEmpty(props.clientId)}
          loading={loadingClients}
          placeholder="Select client"
          optionFilterProp="name"
        >
          {map(clients, (params) => (
            <Option
              key={params.id}
              value={params.id}
              name={params.name}
            >
              {params.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isSubmitting}
        className={styles.submitButton}
      >
        Submit
      </Button>
    </Form>
  );
});
/**
 * @type {React.FunctionComponent}
 */
export const InstallApplicationDrawer: React.FunctionComponent<IProps> = React.memo((props) => (
  <Drawer
    title="Install Applications"
    visible={props.open}
    width={600}
    onClose={props.onRequstClose}
    className={cx(styles.InstallApplicationDrawer, props.className)}
  >
    <InstallApplicationForm
      open={props.open}
      applicationIds={props.applicationIds}
      clientId={props.clientId}
      installedApplications={props.installedApplications}
      onSubmit={props.onSubmit}
      isSubmitting={props.isSubmitting}
    />
  </Drawer>
  ));

import 'css-chunk:src/app/containers/Home/UsersView/UserListView.scss';export default {
  "UserListView": "_UserListView_vcish_2",
  "header": "_header_vcish_2",
  "actions": "_actions_vcish_7",
  "createButton": "_createButton_vcish_10",
  "table": "_table_vcish_13",
  "nameCell": "_nameCell_vcish_13",
  "avatar": "_avatar_vcish_18",
  "name": "_name_vcish_13",
  "clientTag": "_clientTag_vcish_27"
};
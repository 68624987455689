import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_APPLICATIONS_QUERY } from '@frontend/app/queries';
import {
  GetApplicationsQuery,
} from '@frontend/app/queries/types/GetApplicationsQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetApplicationsQuery>;

export function useGetApplications(options: IOptions = {}) {
  const {
 loading, data: { applications = [] } = {}, error, refetch,
} = useQuery<GetApplicationsQuery>(
    GET_APPLICATIONS_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    applications,
    error,
    refetch,
  };
}
